export const defaultItems = [
  { name: "Kitchen - Base Units - with Laminates", height: 3, unitPrice: 1650 },
  { name: "Kitchen - Wall Units - with Laminates", height: 2, unitPrice: 1650 },
  { name: "Kitchen - Loft Units - with Laminates (Frame and Shutter)", height: 2, unitPrice: 1150 },
  { name: "Kitchen Tall Unit For OTG - with Laminates", height: 7, unitPrice: 1650 },
  { name: "Kitchen Tall Unit - with Laminates", height: 7, unitPrice: 1650 },
  { name: "Kitchen - Wall Units - with Glass shutters", height: 2, unitPrice: 2050 },
  { name: "Kitchen - Base Unit - with Acrylic", height: 3, unitPrice: 1850 },
  { name: "Kitchen - Wall Units - with Acrylic", height: 2, unitPrice: 1850 },
  { name: "Kitchen - Loft Units - with Acrylic (Frame and Shutter)", height: 2, unitPrice: 1150 },
  { name: "Kitchen Tall Unit For OTG - with Acrylic", height: 7, unitPrice: 1850 },
  { name: "Kitchen Tall Unit - with Acrylic", height: 7, unitPrice: 1850 },
  { name: "Breakfast Counter - Base Unit - with Laminates", height: 3, unitPrice: 1650 },
  { name: "Wooden Arch - with Laminates", height: 1, unitPrice: 750 },
  { name: "Tv Unit - Drawers - with Laminates", height: 1, unitPrice: 2500 },
  { name: "Tv Unit - Back Panel - with Laminates", height: 7, unitPrice: 750 },
  { name: "Tv Unit - Tall Unit - with Glass shutter", height: 7, unitPrice: 1950 },
  { name: "Tv Unit - Tall Unit Open Shelves - with Laminates", height: 7, unitPrice: 1650 },
  { name: "Tv Unit - Ledge", height: 1, unitPrice: 850 },
  { name: "Crockery - Base Unit - with Laminates", height: 3, unitPrice: 1650 },
  { name: "Crockery - Wall Unit - with Glass Shutter", height: 2, unitPrice: 1950 },
  { name: "Crockery - Tall Unit - with Glass Shutters", height: 7, unitPrice: 1950 },
  { name: "Window Below Storage - Base Unit - with Laminates", height: 2, unitPrice: 1650 },
  { name: "Window Below Storage - Cushioning", height: null, unitPrice: 5000 },
  { name: "Vanity Unit - with Laminates", height: 2, unitPrice: 1650 },
  { name: "Pooja - Back Panel - Lighting with CNC Cut", height: null, unitPrice: 1050 },
  { name: "Pooja - Base Unit - with Laminates", height: 2, unitPrice: 1650 },
  { name: "Pooja - Shutters - with Laminates", height: 7, unitPrice: 750 },
  { name: "Pooja - Shutters - with Glass", height: 7, unitPrice: 1300 },
  { name: "Wardrobe - Sliding Shutter - with Laminates", height: 7, unitPrice: 1750 },
  { name: "Sliding Wardrobe Loft Units - with Laminates (Frame and Shutter)", height: 2, unitPrice: 1150 },
  { name: "Wardrobe Openable Shutter - with Laminates", height: 7, unitPrice: 1650 },
  { name: "Wardrobe - Loft Units - with Laminate (Frame and Shutter)", height: 2, unitPrice: 1150 },
  { name: "Study - Wall Unit - with Laminates", height: 2, unitPrice: 1650 },
  { name: "Study - Drawers - with Laminates", height: 2.5, unitPrice: 2500 },
  { name: "Study - Ledge - with Laminates", height: 1, unitPrice: 900 },
  { name: "Book Shelves - with Laminates", height: 1, unitPrice: 1650 },
  { name: "Dressing - Drawers - with Laminates", height: 1, unitPrice: 2500 },
  { name: "Shoe Cabinet - with Laminates", height: 3, unitPrice: 1650 },
  { name: "Utility Storage - with Laminates", height: 2, unitPrice: 1650 },
  { name: "False Ceiling - Saint Gobain Brand", unitPrice: 60 },
  { name: "False Ceiling - Painting - Asian Paints", unitPrice: 25 },
  { name: "Wooden Ceiling - with Veneer", height: null, unitPrice: 850 },
  { name: "Ceiling Lights - Philips Brand - 15W", height: null, unitPrice: 650 },
  { name: "Balcony PVC Ceiling", height: null, unitPrice: 180 },
  { name: "Electrical Wiring and Piping Points - Finolex Brand", height: null, unitPrice: 850 },
  { name: "Counter-Top Granite", height: 2, unitPrice: 650 },
  { name: "Counter-Top Quartz", height: 2, unitPrice: 950 },
  { name: "Backsplash- Tile", height: 2, unitPrice: 450 },
  { name: "Ebco - Tandem drawers - with Cutlery set, Cup & Saucer, Thali Set", height: null, unitPrice: 6000 },
  { name: "Ebco - Bottle Pullout", height: null, unitPrice: 6500 },
  { name: "Hettich - Tandem drawers - with Cutlery set, Cup & Saucer, Thali Set", height: null, unitPrice: 7000 },
  { name: "Hettich - Bottle Pullout", height: null, unitPrice: 8500 },
  { name: "Hardware - Tall Unit - Ebco Brand", height: null, unitPrice: 22000 },
  { name: "Ebco - Pantry hardware", height: null, unitPrice: 25000 },
  { name: "Mirror - Mirror written as sft", height: 5, unitPrice: 650 },
  { name: "Tv Unit - Louvers", height: null, unitPrice: 750 },
  { name: "Partition - with Laminates / glass and aluminium", height: 9.5, unitPrice: 950 },
  { name: "Profile Lights with adapters - with diffuser, light & aluminium frame", height: null, unitPrice: 450 },
  { name: "Profile Lights Under Wall Units - with diffuser, light & aluminium frame", height: null, unitPrice: 450 },
  { name: "Wallpaper - as per selection", height: 9.5, unitPrice: 150 },
  { name: "Texture Paint - As per Selection", height: 9.5, unitPrice: 180 },
  { name: "Bedback Panel", height: 9.5, unitPrice: 800 },
  { name: "Work Station", height: null, unitPrice: 900 },
  { name: "Demolition Work", height: null, unitPrice: 40 },
  { name: "Switch Board Installation", height: null, unitPrice: 2500 },
  { name: "Bed -with Storage - Surrounded Cushioning", height: null, unitPrice: 85000 },
  { name: "Bed -without Storage - Surrounded Cushioning", height: null, unitPrice: 75000 },
  { name: "Bed - with Laminates", height: null, unitPrice: 60000 },
  { name: "T- Profile (Patti)", height: null, unitPrice: 300 },
  { name: "Moulding - POP / Wooden", height: null, unitPrice: 350 },
  { name: "Strip Lights", height: null, unitPrice: 300 },
  { name: "Bed Back Cushioning", height: 9.5, unitPrice: 900 },
  { name: "Crockery - Open Unit - with Laminates", height: 2, unitPrice: 1650 },
  { name: "Crockery - Loft Units - with Laminate (Frame and Shutter)", height: 2, unitPrice: 1150 },
  { name: "Foldable Dining Table - with Laminates", height: null, unitPrice: 850 },
  { name: "Crockery - Louvers", height: null, unitPrice: 750 },
  { name: "Rolling Shutter - Glass", height: null, unitPrice: 22000 },
  { name: "Rolling Shutter - PVC", height: null, unitPrice: 14000 },
  { name: "Carcass for Rolling Shutter - With Laminates", height: 4.5, unitPrice: 1150 },
  { name: "Kitchen - Open Units - with Laminates", height: 2, unitPrice: 1650 },
  { name: "Wicker Baskets", height: null, unitPrice: 8000 },
  { name: "Study Table", height: null, unitPrice: 12000 },
  { name: "Murphy Bed", height: null, unitPrice: 35000 },
  { name: "Bunk Bed", height: null, unitPrice: 75000 },
  { name: "Table Lamps", height: null, unitPrice: 3000 },
  { name: "Chandelier", height: null, unitPrice: 15000 },
  { name: "Pendent / Hanging Lights", height: null, unitPrice: 7000 },
  { name: "Diamond Shaped Decorative Mirror - with Back Panel", height: null, unitPrice: 950 },
  { name: "Walk-In Wardrobe - Sliding Shutter - with Laminates", height: 7, unitPrice: 1750 },
  { name: "Walk-In Wardrobe Openable Shutter - with Laminates", height: 7, unitPrice: 1650 },
  { name: "Walk-In Wardrobe - Loft Units - with Laminate (Frame and Shutter)", height: 2, unitPrice: 1150 },
  { name: "Wardrobe Shutter - with Profile Mirror", height: 7, unitPrice: 950 },
  { name: "Wardrobe Shutter - with Lacquared Glass", height: 7, unitPrice: 1050 },
  { name: "Wardrobe Carcass - with Laminates", height: 7, unitPrice: 1150 },
  { name: "Center Table", height: null, unitPrice: 12000 },
  { name: "Coffee Table", height: null, unitPrice: 12000 },
  { name: "Console Table", height: null, unitPrice: 35000 },
  { name: "Wall Light", height: null, unitPrice: 3000 },
  { name: "LED Mirror", height: null, unitPrice: 15000 },
  { name: "CNC Cutting Panel - with MDF and Duco Paint", height: null, unitPrice: 950 },
  { name: "Prasad Tray", height: null, unitPrice: 2000 },
  { name: "Pooja - Drawers - with Laminates", height: 1, unitPrice: 2500 },
  { name: "Storage Under Partition - with Laminates", height: 2, unitPrice: 1650 },
  { name: "Janitor Unit - with Laminates", height: null, unitPrice: 1650 },
  { name: "Shelves - with Laminates", height: 1, unitPrice: 750 },
  { name: "Lehmans Corner Unit - Ebco", height: null, unitPrice: 22000 },
  { name: "Lehmans Corner Unit - Hettich", height: null, unitPrice: 40000 },
  { name: "Magic Corner Unit - Ebco", height: null, unitPrice: 18000 },
  { name: "Magic Corner Unit - Hettich", height: null, unitPrice: 28000 },
  { name: "Lacquared Mirror", height: null, unitPrice: 850 },
  { name: "Partition - with PVD Coating", height: 9, unitPrice: 2000 },
  { name: "Track Lights", height: null, unitPrice: 3000 },
  { name: "Spot Lights - 5W", height: null, unitPrice: 400 },
  { name: "Flooring - with Vitrified Tiles", height: null, unitPrice: 200 },
  { name: "Main Door Panelling", height: null, unitPrice: 850 },
  { name: "Main Door - Wooden", height: null, unitPrice: 25000 },
  { name: "Bedroom Doors", height: null, unitPrice: 8000 },
  { name: "Bathroom Doors - PVC", height: null, unitPrice: 5000 },
  { name: "Main Door - MS Grill", height: null, unitPrice: 12000 },
  { name: "Deep Cleaning", height: null, unitPrice: 6000 },
  { name: "Transportation Charge", height: null, unitPrice: 3000 },
  { name: "Wall Paints - 2 coat painting with Putty and Primer", height: null, unitPrice: 30 },
  { name: "Long Handles", height: null, unitPrice: 2000 },
  { name: "Wooden Handles", height: null, unitPrice: 2500 },
  { name: "Knobs", height: null, unitPrice: 300 },
  { name: "Curtains", height: null, unitPrice: 6000 },
  { name: "Home Automation", height: null, unitPrice: 50000 },
  { name: "Blinds", height: null, unitPrice: 6000 },
  { name: "Photo Frames", height: null, unitPrice: 2000 },
  { name: "Wall Decor", height: null, unitPrice: 6000 },
  { name: "Fan Points", height: null, unitPrice: 950 },
  { name: "Name Plate Installation", height: null, unitPrice: 3500 },
  { name: "Ottoman / Stool", height: null, unitPrice: 5000 },
  { name: "Chair", height: null, unitPrice: 3500 },
  { name: "Dining Table - 4 Seater - with Chairs", height: null, unitPrice: 25000 },
  { name: "Dining Table - 6 Seater - with Chairs", height: null, unitPrice: 33000 },
  { name: "Marble Top Dining Table - 4 Seater - with Chairs", height: null, unitPrice: 60000 },
  { name: "Marble Top Dining Table - 6 Seater - with Chairs", height: null, unitPrice: 90000 },
  { name: "Home Theater - Sound Proof Panel", height: null, unitPrice: 800 },
  { name: "AC Installation", height: null, unitPrice: 3500 },
  { name: "Speaker Installation", height: null, unitPrice: 12000 },
  { name: "CCTV Camera Installation", height: null, unitPrice: 12000 },
  { name: "Counter Top Installation", height: null, unitPrice: 180 },
  { name: "Backsplash Installation", height: null, unitPrice: 180 },
  { name: "Star Light Ceiling", height: null, unitPrice: 40000 },
  { name: "Stone Cladding", height: null, unitPrice: 600 },
  { name: "Louvers Panelling", height: null, unitPrice: 750 },
  { name: "Ceiling Cloth Hangers", height: null, unitPrice: 3500 },
  { name: "Chimney", height: null, unitPrice: 15000 },
  { name: "Wall Chipping - For Electrical Wiring", height: null, unitPrice: 1200 },
  { name: "Sink - Stainless Steel", height: null, unitPrice: 8000 },
  { name: "Sink - Quartz", height: null, unitPrice: 15000 },
  { name: "Dustbin", height: null, unitPrice: 7000 }
];