const QuotationSkeleton = () => (
  <div className="animate-pulse p-4">
    {/* Company Info Skeleton */}
    <div className="flex mb-6">
      <div className="w-2/3 pr-2">
        <div className="h-10 bg-gray-200 rounded mb-2"></div>
        <div className="h-16 bg-gray-200 rounded mb-2"></div>
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
      <div className="w-1/3 pl-2">
        <div className="aspect-square bg-gray-200 rounded"></div>
      </div>
    </div>

    {/* Client Info Skeleton */}
    <div className="flex mb-6">
      <div className="w-1/2 pr-2">
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
      <div className="w-1/2 pl-2">
        <div className="h-10 bg-gray-200 rounded"></div>
      </div>
    </div>

    {/* Rooms Skeleton */}
    {[1, 2].map((_, index) => (
      <div key={index} className="mb-6">
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="h-12 bg-gray-200 mb-2"></div>
          <div className="p-4">
            {[1, 2].map((_, itemIndex) => (
              <div key={itemIndex} className="h-16 bg-gray-200 rounded mb-2"></div>
            ))}
          </div>
        </div>
      </div>
    ))}

    {/* Totals Skeleton */}
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="h-12 bg-gray-200 mb-2"></div>
      <div className="p-4">
        {[1, 2].map((_, itemIndex) => (
          <div key={itemIndex} className="h-16 bg-gray-200 rounded mb-2"></div>
        ))}
      </div>
    </div>
  </div>
)

export default QuotationSkeleton
