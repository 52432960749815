import React from 'react';
import { X } from 'lucide-react';
import { auth } from '../Services/firebase';

const SubscriptionExpiredModal = ({ onClose }) => {
  const handleClose = async () => {
    await auth.signOut();
    onClose();
  };

  // Prevent closing by clicking outside
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4" onClick={handleContainerClick}>
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6 relative animate-fade-in">
        <div className="absolute right-4 top-4">
          <button onClick={handleClose} className="text-gray-400 hover:text-gray-600">
            <X size={24} />
          </button>
        </div>
        
        <div className="text-center mb-6">
          <div className="bg-red-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
            <svg className="w-8 h-8 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Access Restricted</h2>
          <p className="text-gray-600 mb-4">
            Your subscription has expired. Please renew to continue using the app.
          </p>
        </div>

        <div className="bg-gradient-to-br from-indigo-50 to-blue-50 rounded-lg p-6 mb-6 border border-indigo-100">
          <div className="text-center">
            <p className="text-lg font-semibold text-indigo-900 mb-4">Most Affordable Quotation Making App</p>
            
            <div className="inline-block mb-4">
              <div className="flex flex-col items-center">
                <div className="relative mb-3">
                  <div className="flex items-center gap-3 mb-2">
                    <span className="text-lg text-gray-400 line-through decoration-red-500/50 decoration-2">
                      ₹500
                    </span>
                    <span className="bg-red-500 text-white text-xs px-2 py-0.5 rounded-full">
                      -60% OFF
                    </span>
                  </div>
                  
                  <div className="flex items-baseline justify-center gap-1">
                    <span className="text-4xl font-bold bg-gradient-to-r from-indigo-600 to-blue-600 bg-clip-text text-transparent">
                      ₹199
                    </span>
                    <span className="text-sm text-indigo-700 font-medium">/ month</span>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="bg-green-50 rounded-full px-4 py-1 inline-block mb-3">
              <p className="text-sm text-green-600 font-semibold">Save ₹301 on subscription!</p>
            </div>
            
            <p className="text-sm text-indigo-600 font-medium">
              Renew now to continue creating professional quotations
            </p>
          </div>
        </div>

        <div className="space-y-4">
          <a 
            href="https://wa.me/919966922000?text=Hi,%20I%20want%20to%20renew%20my%20Design%20Quote%20App%20subscription"
            target="_blank"
            rel="noopener noreferrer"
            className="block w-full bg-gradient-to-r from-green-500 to-green-600 text-white py-3 px-4 rounded-lg hover:from-green-600 hover:to-green-700 transition duration-200 text-center font-medium shadow-sm"
          >
            Renew Now on WhatsApp
          </a>
          <button 
            onClick={handleClose}
            className="w-full border border-gray-300 text-gray-700 py-3 px-4 rounded-lg hover:bg-gray-50 transition duration-200 font-medium"
          >
            Sign Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionExpiredModal;
