import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';

const EditableDropdown = ({ value, options, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue);
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    onChange(option);
    setIsOpen(false);
  };

  const filteredOptions = options.filter(option => 
    option.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex items-center border rounded">
        <input
          className="w-full p-2 rounded-l focus:outline-none"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          placeholder={placeholder}
        />
        <button 
          className="p-2 bg-gray-100 rounded-r"
          onClick={() => setIsOpen(!isOpen)}
        >
          <ChevronDown size={20} />
        </button>
      </div>
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white border mt-1 rounded-md shadow-lg max-h-60 overflow-y-auto">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <li
                key={index}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))
          ) : (
            <li className="px-4 py-2 text-gray-500">No matches found</li>
          )}
        </ul>
      )}
    </div>
  );
};

const NewQuotationPopup = ({ onClose, onStart, clientName, setClientName, propertyType, setPropertyType, companyName }) => {
  const propertyTypes = [ '1BHK', '2BHK', '3BHK', '4BHK', 'Villa', 'Individual House', 'N/A', 'Office/Commercial Space']; // Added new property type

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80">
        <h2 className="text-xl font-bold mb-4">Create New Quotation</h2>
        <p className="text-sm text-gray-600 mb-4">Company: {companyName}</p>
        <input
          type="text"
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
          placeholder="Client Name"
          className="w-full p-2 mb-4 border rounded"
        />
        <EditableDropdown
          value={propertyType}
          options={propertyTypes}
          onChange={setPropertyType}
          placeholder="Select or type Property Type"
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="mr-2 px-4 py-2 bg-gray-200 rounded"
          >
            Cancel
          </button>
          <button
            onClick={() => onStart(clientName, propertyType)}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Start
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewQuotationPopup;
