// pdfThemeColors.js

export const pdfThemeColors = {
  default: {
    name: 'Default',
    roomHeader: '#C7D2FE',
    itemsContainer: '#E0E7FF',
    numberCircle: '#818CF8',
  },
  ocean: {
    name: 'Ocean',
    roomHeader: '#BFDBFE',
    itemsContainer: '#DBEAFE',
    numberCircle: '#3B82F6',
  },
  forest: {
    name: 'Forest',
    roomHeader: '#BBF7D0',
    itemsContainer: '#DCFCE7',
    numberCircle: '#22C55E',
  },
  sunset: {
    name: 'Sunset',
    roomHeader: '#FED7AA',
    itemsContainer: '#FFEDD5',
    numberCircle: '#F97316',
  },
  berry: {
    name: 'Berry',
    roomHeader: '#FBCFE8',
    itemsContainer: '#FCE7F3',
    numberCircle: '#EC4899',
  },
  lavender: {
    name: 'Lavender',
    roomHeader: '#DDD6FE',
    itemsContainer: '#EDE9FE',
    numberCircle: '#A855F7',
  },
  mint: {
    name: 'Mint',
    roomHeader: '#A7F3D0',
    itemsContainer: '#D1FAE5',
    numberCircle: '#10B981',
  },
  coral: {
    name: 'Coral',
    roomHeader: '#FECACA',
    itemsContainer: '#FEE2E2',
    numberCircle: '#EF4444',
  },
  gold: {
    name: 'Gold',
    roomHeader: '#FDE68A',
    itemsContainer: '#FEF3C7',
    numberCircle: '#F59E0B',
  },
  slate: {
    name: 'Slate',
    roomHeader: '#E2E8F0',
    itemsContainer: '#F1F5F9',
    numberCircle: '#64748B',
  }
};