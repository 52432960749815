export const defaultTemplateDetails = {
  paymentTerms: [
    { percentage: 10, description: "Advance payment to start design process" },
    { percentage: 50, description: "Payment to start production" },
    { percentage: 30, description: "After Installation of carcass" },
    { percentage: 10, description: "Payment Before final fixing" }
  ],
  materialSpecs: [
    { name: "Core Material", description: "Gurjan Plyood (BWP-710 & BWR)" },
    { name: "Hardware Brand & Type", description: "Ebco (Soft closing)" },
    { name: "Laminates Brand", description: "Merino / Virgolam" }
  ],
  notes: [
    "All prices quoted are valid for 15 days from the date of stated on the quotation",
    "Color of the laminates is as per selection only.",
    "Hardware: Soft closing channels, soft closing hinges and soft closing tandem units for the kitchen.",
    "Quotation includes: Providing designs and fixing of units with materials, transportation and labour charges.",
    "The above given prices are tentative. The quotation may vary as per design after design confirmation."
  ],
  items: [] // Add default items if needed
};