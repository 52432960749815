// src/components/Tabs.jsx

import React from 'react';
import { FilePenLine, Files, Package, Settings } from 'lucide-react';
import ItemsList from './ItemsList';

const Tabs = ({ activeTab, setActiveTab, onRefreshQuote }) => {
  const tabs = [
    { id: 'quoteMaker', icon: FilePenLine, label: 'Quote' },
    { id: 'quotations', icon: Files, label: 'Files' },
    { id: 'productsList', icon: Package, label: 'Items' },
    { id: 'settings', icon: Settings, label: 'Settings' },
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    
    // Scroll to top for 'quotations' and 'productsList' tabs
    if (tabId === 'quotations' || tabId === 'productsList') {
      window.scrollTo(0, 0);
    }
  };

  return (
    <nav className="flex justify-between items-center px-4 py-2">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => handleTabClick(tab.id)}
          className={`flex flex-col items-center py-2 px-4 ${
            activeTab === tab.id
              ? 'text-indigo-600 border-b-2 border-indigo-600'
              : 'text-gray-500'
          }`}
        >
          <tab.icon size={20} />
          <span className="text-xs mt-1">{tab.label}</span>
        </button>
      ))}
      {activeTab === 'itemsList' && <ItemsList />}
    </nav>
  );
};

export default Tabs;
