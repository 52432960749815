import React, { useState, useEffect } from 'react';
import { Plus, Trash2 } from 'lucide-react';
import { useFirebaseService } from '../hooks/useFirebaseService';
import { useAuth } from '../contexts/AuthContext';

export const defaultRoomNames = [
  "Master Bedroom",
  "Bedroom 1",
  "Bedroom 2",
  "Living Room",
  "Drawing Room",
  "Kids Bedroom",
  "Kitchen",
  "Toilet",
  "Dining Room",
  "Balcony",
  "Study Room",
  "Pooja Room",
  "Guest Room",
  "Servant's Room",
  "Utility Area",
  "Foyer",
  "Store Room",
  "Children's Play Area",
  "Home Theatre",
  "Powder Room",
  "Walk-in Closet",
  "Terrace Garden",
  "Gym Area",
  "Wet Kitchen",
  "Dry Kitchen",
  "Common Toilet",
  "Attached Toilet",
  "Sit-out Area",
  "Maid's Room",
  "Laundry Room",
  "Puja Nook",
  "Lobby",
  "Courtyard",
  "Multipurpose Hall",
  "Other"
];

const CustomRoomNames = () => {
  const [roomNames, setRoomNames] = useState([]);
  const [newRoomName, setNewRoomName] = useState('');
  const { updateUserSettings, getUserSettings } = useFirebaseService();
  const { user } = useAuth();

  useEffect(() => {
    const loadRoomNames = async () => {
      if (user) {
        const settings = await getUserSettings(user.uid);
        if (settings && settings.customRoomNames) {
          setRoomNames(settings.customRoomNames);
        } else {
          // {{ edit_1 }} Use the imported defaultRoomNames
          setRoomNames(defaultRoomNames);
          await updateUserSettings(user.uid, { customRoomNames: defaultRoomNames });
        }
      }
    };
    loadRoomNames();
  }, [user, getUserSettings, updateUserSettings]);

  const updateRoomNames = async (newRoomNames) => {
    try {
      await updateUserSettings(user.uid, { customRoomNames: newRoomNames });
      setRoomNames(newRoomNames);
      
      // Dispatch a custom event to notify other components
      window.dispatchEvent(new CustomEvent('roomNamesUpdated', {
        detail: { roomNames: newRoomNames }
      }));
    } catch (error) {
      console.error('Error updating room names:', error);
    }
  };

  const addRoomName = async () => {
    if (newRoomName.trim() && !roomNames.includes(newRoomName.trim())) {
      const updatedRoomNames = [...roomNames, newRoomName.trim()];
      await updateRoomNames(updatedRoomNames);
      setNewRoomName('');
    }
  };

  const removeRoomName = async (index) => {
    const updatedRoomNames = roomNames.filter((_, i) => i !== index);
    await updateRoomNames(updatedRoomNames);
  };

  return (
    <div className="space-y-4 animate-fade-in">
      {/* Remove or comment out the following line */}
      {/* <h2>Room Names</h2> */}
      
      <p className="text-gray-600 mb-4">Create and manage custom names for your rooms.</p>
      
      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={newRoomName}
          onChange={(e) => setNewRoomName(e.target.value)}
          placeholder="Enter room name"
          className="flex-grow p-2 border rounded-md"
        />
        <button
          onClick={addRoomName}
          className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
        >
          <Plus size={20} />
        </button>
      </div>
      
      <ul className="space-y-2">
        {roomNames.sort().map((name, index) => (
          <li key={index} className="flex justify-between items-center bg-blue-100 p-2 rounded">
            <span>{name}</span>
            <button
              onClick={() => removeRoomName(index)}
              className="text-red-500 hover:text-red-700 transition-colors duration-200"
            >
              <Trash2 size={20} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomRoomNames;
