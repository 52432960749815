// src/hooks/useTheme.js

import { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import { themeColors } from '../utils/themeColors';

export const useTheme = () => {
  const { currentTheme } = useContext(ThemeContext);
  
  if (!currentTheme || !themeColors[currentTheme]) {
    console.warn('Invalid theme or theme not found');
    return themeColors.default || {};
  }
  
  return themeColors[currentTheme];
};