// src/components/SignUp.jsx

import React, { useState } from 'react';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db } from '../Services/firebase';

const SignUp = () => {
  const [error, setError] = useState(null);

  const handleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if user already exists in allowedUsers collection
      const allowedUserRef = doc(db, "allowedUsers", user.email);
      const allowedUserDoc = await getDoc(allowedUserRef);

      if (!allowedUserDoc.exists()) {
        // Set trial period for 7 days for new users
        const trialEndTime = new Date();
        trialEndTime.setDate(trialEndTime.getDate() + 7); // 7 days trial

        await setDoc(allowedUserRef, {
          email: user.email,
          nextBillingDate: trialEndTime,
          createdAt: new Date(),
          trialEndDate: trialEndTime,
          isTrialUser: true,
          status: 'active'
        });
      }

      // Create or update user document
      const userDoc = await getDoc(doc(db, "users", user.uid));
      
      if (!userDoc.exists()) {
        await setDoc(doc(db, "users", user.uid), {
          email: user.email,
          isNewUser: true,
          hasSeenTrialPopup: false,
          hasSeenTutorial: false,
          createdAt: new Date(),
          updatedAt: new Date(),
          status: 'active'
        });
      }
    } catch (error) {
      console.error("Sign-in error:", error);
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col justify-center px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md mx-auto">
        <div className="text-center mb-8">
          <div className="w-20 h-20 bg-white rounded-lg shadow-md overflow-hidden mx-auto mb-4">
            <img 
              src="/logo512.png" 
              alt="Design Que Logo" 
              className="w-full h-full object-cover"
            />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-2">
            Welcome to Design Que
          </h2>
          <p className="text-base text-gray-600">
            Create beautiful quotations for your interior design projects
          </p>
        </div>

        {error && (
          <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-sm" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        <div className="flex justify-center">
          <button
            onClick={handleSignIn}
            className="w-11/12 sm:w-4/5 flex justify-center items-center py-3 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg className="w-5 h-5 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
              <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
              <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
              <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
              <path d="M1 1h22v22H1z" fill="none"/>
            </svg>
            Sign in with Google
          </button>
        </div>

        {/* Enhanced Video Tutorials Section */}
        <div className="mt-6 space-y-3">
          <h3 className="text-xl font-semibold text-gray-800 text-center mb-2">
            Quick Start Tutorials
          </h3>
          
          {/* Tutorial 1 */}
          <div className="relative w-11/12 sm:w-3/4 mx-auto group">
            <div className="rounded-lg shadow-[0_0_15px_rgba(0,0,0,0.15)] hover:shadow-[0_0_25px_rgba(0,0,0,0.25)] transition-shadow duration-300">
              <div className="flex items-center">
                {/* Video Container */}
                <div className="w-2/5">
                  <div className="relative bg-gradient-to-r from-teal-50 via-emerald-50 to-teal-50 rounded-l-lg overflow-hidden" 
                       style={{ aspectRatio: '16/9', maxHeight: '90px' }}>
                    {/* Wave pattern background */}
                    <div className="absolute inset-0 opacity-20">
                      <svg className="w-[200%] h-full animate-wave" viewBox="0 0 200 100" preserveAspectRatio="none">
                        <pattern id="waves" width="40" height="15" patternUnits="userSpaceOnUse">
                          <path d="M0 7.5 Q10 0, 20 7.5 Q30 15, 40 7.5" 
                                fill="none" 
                                stroke="currentColor" 
                                strokeWidth="0.8" 
                                className="text-emerald-400"/>
                        </pattern>
                        <rect width="200" height="100" fill="url(#waves)"/>
                      </svg>
                    </div>

                    {/* Tutorial Number */}
                    <div className="absolute left-2 top-2 text-emerald-600 text-sm font-medium">
                      01
                    </div>

                    {/* Play Button Link */}
                    <a href="https://youtube.com/shorts/8wj0ISfBTTE?feature=share"
                       target="_blank" 
                       rel="noopener noreferrer"
                       className="absolute inset-0 flex items-center justify-center">
                      <div className="w-9 h-9 rounded-full border-2 border-emerald-200 bg-white/90 flex items-center justify-center group-hover:border-emerald-300 group-hover:bg-white transition-all duration-300 shadow-md hover:shadow-lg">
                        <svg className="w-4 h-4 text-emerald-600 group-hover:text-emerald-700 transition-colors duration-300 translate-x-0.5" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M6.92 5.51L18.9 12l-11.98 6.49V5.51z"/>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>

                {/* Title Beside Video */}
                <div className="w-3/5 pl-2 pr-4 py-2">
                  <p className="text-gray-800 text-sm">
                    App installation tutorial for iPhone, Android, Windows
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Tutorial 2 */}
          <div className="relative w-11/12 sm:w-3/4 mx-auto group">
            <div className="rounded-lg shadow-[0_0_15px_rgba(0,0,0,0.15)] hover:shadow-[0_0_25px_rgba(0,0,0,0.25)] transition-shadow duration-300">
              <div className="flex items-center">
                {/* Video Container */}
                <div className="w-2/5">
                  <div className="relative bg-gradient-to-r from-teal-50 via-emerald-50 to-teal-50 rounded-l-lg overflow-hidden" 
                       style={{ aspectRatio: '16/9', maxHeight: '90px' }}>
                    {/* Wave pattern background - same as above */}
                    <div className="absolute inset-0 opacity-20">
                      <svg className="w-[200%] h-full animate-wave" viewBox="0 0 200 100" preserveAspectRatio="none">
                        <pattern id="waves2" width="40" height="15" patternUnits="userSpaceOnUse">
                          <path d="M0 7.5 Q10 0, 20 7.5 Q30 15, 40 7.5" 
                                fill="none" 
                                stroke="currentColor" 
                                strokeWidth="0.8" 
                                className="text-emerald-400"/>
                        </pattern>
                        <rect width="200" height="100" fill="url(#waves2)"/>
                      </svg>
                    </div>

                    {/* Tutorial Number */}
                    <div className="absolute left-2 top-2 text-emerald-600 text-sm font-medium">
                      02
                    </div>

                    {/* Play Button Link */}
                    <a href="https://youtu.be/yWC31ZkR5as"
                       target="_blank" 
                       rel="noopener noreferrer"
                       className="absolute inset-0 flex items-center justify-center">
                      <div className="w-9 h-9 rounded-full border-2 border-emerald-200 bg-white/90 flex items-center justify-center group-hover:border-emerald-300 group-hover:bg-white transition-all duration-300 shadow-md hover:shadow-lg">
                        <svg className="w-4 h-4 text-emerald-600 group-hover:text-emerald-700 transition-colors duration-300 translate-x-0.5" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M6.92 5.51L18.9 12l-11.98 6.49V5.51z"/>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>

                {/* Title Beside Video */}
                <div className="w-3/5 pl-2 pr-4 py-2">
                  <p className="text-gray-800 text-sm">
                    App Demo
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">
                Or
              </span>
            </div>
          </div>

          <div className="mt-6">
            <p className="text-center text-base text-gray-600 mb-4">
              To access the app, contact Design Que to add your email:
            </p>
            <div className="flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-6 h-6 mr-2 text-green-600">
                <path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
              </svg>
              <a href="https://wa.me/919966922000" target="_blank" rel="noopener noreferrer" className="text-green-600 hover:text-green-800 transition duration-300 text-lg font-medium">
                +91 99669 22000
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;