// src/contexts/ProductContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useFirebaseService } from '../hooks/useFirebaseService';
import { useAuth } from './AuthContext';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const { getProducts } = useFirebaseService();
  const { user } = useAuth();

  const refreshProducts = async () => {
    if (user) {
      const updatedProducts = await getProducts(user.uid);
      setProducts(updatedProducts);
    }
  };

  useEffect(() => {
    if (user) {
      refreshProducts();
    }
  }, [user]);

  return (
    <ProductContext.Provider value={{ products, refreshProducts }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => useContext(ProductContext);