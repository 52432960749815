import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFirebaseService } from '../hooks/useFirebaseService';
import InteractiveMobileQuotation from './InteractiveMobileQuotation';

const SharedQuotation = () => {
  const { shareId } = useParams();
  const navigate = useNavigate();
  const { getSharedQuotation, duplicateSharedQuotation } = useFirebaseService();
  const [quotation, setQuotation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSharedQuotation = async () => {
      try {
        const sharedQuotation = await getSharedQuotation(shareId);
        setQuotation(sharedQuotation);
      } catch (err) {
        setError('Failed to load shared quotation. It may have expired or been deleted.');
      }
    };

    fetchSharedQuotation();
  }, [shareId, getSharedQuotation]);

  const handleSaveToMyAccount = async () => {
    try {
      await duplicateSharedQuotation(shareId);
      alert('Quotation saved to your account successfully!');
      navigate('/');
    } catch (err) {
      console.error('Error saving shared quotation:', err);
      alert('Failed to save quotation to your account. Please try again.');
    }
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!quotation) {
    return <div>Loading shared quotation...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Shared Quotation</h1>
      <div className="mb-4">
        <button 
          onClick={handleSaveToMyAccount}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
        >
          Save to My Account
        </button>
        <button 
          onClick={() => navigate('/')}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
        >
          Cancel
        </button>
      </div>
      <InteractiveMobileQuotation
        editQuotationId={null}
        onSave={() => {
          // Handle save logic for shared quotation if needed
        }}
        onClose={() => {
          // Handle close logic for shared quotation if needed
        }}
        setIsDragging={() => {}}
        initialQuotationData={quotation.quotationData || {}}
      />
    </div>
  );
};

export default SharedQuotation;
