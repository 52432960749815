import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import { format, isValid, parseISO } from 'date-fns';
import { pdfThemeColors } from '../utils/pdfThemeColors';
import { useTheme } from '../hooks/useTheme';
import { pdf } from '@react-pdf/renderer';

// Register fonts
Font.register({
  family: 'NotoSans',
  fonts: [
    { src: '/NotoSans-Medium.ttf', fontWeight: 500 },
    { src: '/NotoSans-Bold.ttf', fontWeight: 700 },
  ],
});

// Helper function to format price
const formatPrice = (price) => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price).replace('₹', '₹ ');
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  try {
    let date;
    if (typeof dateString === 'string') {
      date = parseISO(dateString);
    } else if (dateString instanceof Date) {
      date = dateString;
    } else {
      return 'Invalid Date';
    }
    return isValid(date) ? format(date, 'dd MMM yyyy') : 'Invalid Date';
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid Date';
  }
};

// QuotationPDF component
const QuotationPDF = ({ quotationData, theme }) => {
  const currentTheme = pdfThemeColors[theme.toLowerCase()] || pdfThemeColors.default;

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'NotoSans',
      flexDirection: 'column',
      backgroundColor: currentTheme.itemBackground,
      padding: '16 4',
      width: '80%',
    },
    sectionHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 12,
      backgroundColor: currentTheme.roomHeader,
    },
    numberCircle: {
      backgroundColor: currentTheme.numberCircle,
    },
    section: {
      marginBottom: 20,
    },
    sectionContent: {
      backgroundColor: '#F9FAFB',
      borderRadius: 8,
      padding: 8,
      borderWidth: 1,
      borderColor: '#E5E7EB',
    },
    sectionDivider: {
      borderBottomWidth: 2,
      borderBottomColor: currentTheme.dividerColor,
      marginVertical: 12,
    },
    header: {
      flexDirection: 'row',
      marginBottom: 16,
      alignItems: 'flex-start',
    },
    companyDetails: {
      width: '70%',
      paddingRight: 8,
    },
    logoContainer: {
      width: '30%',
      height: 'auto',
      maxWidth: 70,
      maxHeight: 70,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      borderRadius: 4,
    },
    logo: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      borderRadius: 4,
    },
    companyName: {
      fontFamily: 'NotoSans',
      fontWeight: 700,
      fontSize: 7.2,
      marginBottom: 6,
      padding: 4,
      borderWidth: 1,
      borderColor: '#E5E7EB',
      borderRadius: 4,
      color: '#000000',
      backgroundColor: '#FFFFFF',
    },
    companyInfo: {
      fontFamily: 'NotoSans',
      fontSize: 7,
      fontWeight: 500,
      marginBottom: 2,
      color: '#000000',
      padding: '2 4',
      borderWidth: 1,
      borderColor: '#E5E7EB',
      borderRadius: 4,
      backgroundColor: '#FFFFFF',
      flexDirection: 'row',
    },
    companyAddress: {
      fontFamily: 'NotoSans',
      fontSize: 5,
      fontWeight: 500,
      color: '#000000',
      marginBottom: 3,
      padding: 4,
      borderWidth: 1,
      borderColor: '#E5E7EB',
      borderRadius: 4,
      backgroundColor: '#FFFFFF',
      flexDirection: 'row',
    },
    addressLabel: {
      fontFamily: 'NotoSans',
      fontWeight: 500,
    },
    clientInfoRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 2,
    },
    clientInfo: {
      fontFamily: 'NotoSans',
      fontSize: 7,
      fontWeight: 500,
      color: '#000000',
      padding: '2 4',
      borderWidth: 1,
      borderColor: '#E5E7EB',
      borderRadius: 4,
      flex: 1,
      backgroundColor: '#FFFFFF',
    },
    dateInfo: {
      fontFamily: 'NotoSans',
      fontSize: 7,
      fontWeight: 500,
      color: '#000000',
      padding: '2 4',
      borderWidth: 1,
      borderColor: '#E5E7EB',
      borderRadius: 4,
      marginLeft: 8,
      width: 80,
      backgroundColor: '#FFFFFF',
    },
    roomSection: {
      marginBottom: 8,
      backgroundColor: '#FFFFFF',
      borderRadius: 8,
      overflow: 'hidden',
    },
    roomHeader: {
      backgroundColor: currentTheme.roomHeader,
      padding: 4,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: 26,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    roomHeaderContent: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    roomName: {
      fontFamily: 'NotoSans',
      fontSize: 8.5,
      fontWeight: 700,
      color: '#000000',
      padding: 2,
    },
    roomValue: {
      fontFamily: 'NotoSans',
      fontSize: 8,
      fontWeight: 700,
      color: '#1F2937',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      padding: '2 6',
      borderRadius: 4,
      marginRight: 4,
    },
    itemsContainer: {
      padding: 4,
      backgroundColor: currentTheme.itemsContainer,
    },
    item: {
      marginBottom: 4,
      backgroundColor: '#FFFFFF',
      borderRadius: 6,
      padding: 4,
    },
    itemHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 2,
    },
    itemIndex: {
      width: 14,
      height: 14,
      borderRadius: 7,
      backgroundColor: currentTheme.numberCircle,
      justifyContent: 'center',
      alignItems: 'center',
    },
    itemIndexText: {
      fontFamily: 'NotoSans',
      color: '#FFFFFF',
      fontSize: 8,
      fontWeight: 700,
    },
    itemName: {
      fontFamily: 'NotoSans',
      fontSize: 7,
      fontWeight: 500,
      color: '#1F2937',
      flex: 1,
      marginLeft: 4,
      marginRight: 2,
    },
    itemPrice: {
      fontFamily: 'NotoSans',
      fontSize: 7,
      fontWeight: 800,
      color: '#1F2937',
    },
    itemDetails: {
      fontFamily: 'NotoSans',
      fontSize: 6,
      marginTop: 2,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    itemDetailText: {
      fontFamily: 'NotoSans',
      fontWeight: 500,
      marginRight: 24,
    },
    itemDetailLabel: {
      fontFamily: 'NotoSans',
      fontWeight: 500,
      color: '#6B7280',
    },
    itemDetailValue: {
      fontFamily: 'NotoSans',
      fontWeight: 500,
      color: '#000000',
    },
    itemDimensionText: {
      fontFamily: 'NotoSans',
      fontWeight: 500,
      color: '#6B7280',
    },
    spacer: {
      width: 16,
    },
    totalSection: {
      backgroundColor: '#FFFFFF',
      borderRadius: 8,
      padding: 12,
      marginTop: 12,
      borderWidth: 1,
      borderColor: '#E5E7EB',
    },
    totalRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 6,
    },
    totalLabel: {
      fontFamily: 'NotoSans',
      fontSize: 9,
      fontWeight: 500,
      color: '#4B5563',
    },
    totalValue: {
      fontFamily: 'NotoSans',
      fontSize: 9,
      fontWeight: 700,
      color: '#1F2937',
    },
    discountValue: {
      fontFamily: 'NotoSans',
      fontSize: 9,
      fontWeight: 700,
      color: '#10B981',
    },
    grandTotal: {
      marginTop: 6,
      paddingTop: 6,
      borderTopWidth: 1,
      borderTopColor: '#E5E7EB', // Keep this light border for separation
    },
    grandTotalLabel: {
      fontFamily: 'NotoSans',
      fontSize: 12,
      fontWeight: 700,
      color: '#1F2937',
    },
    grandTotalValue: {
      fontFamily: 'NotoSans',
      fontSize: 12,
      fontWeight: 700,
      color: '#1F2937',
    },
    subsection: {
      marginBottom: 12,
    },
    subsectionTitle: {
      fontFamily: 'NotoSans',
      fontSize: 10,
      fontWeight: 700,
      color: '#1F2937',
      marginBottom: 6,
    },
    paymentTerm: {
      marginBottom: 2, // Reduce gap between rectangles
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      padding: 3, // Reduce padding to decrease height
      borderWidth: 1,
      borderColor: '#E5E7EB',
    },
    paymentTermRow: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    paymentTermContent: {
      flex: 1,
    },
    paymentTermDescription: {
      fontFamily: 'NotoSans',
      fontSize: 7,
      fontWeight: 500,
      color: '#374151',
      marginBottom: 1, // Reduce margin to fit in smaller rectangle
    },
    paymentTermDetails: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 0, // Reduce row gap between payment description and percentage
    },
    paymentTermPercentage: {
      fontFamily: 'NotoSans',
      fontSize: 6, // Reduce text size
      fontWeight: 700,
      color: '#1F2937',
    },
    paymentTermAmount: {
      fontFamily: 'NotoSans',
      fontSize: 6, // Reduce text size
      fontWeight: 700,
      color: '#1F2937',
    },
    serialNumber: {
      fontFamily: 'NotoSans',
      fontSize: 7,
      color: '#4B5563',
      marginRight: 4,
      marginTop: 1,
    },
    material: {
      marginBottom: 2,
      backgroundColor: '#FFFFFF',
      padding: 3, // Reduce padding to decrease height
      borderRadius: 4,
      borderWidth: 1,
      borderColor: '#E5E7EB',
    },
    materialName: {
      fontFamily: 'NotoSans',
      fontSize: 6,
      fontWeight: 700,
      color: '#1F2937',
      marginBottom: 1, // Add small margin to separate from description
    },
    materialDescription: {
      fontFamily: 'NotoSans',
      fontSize: 7,
      fontWeight: 500,
      color: '#374151',
    },
    note: {
      fontFamily: 'NotoSans',
      fontSize: 7,
      fontWeight: 500,
      color: '#374151',
      marginBottom: 2,
      backgroundColor: '#FFFFFF',
      padding: 3, // Reduce padding to decrease height
      borderRadius: 4,
      borderWidth: 1,
      borderColor: '#E5E7EB',
    },
    labelText: {
      fontFamily: 'NotoSans',
      fontSize: 5.5,
      fontWeight: 500,
      color: '#6B7280',
      marginRight: 2,
    },
    contentText: {
      fontFamily: 'NotoSans',
      fontSize: 7,
      fontWeight: 500,
      color: '#000000',
    },
    addressContentText: {
      fontFamily: 'NotoSans',
      fontSize: 5.5,
      fontWeight: 500,
      color: '#000000',
    },
  });

  const {
    companyName,
    mobileNumber,
    logo,
    companyAddress,
    clientName,
    date,
    rooms,
    discount,
    total,
    grandTotal,
    hideUnitPrice,
    paymentTerms,
    materials,
    notes,
    isGstEnabled,
    isDiscountEnabled,
    gst
  } = quotationData;

  return (
    <Document>
      <Page size={[256, 'auto']} style={styles.page}>
        {/* Section 1: Company & Client Details */}
        <View style={styles.section}>
          <View style={styles.sectionContent}>
            <View style={styles.header}>
              <View style={styles.companyDetails}>
                <Text style={styles.companyName}>{companyName}</Text>
                <Text style={styles.companyAddress}>
                  <Text style={styles.labelText}>Address: </Text>
                  <Text style={styles.addressContentText}>{companyAddress}</Text>
                </Text>
                <Text style={styles.companyInfo}>
                  <Text style={styles.labelText}>Phone: </Text>
                  <Text style={styles.contentText}>{mobileNumber}</Text>
                </Text>
              </View>
              {logo && (
                <View style={styles.logoContainer}>
                  <Image source={logo} style={styles.logo} />
                </View>
              )}
            </View>
            
            <View style={styles.clientInfoRow}>
              <Text style={styles.clientInfo}>
                <Text style={styles.labelText}>Client: </Text>
                <Text style={styles.contentText}>{clientName}</Text>
              </Text>
              <Text style={styles.dateInfo}>
                <Text style={styles.labelText}>Date: </Text>
                <Text style={styles.contentText}>{formatDate(date)}</Text>
              </Text>
            </View>
          </View>
        </View>
        
        {/* Section 2: Items & Pricing */}
        <View style={styles.section}>
          <View style={styles.sectionContent}>
            {(rooms || []).map((room, roomIndex) => (
              <View key={roomIndex} style={styles.roomSection}>
                <View style={styles.roomHeader}>
                  <View style={styles.roomHeaderContent}>
                    <Text style={styles.roomName}>{room.name || 'Room Name'}</Text>
                    <Text style={styles.roomValue}>
                      {formatPrice(room.items.reduce((total, item) => total + item.totalPrice, 0))}
                    </Text>
                  </View>
                </View>
                <View style={styles.itemsContainer}>
                  {(room.items || []).map((item, itemIndex) => (
                    <View key={itemIndex} style={styles.item}>
                      <View style={styles.itemHeader}>
                        <View style={styles.itemIndex}>
                          <Text style={styles.itemIndexText}>{itemIndex + 1}</Text>
                        </View>
                        <Text style={styles.itemName}>{item.name}</Text>
                        <Text style={styles.itemPrice}>{formatPrice(item.totalPrice)}</Text>
                      </View>
                      <View style={styles.itemDetails}>
                        {(item.length !== 0 || item.height !== 0) && (
                          <Text style={styles.itemDetailText}>
                            <Text style={styles.itemDetailValue}>{item.length}'</Text>
                            <Text style={styles.itemDimensionText}>w X </Text>
                            <Text style={styles.itemDetailValue}>{item.height}'</Text>
                            <Text style={styles.itemDimensionText}>h ft</Text>
                          </Text>
                        )}
                        <Text style={styles.itemDetailText}>
                          <Text style={styles.itemDetailLabel}>Area/No.S: </Text>
                          <Text style={styles.itemDetailValue}>{item.area}</Text>
                        </Text>
                        {!hideUnitPrice && (
                          <Text style={styles.itemDetailText}>
                            <Text style={styles.itemDetailLabel}>Unit Price: </Text>
                            <Text style={styles.itemDetailValue}>{formatPrice(item.boxPrice)}</Text>
                          </Text>
                        )}
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            ))}
            
            <View style={styles.totalSection}>
              {(!isGstEnabled && !isDiscountEnabled) ? (
                <View style={[styles.totalRow, styles.grandTotal]}>
                  <Text style={styles.grandTotalLabel}>Grand Total:</Text>
                  <Text style={styles.grandTotalValue}>{formatPrice(grandTotal)}</Text>
                </View>
              ) : (
                <>
                  <View style={styles.totalRow}>
                    <Text style={styles.totalLabel}>Total:</Text>
                    <Text style={styles.totalValue}>{formatPrice(total)}</Text>
                  </View>
                  {isDiscountEnabled && discount !== 0 && (
                    <View style={styles.totalRow}>
                      <Text style={styles.totalLabel}>Discount:</Text>
                      <Text style={styles.discountValue}>
                        {discount}% (-{formatPrice(total * discount / 100)})
                      </Text>
                    </View>
                  )}
                  {isGstEnabled && (
                    <View style={styles.totalRow}>
                      <Text style={styles.totalLabel}>GST ({gst}%):</Text>
                      <Text style={styles.totalValue}>
                        {formatPrice(((total - (total * discount / 100)) * gst / 100))}
                      </Text>
                    </View>
                  )}
                  <View style={[styles.totalRow, styles.grandTotal]}>
                    <Text style={styles.grandTotalLabel}>Grand Total:</Text>
                    <Text style={styles.grandTotalValue}>{formatPrice(grandTotal)}</Text>
                  </View>
                </>
              )}
            </View>
          </View>
        </View>
        
        {/* Section 3: Terms & Specifications */}
        <View style={styles.section}>
          <View style={styles.sectionContent}>
            {/* Payment Terms */}
            {paymentTerms.length > 0 && (
              <View style={styles.subsection}>
                <Text style={styles.subsectionTitle}>Payment Terms</Text>
                {paymentTerms.map((term, index) => (
                  <View key={index} style={styles.paymentTerm}>
                    <View style={styles.paymentTermRow}>
                      <Text style={styles.serialNumber}>{index + 1}.</Text>
                      <View style={styles.paymentTermContent}>
                        <Text style={styles.paymentTermDescription}>{term.description}</Text>
                        <View style={styles.paymentTermDetails}>
                          <Text style={styles.paymentTermPercentage}>{term.percentage}%</Text>
                          <Text style={styles.paymentTermAmount}>
                            {formatPrice(grandTotal * term.percentage / 100)}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                ))}
              </View>
            )}

            {/* Material Specification */}
            {materials.length > 0 && (
              <View style={styles.subsection}>
                <Text style={styles.subsectionTitle}>Material Specification</Text>
                {materials.map((material, index) => (
                  <View key={index} style={styles.material}>
                    <Text>
                      <Text style={styles.serialNumber}>{index + 1}.</Text>
                      <Text style={styles.materialName}>{material.name}:</Text>
                    </Text>
                    <Text style={styles.materialDescription}>{material.description}</Text>
                  </View>
                ))}
              </View>
            )}

            {/* Notes */}
            {notes.length > 0 && (
              <View style={styles.subsection}>
                <Text style={styles.subsectionTitle}>Notes</Text>
                {notes.map((note, index) => (
                  <Text key={index} style={styles.note}>
                    <Text style={styles.serialNumber}>{index + 1}. </Text>
                    {note}
                  </Text>
                ))}
              </View>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
};

// DownloadPDF component (updated)
const DownloadPDF = ({ quotationData, children, isMobile }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const theme = useTheme();

  const generatePDF = async (data) => {
    const document = <QuotationPDF quotationData={data} theme={theme.name} />;
    return await pdf(document).toBlob();
  };

  const handleDownload = async () => {
    setLoading(true);
    setError(null);

    try {
      const pdfBlob = await generatePDF(quotationData);
      
      const formattedDate = format(new Date(), 'dd MMM yyyy');
      const fileName = `${quotationData.companyName}_${quotationData.clientName}_Quotation_${formattedDate}.pdf`;

      if (isMobile) {
        const file = new File([pdfBlob], fileName, { type: 'application/pdf' });
        if (navigator.share) {
          await navigator.share({
            files: [file],
            title: 'Quotation PDF',
            text: 'Here is your quotation PDF',
          });
        } else {
          // Fallback for devices that don't support sharing
          const pdfUrl = URL.createObjectURL(pdfBlob);
          window.open(pdfUrl, '_blank');
        }
      } else {
        // For desktop, directly download the PDF
        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = fileName;
        link.click();
      }
      
      setLoading(false);
    } catch (err) {
      console.error('Error generating or sharing PDF:', err);
      setError('Failed to generate or share PDF. Please try again.');
      setLoading(false);
    }
  };

  return children({ loading, error, handleDownload });
};

export default DownloadPDF;