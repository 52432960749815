import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useFirebaseService } from '../hooks/useFirebaseService';
import { useProductContext } from '../contexts/ProductContext';
import { useAuth } from '../contexts/AuthContext';
import { Plus, Minus, Trash2, Check, Edit2, X, Search, Download, FileText, AlertTriangle } from 'lucide-react';
import { debounce } from 'lodash';
import ExcelJS from 'exceljs';
import { defaultItems } from '../defaultItems';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../Services/firebase';

const CircularButton = ({ onClick, className, children }) => (
  <button
    onClick={onClick}
    className={`w-10 h-10 rounded-full flex items-center justify-center ${className}`}
  >
    {children}
  </button>
);

const RectangularButton = ({ onClick, className, children }) => (
  <button
    onClick={onClick}
    className={`w-12 h-10 rounded-lg flex items-center justify-center ${className}`}
  >
    {children}
  </button>
);

const Input = ({ className, ...props }) => (
  <input
    className={`border border-gray-300 rounded-md px-3 py-2 ${className}`}
    {...props}
  />
);

const ItemsList = ({ onRefreshQuote }) => {
  const [products, setProducts] = useState([]);
  const [editingProductId, setEditingProductId] = useState(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newProduct, setNewProduct] = useState({ name: '', height: 0, unitPrice: 0 });
  const [searchQuery, setSearchQuery] = useState('');
  const [activeSection, setActiveSection] = useState('list');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteMode, setDeleteMode] = useState(null);
  const [userLoaded, setUserLoaded] = useState(false);
  const [defaultItemsLoaded, setDefaultItemsLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isTrialUser, setIsTrialUser] = useState(true);
  const [showTrialFeaturePopup, setShowTrialFeaturePopup] = useState(false);
  const [restrictedFeature, setRestrictedFeature] = useState('');

  const { getProducts, addProduct, updateProduct, deleteProduct, checkDefaultItemsLoaded, setDefaultItemsLoadedFlag, isFirstTimeUser, setFirstTimeUserFlag } = useFirebaseService();
  const { refreshProducts } = useProductContext();
  const { user } = useAuth();

  useEffect(() => {
    const fetchProductsAndCheckFirstTime = async () => {
      if (user) {
        try {
          const isFirstTime = await isFirstTimeUser(user.uid);
          if (isFirstTime) {
            await loadDefaultItems();
            await setFirstTimeUserFlag(user.uid);
          }
          const fetchedProducts = await getProducts(user.uid);
          setProducts(fetchedProducts);
        } catch (error) {
          console.error('Error fetching products or loading default items:', error);
        }
      }
    };
    fetchProductsAndCheckFirstTime();
  }, [getProducts, user, isFirstTimeUser, setFirstTimeUserFlag]);

  useEffect(() => {
    if (user) {
      setUserLoaded(true);
    }
  }, [user]);

  useEffect(() => {
    const checkTrialStatus = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userData = userDoc.data();
          setIsTrialUser(userData?.isTrialUser ?? true);
        } catch (error) {
          console.error('Error checking trial status:', error);
          setIsTrialUser(true);
        }
      }
    };
    checkTrialStatus();
  }, [user]);

  const debouncedSearch = debounce((value) => {
    setSearchQuery(value);
  }, 300);

  const handleInputChange = (id, field, value) => {
    if (id === 'new') {
      setNewProduct(prev => ({
        ...prev,
        [field]: field === 'height' || field === 'unitPrice'
          ? value === '' ? '' : Number(value)
          : value
      }));
    } else {
      setProducts(prev => prev.map(p => 
        p.id === id ? {
          ...p,
          [field]: field === 'height' || field === 'unitPrice'
            ? value === '' ? '' : Number(value)
            : value
        } : p
      ));
    }
  };

  const handleAddProduct = async () => {
    if (user) {
      setIsAddingNew(true);
      setNewProduct({ name: '', height: 0, unitPrice: 0 });
    }
  };

  const handleSaveNewProduct = async () => {
    if (user && newProduct.name) {
      try {
        const existingProduct = products.find(p => p.name === newProduct.name);
        if (!existingProduct) {
          const addedProduct = await addProduct(user.uid, { ...newProduct, source: 'manual' });
          setProducts(prev => [addedProduct, ...prev.sort((a, b) => a.name.localeCompare(b.name))]);
          setIsAddingNew(false);
          setNewProduct({ name: '', height: 0, unitPrice: 0 });
          
          // Refresh the global product context to update the quote page
          await refreshProducts();
        } else {
          console.warn('Product already exists:', newProduct.name);
        }
      } catch (error) {
        console.error('Error adding product:', error);
      }
    } else {
      console.warn('Product name is required');
    }
  };

  const handleDeleteProduct = async (id) => {
    if (user) {
      try {
        await deleteProduct(user.uid, id);
        setProducts(prev => prev.filter(p => p.id !== id));
        setEditingProductId(null);
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  const handleSaveUpdatedProduct = async (product) => {
    if (user) {
      try {
        await updateProduct(user.uid, product.id, product);
        setProducts(prev => prev.map(p => (p.id === product.id ? product : p)));
        setEditingProductId(null);
        
        // Refresh the global product context to update the quote page
        await refreshProducts();
      } catch (error) {
        console.error('Error updating product:', error);
      }
    }
  };

  const handleFileUpload = async (event) => {
    if (isTrialUser) {
      handleTrialFeature('file upload');
      event.target.value = '';
      return;
    }
    const file = event.target.files[0];
    setIsLoading(true);
    setProgress(0);

    try {
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(await file.arrayBuffer());
      
      const worksheet = workbook.getWorksheet(1);
      const jsonData = [];

      worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        if (rowNumber > 1) { // Assuming first row is headers
          const [itemName, height, unitPrice] = row.values.slice(1); // ExcelJS uses 1-based indexing
          jsonData.push({
            'Item Name': itemName,
            'Height (ft)': height,
            'Unit Price (₹)': unitPrice
          });
        }
      });
      
      const totalItems = jsonData.length;
      let processedItems = 0;

      for (const item of jsonData) {
        const existingProduct = products.find(p => p.name === item['Item Name']);
        if (!existingProduct && item['Item Name']) {
          const newProduct = {
            name: item['Item Name'],
            height: item['Height (ft)'] !== undefined ? Number(item['Height (ft)']) : 0,
            unitPrice: item['Unit Price (₹)'] !== undefined ? Number(item['Unit Price (₹)']) : 0,
            source: 'uploaded'
          };
          await addProduct(user.uid, newProduct);
        }
        processedItems++;
        setProgress(Math.round((processedItems / totalItems) * 100));
      }

      const updatedProducts = await getProducts(user.uid);
      setProducts(updatedProducts);
      
      // Refresh the global product context
      await refreshProducts();
    } catch (error) {
      console.error('Error processing file:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  };

  const downloadSampleFile = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sample Items');

    worksheet.columns = [
      { header: 'Item Name', key: 'name', width: 30 },
      { header: 'Height (ft)', key: 'height', width: 15 },
      { header: 'Unit Price (₹)', key: 'unitPrice', width: 15 }
    ];

    const sampleData = [
      { name: 'Example 1', height: 10, unitPrice: 1000 },
      { name: 'Example 2', height: 12, unitPrice: 1500 },
    ];

    worksheet.addRows(sampleData);

    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'sample_items.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const downloadAllItems = () => {
    if (isTrialUser) {
      handleTrialFeature('export');
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('All Items');

    worksheet.columns = [
      { header: 'Item Name', key: 'name', width: 30 },
      { header: 'Height (ft)', key: 'height', width: 15 },
      { header: 'Unit Price (₹)', key: 'unitPrice', width: 15 }
    ];

    const filteredItems = products.map(({ name, height, unitPrice }) => ({
      name,
      height,
      unitPrice
    }));

    worksheet.addRows(filteredItems);

    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'all_items.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const loadDefaultItems = async () => {
    setIsLoading(true);
    try {
      const addPromises = defaultItems.map(async (item) => {
        const existingProduct = products.find(p => p.name === item.name);
        if (!existingProduct) {
          return addProduct(user.uid, { ...item, source: 'default' });
        }
        return null;
      });
      await Promise.all(addPromises);
      
      const updatedProducts = await getProducts(user.uid);
      setProducts(updatedProducts);
      
      await refreshProducts();
      
      // Trigger a refresh of the quote tab
      if (typeof onRefreshQuote === 'function') {
        onRefreshQuote();
      }
    } catch (error) {
      console.error('Error loading default items:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDefaultItemsCount = () => {
    return products.filter(product => product.source === 'default').length;
  };

  const getManuallyAddedItemsCount = () => {
    return products.filter(product => product.source === 'manual').length;
  };

  const getUploadedItemsCount = () => {
    return products.filter(product => product.source === 'uploaded').length;
  };

  const handleDeleteDefaultItems = async () => {
    setShowConfirmation(false);
    setIsLoading(true);
    setProgress(0);
    try {
      const defaultItemNames = defaultItems.map(item => item.name);
      const itemsToDelete = products.filter(product => defaultItemNames.includes(product.name));
      
      const totalItems = itemsToDelete.length;
      let deletedItems = 0;

      for (const product of itemsToDelete) {
        await deleteProduct(user.uid, product.id);
        deletedItems++;
        setProgress(Math.round((deletedItems / totalItems) * 100));
      }
      
      const updatedProducts = await getProducts(user.uid);
      setProducts(updatedProducts);
      
      await refreshProducts();
      
      console.log('Default items deleted successfully');
    } catch (error) {
      console.error('Error deleting default items:', error);
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  };

  const handleDeleteManualItems = async () => {
    setShowConfirmation(false);
    setIsLoading(true);
    setProgress(0);
    try {
      const defaultItemNames = defaultItems.map(item => item.name);
      const itemsToDelete = products.filter(product => !defaultItemNames.includes(product.name));
      
      const totalItems = itemsToDelete.length;
      let deletedItems = 0;

      for (const product of itemsToDelete) {
        await deleteProduct(user.uid, product.id);
        deletedItems++;
        setProgress(Math.round((deletedItems / totalItems) * 100));
      }
      
      const updatedProducts = await getProducts(user.uid);
      setProducts(updatedProducts);
      
      await refreshProducts();
      
      console.log('Manually added items deleted successfully');
    } catch (error) {
      console.error('Error deleting manually added items:', error);
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  };

  const handleDeleteUploadedItems = async () => {
    setShowConfirmation(false);
    setIsLoading(true);
    setProgress(0);
    try {
      const itemsToDelete = products.filter(product => product.source === 'uploaded');
      
      const totalItems = itemsToDelete.length;
      let deletedItems = 0;

      for (const product of itemsToDelete) {
        await deleteProduct(user.uid, product.id);
        deletedItems++;
        setProgress(Math.round((deletedItems / totalItems) * 100));
      }
      
      const updatedProducts = await getProducts(user.uid);
      setProducts(updatedProducts);
      
      await refreshProducts();
      
      console.log('Uploaded items deleted successfully');
    } catch (error) {
      console.error('Error deleting uploaded items:', error);
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  };

  const handleTrialFeature = (feature) => {
    setRestrictedFeature(feature);
    setShowTrialFeaturePopup(true);
  };

  const filteredProducts = useMemo(() => {
    if (!searchQuery.trim()) return products;
    const searchTerms = searchQuery.toLowerCase().split(/\s+/);
    return products.filter(product => 
      searchTerms.every(term => 
        product.name.toLowerCase().split(/\s+/).some(word => 
          word.startsWith(term)
        )
      )
    );
  }, [products, searchQuery]);

  const renderProductForm = (product, isNew = false) => (
    <div className="space-y-4">
      <Input 
        placeholder="Item Name" 
        value={product.name} 
        onChange={(e) => handleInputChange(isNew ? 'new' : product.id, 'name', e.target.value)} 
        className="w-full h-12 text-lg"
      />
      <div className="flex justify-between items-center">
        <span className="text-lg text-black">Height (ft):</span>
        <div className="flex items-center">
          <RectangularButton onClick={() => handleInputChange(isNew ? 'new' : product.id, 'height', Math.max(0, Number(product.height || 0) - 1))} className="bg-blue-200 text-blue-600">
            <Minus size={20} />
          </RectangularButton>
          <Input 
            type="number"
            value={product.height}
            onChange={(e) => handleInputChange(isNew ? 'new' : product.id, 'height', e.target.value)}
            className="w-24 h-12 text-lg text-center mx-2"
          />
          <RectangularButton onClick={() => handleInputChange(isNew ? 'new' : product.id, 'height', Number(product.height || 0) + 1)} className="bg-blue-200 text-blue-600">
            <Plus size={20} />
          </RectangularButton>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-lg text-black">Unit Price ():</span>
        <div className="flex items-center">
          <RectangularButton onClick={() => handleInputChange(isNew ? 'new' : product.id, 'unitPrice', Math.max(0, Number(product.unitPrice || 0) - 50))} className="bg-blue-200 text-blue-600">
            <Minus size={20} />
          </RectangularButton>
          <Input 
            type="number"
            value={product.unitPrice}
            onChange={(e) => handleInputChange(isNew ? 'new' : product.id, 'unitPrice', e.target.value)}
            className="w-24 h-12 text-lg text-center mx-2"
          />
          <RectangularButton onClick={() => handleInputChange(isNew ? 'new' : product.id, 'unitPrice', Number(product.unitPrice || 0) + 50)} className="bg-blue-200 text-blue-600">
            <Plus size={20} />
          </RectangularButton>
        </div>
      </div>
      <div className="flex justify-between items-center mt-4">
        {isNew ? (
          <>
            <CircularButton onClick={() => setIsAddingNew(false)} className="bg-gray-200 hover:bg-gray-300 text-gray-700">
              <X size={20} />
            </CircularButton>
            <CircularButton onClick={handleSaveNewProduct} className="bg-green-500 hover:bg-green-600 text-white">
              <Check size={20} />
            </CircularButton>
          </>
        ) : (
          <>
            <CircularButton onClick={() => handleDeleteProduct(product.id)} className="bg-red-500 hover:bg-red-600 text-white">
              <Trash2 size={20} />
            </CircularButton>
            <CircularButton onClick={() => handleSaveUpdatedProduct(product)} className="bg-green-500 hover:bg-green-600 text-white">
              <Check size={20} />
            </CircularButton>
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="w-full max-w-md mx-auto bg-indigo-50 min-h-screen p-4">
      <h1 className="text-2xl font-bold mb-4 text-gray-800">Items Management</h1>
      
      <div className="flex mb-4">
        <button 
          onClick={() => setActiveSection('list')}
          className={`flex-grow h-10 px-4 border-b-2 ${activeSection === 'list' ? 'border-indigo-500 bg-white text-indigo-500' : 'border-transparent bg-gray-200 text-gray-700'} rounded-tl-lg rounded-tr-lg transition duration-200`}
        >
          Items List
        </button>
        <button 
          onClick={() => setActiveSection('manager')}
          className={`flex-grow h-10 px-4 border-b-2 ${activeSection === 'manager' ? 'border-indigo-500 bg-white text-indigo-500' : 'border-transparent bg-gray-200 text-gray-700'} rounded-tr-lg rounded-br-lg transition duration-200`}
        >
          Items Manager
        </button>
      </div>

      {activeSection === 'list' && (
        <div>
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={handleAddProduct}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full flex items-center justify-center transition duration-200 ease-in-out"
            >
              <Plus size={20} className="mr-2" /> Add Item
            </button>
            <span className="text-gray-600 font-medium">
              Total Items: {products.length}
            </span>
          </div>

          {isAddingNew && (
            <div className="mb-4 bg-white p-4 rounded-lg shadow-md">
              {renderProductForm(newProduct, true)}
            </div>
          )}

          <div className="mb-4 relative">
            <Input
              type="text"
              placeholder="Search items..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full h-12 text-lg pl-10"
            />
            <Search className="absolute left-3 top-3.5 text-gray-400" size={20} />
          </div>

          <div className="space-y-3">
            {filteredProducts.map((product, index) => (
              <div key={product.id} className="bg-white p-4 rounded-lg shadow-md">
                {editingProductId === product.id ? (
                  renderProductForm(product)
                ) : (
                  <div className="flex items-start justify-between">
                    <div className="flex items-center space-x-3">
                      <div className="bg-indigo-500 text-white rounded-full w-6 h-6 flex items-center justify-center">
                        {index + 1}
                      </div>
                      <div className="space-y-1">
                        <div className="font-medium">{product.name}</div>
                        <div className="text-sm">
                          {product.height > 0 && (
                            <>
                              <span className="font-bold">{product.height}'</span>h •
                            </>
                          )}
                          Unit Price: <span className="font-bold">₹{product.unitPrice}</span>
                        </div>
                      </div>
                    </div>
                    <CircularButton onClick={() => setEditingProductId(product.id)} className="bg-indigo-200 hover:bg-indigo-300 text-indigo-700">
                      <Edit2 size={16} />
                    </CircularButton>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {activeSection === 'manager' && (
        <div className="mt-4 bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">Items Manager</h2>
          
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium mb-2 text-gray-700">Import/Export</h3>
              <div className="flex flex-col space-y-2">
                <button onClick={downloadSampleFile} className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md flex items-center justify-center transition duration-200 ease-in-out">
                  <FileText size={20} className="mr-2" /> Download Sample File
                </button>
                <button 
                  onClick={downloadAllItems} 
                  className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md flex items-center justify-center transition duration-200 ease-in-out"
                >
                  <Download size={20} className="mr-2" /> Export Existing Items
                </button>
                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Upload Items File:</label>
                  <input 
                    type="file" 
                    accept=".xlsx" 
                    onChange={handleFileUpload} 
                    className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100" 
                  />
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium mb-2 text-gray-700">Manage Items</h3>
              <div className="flex flex-col space-y-2">
                <button onClick={loadDefaultItems} className="bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded-md flex items-center justify-center transition duration-200 ease-in-out">
                  <FileText size={20} className="mr-2" /> Load Default Items
                </button>
                <button 
                  onClick={() => setDeleteMode('options')} 
                  className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md flex items-center justify-center transition duration-200 ease-in-out"
                >
                  <Trash2 size={20} className="mr-2" /> Delete Items
                </button>
              </div>
            </div>
          </div>

          {deleteMode === 'options' && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
                <h2 className="text-xl font-bold mb-4">Select Delete Option</h2>
                <div className="space-y-2">
                  <button 
                    onClick={() => {
                      setDeleteMode('confirm');
                      setShowConfirmation(true);
                    }}
                    className="w-full px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200"
                  >
                    Delete Default Items ({getDefaultItemsCount()})
                  </button>
                  <button 
                    onClick={() => {
                      setDeleteMode('confirmManual');
                      setShowConfirmation(true);
                    }}
                    className="w-full px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200"
                  >
                    Delete Manually Added Items ({getManuallyAddedItemsCount()})
                  </button>
                  <button 
                    onClick={() => {
                      setDeleteMode('confirmUploaded');
                      setShowConfirmation(true);
                    }}
                    className="w-full px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200"
                  >
                    Delete Uploaded Items ({getUploadedItemsCount()})
                  </button>
                </div>
                <button 
                  onClick={() => setDeleteMode(null)}
                  className="mt-4 w-full px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition duration-200"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          {showConfirmation && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
                <div className="flex items-center mb-4">
                  <AlertTriangle size={24} className="text-red-500 mr-2" />
                  <h2 className="text-xl font-bold">Confirm Deletion</h2>
                </div>
                <p className="mb-4 text-gray-600">
                  Are you sure you want to delete {
                    deleteMode === 'confirm' ? 'default' : 
                    deleteMode === 'confirmManual' ? 'manually added' : 'uploaded'
                  } items? 
                  This will remove {
                    deleteMode === 'confirm' ? getDefaultItemsCount() : 
                    deleteMode === 'confirmManual' ? getManuallyAddedItemsCount() : 
                    getUploadedItemsCount()
                  } items.
                  This action cannot be undone.
                </p>
                <div className="flex justify-end space-x-2">
                  <button 
                    onClick={() => {
                      setShowConfirmation(false);
                      setDeleteMode(null);
                    }}
                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition duration-200"
                  >
                    Cancel
                  </button>
                  <button 
                    onClick={
                      deleteMode === 'confirm' ? handleDeleteDefaultItems : 
                      deleteMode === 'confirmManual' ? handleDeleteManualItems :
                      handleDeleteUploadedItems
                    }
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200"
                  >
                    Delete Items
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-lg font-semibold mb-2">Processing...</p>
            <div className="w-64 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div className="bg-blue-600 h-2.5 rounded-full" style={{width: `${progress}%`}}></div>
            </div>
            <p className="text-sm mt-2">{progress}% Complete</p>
          </div>
        </div>
      )}

      {showTrialFeaturePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
            <div className="flex justify-between items-start mb-4">
              <div className="flex items-center space-x-2">
                <div className="w-10 h-10 bg-amber-100 rounded-full flex items-center justify-center">
                  <AlertTriangle className="w-5 h-5 text-amber-600" />
                </div>
                <h2 className="text-lg font-semibold text-gray-900">Trial Version</h2>
              </div>
              <button
                onClick={() => setShowTrialFeaturePopup(false)}
                className="text-gray-400 hover:text-gray-500 transition-colors"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
            
            <div className="mt-3 space-y-2">
              <p className="text-gray-600 text-sm">
                The {restrictedFeature} feature is only available in the full version. Please upgrade to access all features.
              </p>
              <div className="bg-amber-50 rounded-lg p-3">
                <p className="text-sm text-amber-700">
                  Upgrade now to unlock all premium features!
                </p>
              </div>
            </div>

            <div className="mt-4 flex justify-end space-x-3">
              <button
                onClick={() => setShowTrialFeaturePopup(false)}
                className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800 font-medium"
              >
                Maybe Later
              </button>
              <a
                href="https://wa.me/919966922000?text=Hi,%20I%20want%20to%20upgrade%20my%20Design%20Quote%20App%20subscription"
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-1.5 bg-green-500 text-white rounded hover:bg-green-600 font-medium shadow-sm hover:shadow transition-all text-sm"
                onClick={() => setShowTrialFeaturePopup(false)}
              >
                Upgrade Now
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemsList;