import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../Services/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  signInWithPhoneNumber,
  RecaptchaVerifier,
  updateProfile,
  signOut as firebaseSignOut
} from 'firebase/auth';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      
      // Don't modify billing dates on re-authentication
      const allowedUserRef = doc(db, "allowedUsers", result.user.email);
      const allowedUserDoc = await getDoc(allowedUserRef);
      
      if (!allowedUserDoc.exists()) {
        // Only for first-time users
        const trialEndTime = new Date(Date.now() + (7 * 24 * 60 * 60 * 1000));
        await setDoc(allowedUserRef, {
          email: result.user.email,
          nextBillingDate: trialEndTime,
          createdAt: new Date(),
          trialEndDate: trialEndTime
        });
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
      throw error;
    }
  };

  const signInWithPhone = async (phoneNumber) => {
    const appVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
    try {
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      return confirmationResult;
    } catch (error) {
      console.error('Error signing in with phone:', error);
      throw error;
    }
  };

  const verifyOtp = async (confirmationResult, otp) => {
    try {
      await confirmationResult.confirm(otp);
    } catch (error) {
      console.error('Error verifying OTP:', error);
      throw error;
    }
  };

  const updateUserProfile = async (profileData) => {
    if (!auth.currentUser) throw new Error('No user is currently signed in');
    
    try {
      await updateProfile(auth.currentUser, profileData);
      
      // Update the user document in Firestore
      await setDoc(doc(db, 'users', auth.currentUser.uid), {
        ...profileData,
        updatedAt: new Date().toISOString()
      }, { merge: true });
      
      // Update the local user state
      setUser(prevUser => ({ ...prevUser, ...profileData }));
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  };

  const value = {
    user,
    setUser,
    signInWithGoogle,
    signInWithPhone,
    verifyOtp,
    updateUserProfile,
    signOut,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
