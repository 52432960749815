// src/utils/themeColors.js

export const themeColors = {
    default: {
      name: 'Default',
      roomHeader: 'bg-indigo-200',
      itemsArea: 'bg-indigo-100',
      addItemButton: 'bg-indigo-500 hover:bg-indigo-600',
      numberCircle: 'bg-indigo-500',
      inputFocus: 'focus:ring-indigo-500',
      numberInputButtons: 'bg-indigo-100 text-indigo-800 hover:bg-indigo-200',
      editButton: 'bg-indigo-100 text-indigo-500',
      dropdownFocus: 'focus:ring-indigo-500',
      dropdownOptionHover: 'bg-indigo-100',
      itemBackground: 'bg-white'
    },
    ocean: {
      name: 'Ocean',
      roomHeader: 'bg-blue-200',
      itemsArea: 'bg-blue-100',
      addItemButton: 'bg-blue-500 hover:bg-blue-600',
      numberCircle: 'bg-blue-500',
      inputFocus: 'focus:ring-blue-500',
      numberInputButtons: 'bg-blue-100 text-blue-800 hover:bg-blue-200',
      editButton: 'bg-blue-100 text-blue-500',
      dropdownFocus: 'focus:ring-blue-500',
      dropdownOptionHover: 'bg-blue-100',
      itemBackground: 'bg-white'
    },
    forest: {
      name: 'Forest',
      roomHeader: 'bg-green-200',
      itemsArea: 'bg-green-100',
      addItemButton: 'bg-green-500 hover:bg-green-600',
      numberCircle: 'bg-green-500',
      inputFocus: 'focus:ring-green-500',
      numberInputButtons: 'bg-green-100 text-green-800 hover:bg-green-200',
      editButton: 'bg-green-100 text-green-500',
      dropdownFocus: 'focus:ring-green-500',
      dropdownOptionHover: 'bg-green-100',
      itemBackground: 'bg-white'
    },
    sunset: {
      name: 'Sunset',
      roomHeader: 'bg-orange-200',
      itemsArea: 'bg-orange-100',
      addItemButton: 'bg-orange-500 hover:bg-orange-600',
      numberCircle: 'bg-orange-500',
      inputFocus: 'focus:ring-orange-500',
      numberInputButtons: 'bg-orange-100 text-orange-800 hover:bg-orange-200',
      editButton: 'bg-orange-100 text-orange-500',
      dropdownFocus: 'focus:ring-orange-500',
      dropdownOptionHover: 'bg-orange-100',
      itemBackground: 'bg-white'
    },
    berry: {
      name: 'Berry',
      roomHeader: 'bg-pink-200',
      itemsArea: 'bg-pink-100',
      addItemButton: 'bg-pink-500 hover:bg-pink-600',
      numberCircle: 'bg-pink-500',
      inputFocus: 'focus:ring-pink-500',
      numberInputButtons: 'bg-pink-100 text-pink-800 hover:bg-pink-200',
      editButton: 'bg-pink-100 text-pink-500',
      dropdownFocus: 'focus:ring-pink-500',
      dropdownOptionHover: 'bg-pink-100',
      itemBackground: 'bg-white'
    },
    lavender: {
      name: 'Lavender',
      roomHeader: 'bg-purple-200',
      itemsArea: 'bg-purple-100',
      addItemButton: 'bg-purple-500 hover:bg-purple-600',
      numberCircle: 'bg-purple-500',
      inputFocus: 'focus:ring-purple-500',
      numberInputButtons: 'bg-purple-100 text-purple-800 hover:bg-purple-200',
      editButton: 'bg-purple-100 text-purple-500',
      dropdownFocus: 'focus:ring-purple-500',
      dropdownOptionHover: 'bg-purple-100',
      itemBackground: 'bg-white'
    },
    mint: {
      name: 'Mint',
      roomHeader: 'bg-emerald-200',
      itemsArea: 'bg-emerald-100',
      addItemButton: 'bg-emerald-500 hover:bg-emerald-600',
      numberCircle: 'bg-emerald-500',
      inputFocus: 'focus:ring-emerald-500',
      numberInputButtons: 'bg-emerald-100 text-emerald-800 hover:bg-emerald-200',
      editButton: 'bg-emerald-100 text-emerald-500',
      dropdownFocus: 'focus:ring-emerald-500',
      dropdownOptionHover: 'bg-emerald-100',
      itemBackground: 'bg-white'
    },
    coral: {
      name: 'Coral',
      roomHeader: 'bg-red-200',
      itemsArea: 'bg-red-100',
      addItemButton: 'bg-red-500 hover:bg-red-600',
      numberCircle: 'bg-red-500',
      inputFocus: 'focus:ring-red-500',
      numberInputButtons: 'bg-red-100 text-red-800 hover:bg-red-200',
      editButton: 'bg-red-100 text-red-500',
      dropdownFocus: 'focus:ring-red-500',
      dropdownOptionHover: 'bg-red-100',
      itemBackground: 'bg-white'
    },
    gold: {
      name: 'Gold',
      roomHeader: 'bg-yellow-200',
      itemsArea: 'bg-yellow-100',
      addItemButton: 'bg-yellow-500 hover:bg-yellow-600',
      numberCircle: 'bg-yellow-500',
      inputFocus: 'focus:ring-yellow-500',
      numberInputButtons: 'bg-yellow-100 text-yellow-800 hover:bg-yellow-200',
      editButton: 'bg-yellow-100 text-yellow-500',
      dropdownFocus: 'focus:ring-yellow-500',
      dropdownOptionHover: 'bg-yellow-100',
      itemBackground: 'bg-white'
    },
    slate: {
      name: 'Slate',
      roomHeader: 'bg-slate-200',
      itemsArea: 'bg-slate-100',
      addItemButton: 'bg-slate-500 hover:bg-slate-600',
      numberCircle: 'bg-slate-500',
      inputFocus: 'focus:ring-slate-500',
      numberInputButtons: 'bg-slate-100 text-slate-800 hover:bg-slate-200',
      editButton: 'bg-slate-100 text-slate-500',
      dropdownFocus: 'focus:ring-slate-500',
      dropdownOptionHover: 'bg-slate-100',
      itemBackground: 'bg-white'
    }
  };