import { openDB } from 'idb';

const CACHE_VERSION = 1;
const DB_NAME = 'quotationCache';

export const initCache = async () => {
  return openDB(DB_NAME, CACHE_VERSION, {
    upgrade(db) {
      db.createObjectStore('quotationState');
      db.createObjectStore('userSettings');
      db.createObjectStore('products');
    },
  });
};

export const getCachedData = async (storeName, key) => {
  try {
    const db = await initCache();
    return await db.get(storeName, key);
  } catch (error) {
    console.error('Error getting cached data:', error);
    return null;
  }
};

export const setCachedData = async (storeName, key, data) => {
  try {
    const db = await initCache();
    await db.put(storeName, data, key);
  } catch (error) {
    console.error('Error setting cached data:', error);
  }
};

export const clearCache = async () => {
  try {
    const db = await initCache();
    const stores = db.objectStoreNames;
    for (const store of stores) {
      await db.clear(store);
    }
  } catch (error) {
    console.error('Error clearing cache:', error);
  }
};
