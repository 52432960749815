import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { auth, db } from '../Services/firebase';
import { signInWithPopup, GoogleAuthProvider, signOut, sendPasswordResetEmail, updateProfile } from 'firebase/auth';
import { ChevronLeft, User, Bell, Palette, Lock, Globe, Database, LogOut, HelpCircle, FileText, Camera, Mail, Key, Moon, Sun, Volume2, Smartphone, Wifi, Home, UserCheck, Construction, Briefcase, Save, Trash2, Upload, CreditCard, Plus, Download, MapPin, Phone, Minus, ChevronUp, ChevronDown, List, Package, Box, Calendar, Check, Video, RefreshCw } from 'lucide-react';
import CustomRoomNames from './CustomRoomNames';
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import LegalAndAbout from './LegalAndAbout';
import { useFirebaseService } from '../hooks/useFirebaseService';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import PhonePePayment from './PhonePePayment';
import { themeColors } from '../utils/themeColors';
import { ThemeContext } from '../contexts/ThemeContext';
import { defaultRoomNames } from './CustomRoomNames';
import { defaultTemplateDetails } from '../utils/defaultTemplateDetails';
import { defaultItems } from '../defaultItems'; // Make sure this import is correct
import { loadDefaultItems } from '../utils/defaultItemLoader';
import { AlertTriangle } from 'lucide-react';
import { useSwipeable } from 'react-swipeable';
import imageCompression from 'browser-image-compression';
import { openDB } from 'idb';
import { format } from 'date-fns'; // Make sure to install this package
import { motion } from 'framer-motion';
import { getAppVersion } from '../utils/version'; 

// Define AutoResizeTextarea component within the same file
const AutoResizeTextarea = ({ value, onChange, placeholder, className }) => {
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '20px'; // Set an initial height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      ref={textAreaRef}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{ resize: 'none', overflow: 'hidden' }}
      className={`${className} py-1`} // Reduce vertical padding
    />
  );
};

const ThemeOption = ({ theme, isSelected, onSelect }) => {
  const themeColor = themeColors[theme];
  
  return (
    <button
      onClick={() => onSelect(theme)}
      className={`w-full p-4 rounded-lg flex items-center justify-between transition-all duration-300 ${
        isSelected ? `${themeColor.roomHeader} border-2 border-${theme}-500` : 'bg-white hover:bg-gray-50'
      }`}
    >
      <div className="flex items-center">
        <div className={`w-8 h-8 rounded-full ${themeColor.numberCircle} mr-3`}></div>
        <span className="font-medium">{themeColor.name}</span>
      </div>
      {isSelected && <Check className={`text-${theme}-500`} size={20} />}
    </button>
  );
};

const Cube = () => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setKey(prevKey => prevKey + 1);
    }, 4000); // Reset animation every 4 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      key={key}
      className="w-6 h-6"
      style={{
        transformStyle: 'preserve-3d',
        perspective: '1000px',
      }}
      animate={{
        y: [0, -10, 0], // Bouncing effect
        rotateY: [0, 360], // Rotation after bouncing
        rotateX: [0, 360], // Rotation after bouncing
      }}
      transition={{
        duration: 4,
        times: [0, 0.2, 0.4, 1],
        ease: ["easeOut", "easeIn", "easeInOut"],
      }}
    >
      {/* Front face */}
      <motion.div className="absolute inset-0 border-2 border-current" style={{ transform: 'translateZ(12px)' }} />
      {/* Back face */}
      <motion.div className="absolute inset-0 border-2 border-current" style={{ transform: 'rotateY(180deg) translateZ(12px)' }} />
      {/* Right face */}
      <motion.div className="absolute inset-0 border-2 border-current" style={{ transform: 'rotateY(90deg) translateZ(12px)' }} />
      {/* Left face */}
      <motion.div className="absolute inset-0 border-2 border-current" style={{ transform: 'rotateY(-90deg) translateZ(12px)' }} />
      {/* Top face */}
      <motion.div className="absolute inset-0 border-2 border-current" style={{ transform: 'rotateX(90deg) translateZ(12px)' }} />
      {/* Bottom face */}
      <motion.div className="absolute inset-0 border-2 border-current" style={{ transform: 'rotateX(-90deg) translateZ(12px)' }} />
    </motion.div>
  );
};

const SettingsScreen = ({ waitingWorker, onUpdate }) => {
  const { currentTheme, setCurrentTheme } = useContext(ThemeContext);
  const [activeSection, setActiveSection] = useState(null);
  const [theme, setTheme] = useState('light');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [user, setUser] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const { updateUserSettings } = useFirebaseService();
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [notes, setNotes] = useState([]);
  const textAreaRefs = useRef({});
  const [expandedSection, setExpandedSection] = useState(null);
  const [customSections, setCustomSections] = useState([]);

  const { 
    getUserSettings, 
    updateUserSettings: firebaseUpdateUserSettings, 
    addProduct,
    getProducts,
    loadDefaultTemplateDetails,
    deleteUserAccount
  } = useFirebaseService();
  const { user: authUser } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Loading your settings');
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showRefundPolicy, setShowRefundPolicy] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [nextBillingDate, setNextBillingDate] = useState(null);
  const [currentVersion, setCurrentVersion] = useState('');

  // Move these utility functions inside the component
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-IN', { 
      day: '2-digit', 
      month: 'short', 
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const calculateNextBillingDate = (nextBillingDateTimestamp) => {
    if (nextBillingDateTimestamp) {
      const nextBillingDate = nextBillingDateTimestamp.toDate();
      return formatDate(nextBillingDate);
    }
    return 'Not set';
  };

  const loadUserSettings = useCallback(async () => {
    if (user) {
      try {
        const settings = await getUserSettings(user.uid);
        if (settings) {
          setCompanyName(settings.companyName || '');
          setCompanyAddress(settings.companyAddress || '');
          setMobileNumber(settings.mobileNumber || '');
          setLogoPreview(settings.logo || null);
          setPaymentTerms(settings.paymentTerms || []);
          setMaterials(settings.materialSpecs || []);
          setNotes(settings.notes || []);
        }
      } catch (error) {
        console.error('Error loading user settings:', error);
      }
    }
  }, [user, getUserSettings]);

  useEffect(() => {
    loadUserSettings();
  }, [loadUserSettings]);

  useEffect(() => {
    if (shouldRefresh) {
      loadUserSettings();
      setShouldRefresh(false);
    }
  }, [shouldRefresh, loadUserSettings]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
        console.warn('Loading timed out. Please try refreshing the page.');
        // Show an error message to the user
      }
    }, 60000); // 60 seconds timeout

    return () => clearTimeout(timer);
  }, [isLoading]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(prev => !prev);
    }, 2000); // Toggle animation every 2 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchNextBillingDate = async () => {
      if (user) {
        console.log("Fetching billing date for user:", user.email);
        const allowedUserRef = doc(db, "allowedUsers", user.email);
        const allowedUserSnap = await getDoc(allowedUserRef);
        if (allowedUserSnap.exists()) {
          const userData = allowedUserSnap.data();
          console.log("User data:", userData);
          if (userData.nextBillingDate) {
            console.log("Next billing date:", userData.nextBillingDate.toDate());
            setNextBillingDate(userData.nextBillingDate.toDate());
          } else {
            console.log("Next billing date not found in user data");
          }
        } else {
          console.log("User document not found in allowedUsers");
        }
      }
    };

    fetchNextBillingDate();
  }, [user]);

  const handleTextAreaResize = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight + 2}px`; // Add a small buffer
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        setAuthError(null); // Clear any previous errors

        // Fetch company details from the database
        const userDetails = await fetchUserDetails(user.uid);
        if (userDetails) {
          setCompanyName(userDetails.companyName || '');
          setCompanyAddress(userDetails.companyAddress || '');
          setMobileNumber(userDetails.mobileNumber || '');
          setLogoPreview(userDetails.logo || null);
          setPaymentTerms(userDetails.paymentTerms || defaultTemplateDetails.paymentTerms);
          setMaterials(userDetails.materialSpecs || defaultTemplateDetails.materialSpecs);
          setNotes(userDetails.notes || defaultTemplateDetails.notes);
          setCustomSections(userDetails.customSections || []);
        }
      } else {
        setUser(null);
        setCustomSections([]); // Reset custom sections when user logs out
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserDetails = async (userId) => {
    try {
      const userDetails = await getUserSettings(userId);
      if (userDetails) {
        setCompanyName(userDetails.companyName || '');
        setCompanyAddress(userDetails.companyAddress || '');
        setMobileNumber(userDetails.mobileNumber || '');
        setLogoPreview(userDetails.logo || null);
        if (userDetails.logo) {
          await saveLogo(userDetails.logo);
        }
        setPaymentTerms(userDetails.paymentTerms || defaultTemplateDetails.paymentTerms);
        setMaterials(userDetails.materialSpecs || defaultTemplateDetails.materialSpecs);
        setNotes(userDetails.notes || defaultTemplateDetails.notes);
        setCustomSections(userDetails.customSections || []);
      }
      return userDetails;
    } catch (error) {
      console.error('Error fetching user details:', error);
      return null;
    }
  };

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error signing in:', error);
      setAuthError(error.message); // Set error message for display
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
      setAuthError(error.message); // Set error message for display
    }
  };

  const settingsSections = [
    { id: 'billing', icon: CreditCard, label: 'Billing & Payments', color: 'bg-blue-500' },
    { id: 'profile', icon: User, label: 'Profile', color: 'bg-green-500' },
    { id: '3dDesign', icon: Box, label: '3D Design', color: 'bg-orange-500' },
    { id: 'roomNames', icon: Home, label: 'Room Names', color: 'bg-yellow-500' },
    { id: 'defaultTemplate', icon: FileText, label: 'Default Template', color: 'bg-indigo-500' },
    { id: 'theme', icon: Palette, label: 'Theme & Display', color: 'bg-pink-500' },
    { id: 'privacy', icon: Lock, label: 'Privacy & Security', color: 'bg-red-500' },
    { id: 'support', icon: HelpCircle, label: 'Support & Contact', color: 'bg-purple-600' },
    { id: 'legal', icon: Construction, label: 'Legal & About', color: 'bg-gray-500' },
    { id: 'appDemo', icon: Video, label: 'App Demo & Guide', color: 'bg-emerald-500' },
  ];

  useEffect(() => {
    if (isTransitioning) {
      const timer = setTimeout(() => setIsTransitioning(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);

  const handleSectionChange = (sectionId) => {
    setIsTransitioning(true);
    setActiveSection(sectionId);
  };

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const handleSectionNameChange = (setter, newName) => {
    setter(prevState => ({ ...prevState, name: newName }));
  };

  const handleCustomSectionNameChange = (index, newName) => {
    setCustomSections(prev => {
      const newSections = [...prev];
      newSections[index].name = newName;
      return newSections;
    });
  };

  const handleCustomSectionItemChange = (sectionIndex, itemIndex, value) => {
    setCustomSections(prev => {
      const newSections = [...prev];
      newSections[sectionIndex].items[itemIndex] = value;
      return newSections;
    });
  };

  const handleAddCustomSectionItem = (sectionIndex) => {
    setCustomSections(prev => {
      const newSections = [...prev];
      newSections[sectionIndex].items.push('');
      return newSections;
    });
  };

  const handleRemoveCustomSectionItem = (sectionIndex, itemIndex) => {
    setCustomSections(prev => {
      const newSections = [...prev];
      newSections[sectionIndex].items = newSections[sectionIndex].items.filter((_, i) => i !== itemIndex);
      return newSections;
    });
  };

  const handleAddCustomSection = () => {
    setCustomSections(prev => [...prev, { name: 'New Section', items: [''] }]);
  };

  const handleDeleteCustomSection = (index) => {
    setCustomSections(prev => prev.filter((_, i) => i !== index));
  };

  const handleThemeChange = (theme) => {
    setCurrentTheme(theme);
    localStorage.setItem('selectedTheme', theme);
  };

  const renderThemeOptions = () => (
    <div className="space-y-2">
      {Object.keys(themeColors).map((theme) => (
        <ThemeOption
          key={theme}
          theme={theme}
          isSelected={currentTheme === theme}
          onSelect={handleThemeChange}
        />
      ))}
    </div>
  );

  const renderSettingsContent = () => {
    switch (activeSection) {
      case 'billing':
        return (
          <div className="space-y-4 animate-fade-in p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Current Plan</h2>
            
            {/* Current Plan - Enhanced UI Design */}
            <div className="bg-gradient-to-br from-blue-100 to-gray-100 p-6 rounded-xl shadow-lg border border-blue-200">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center">
                  <CreditCard className="w-8 h-8 mr-3 text-blue-500" />
                  <div>
                    <p className="text-2xl font-bold text-gray-800">Pro Plan</p>
                    <div className="flex items-center gap-3 mb-1">
                      <span className="text-xl text-gray-600 relative">
                        ₹500
                        <span className="absolute inset-0 transform rotate-[-8deg] border-t-2 border-red-500/90" style={{ top: '50%' }}></span>
                      </span>
                      <span className="bg-red-500 text-white text-xs px-2 py-0.5 rounded-full">
                        -60% OFF
                      </span>
                    </div>
                    <div className="flex items-baseline gap-1">
                      <span className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                        ₹199
                      </span>
                      <span className="text-sm text-gray-600 font-medium">/ month</span>
                    </div>
                    <p className="text-sm text-green-600 font-medium mt-1">Save ₹301 on subscription!</p>
                  </div>
                </div>
                <div className="bg-green-500 text-white px-3 py-1 rounded-full flex items-center">
                  <Check className="w-4 h-4 mr-1" />
                  <span className="font-semibold text-sm">Active</span>
                </div>
              </div>
              <div className="flex items-center text-sm text-gray-600">
                <Calendar className="w-5 h-5 mr-2 text-blue-500" />
                <span>
                  Next billing date: {
                    nextBillingDate 
                      ? format(nextBillingDate, 'dd MMM yyyy, hh:mm a')
                      : 'Not set'
                  }
                </span>
              </div>
            </div>

            {/* Refund Policy */}
            <div className="mt-6">
              <button
                onClick={() => setShowRefundPolicy(!showRefundPolicy)}
                className="text-sm text-blue-600 hover:text-blue-800 focus:outline-none flex items-center"
              >
                <FileText className="w-4 h-4 mr-2" />
                View Refund Policy
              </button>
              {showRefundPolicy && (
                <div className="mt-3 p-4 bg-gray-50 rounded-lg text-sm text-gray-700 border border-gray-200">
                  <p className="mb-2">
                    At Design Que, we are committed to providing high-quality services. Please note our no-refund policy:
                  </p>
                  <ul className="list-disc list-inside mb-2">
                    <li>All purchases are final and non-refundable.</li>
                    <li>Subscription fees are charged in advance and are non-refundable.</li>
                    <li>We do not prorate or offer partial refunds for unused time on active subscriptions.</li>
                    <li>Cancellations will take effect at the end of the current billing cycle.</li>
                  </ul>
                  <p className="mb-2">
                    We encourage users to carefully review our services before making a purchase. If you have any questions about our offerings, please contact us before subscribing.
                  </p>
                  <p className="mb-2">
                    While we don't offer refunds, we are committed to resolving any technical issues you may encounter while using our service. Our support team is available to assist you with any problems or concerns.
                  </p>
                  <p>
                    For any questions about this policy or our services, please contact our support team at <a href="mailto:support@designque.in" className="text-blue-600 hover:underline">support@designque.in</a>.
                  </p>
                </div>
              )}
            </div>

            {/* Subscription Management */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-gray-700 mb-2">Subscription Management</h3>
              <p className="text-gray-600 mb-2">Please contact Design Que to manage your subscription.</p>
              <div className="space-y-2">
                <a 
                  href="mailto:support@designque.in" 
                  className="inline-block text-blue-600 hover:text-blue-800"
                >
                  Contact Support
                </a>
                <a 
                  href="https://wa.me/919966922000" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="flex items-center text-green-600 hover:text-green-800"
                >
                  <FaWhatsapp className="w-5 h-5 mr-2" />
                  <span>+919966922000</span>
                </a>
              </div>
            </div>
          </div>
        );
      case 'profile':
        return (
          <div className="space-y-6 animate-fade-in p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-gray-800">Profile Settings</h2>
            <input className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder="Username" />
            <input className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder="Email" type="email" />
            <input className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" type="password" placeholder="New Password" />
            <input className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder="Phone Number" type="tel" />
            <button className="w-full p-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition duration-200">Update Profile</button>

            <div className="mt-6">
              <h3 className="text-lg font-semibold text-gray-700">Account Management</h3>
              {user && (
                <p className="text-sm mb-2">Signed in as: <strong>{user.email}</strong></p>
              )}
              <button className="w-full p-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-200" onClick={handleSignOut}>
                Sign Out
              </button>
              <div className="mt-2" />
              <button className="w-full p-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition duration-200" onClick={handleLostPassword}>
                Reset Lost Password
              </button>
              <div className="mt-2" />
              <button className="w-full p-3 bg-teal-500 text-white rounded-lg hover:bg-teal-600 transition duration-200" onClick={handleChangePhoneNumber}>
                Change Phone Number
              </button>
              <div className="mt-2" />
              <button 
                className="w-full p-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-200"
                onClick={() => setShowDeleteConfirmation(true)}
              >
                Delete Account
              </button>
            </div>

            {/* Delete Account Confirmation Modal */}
            {showDeleteConfirmation && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
                  <div className="flex items-center mb-4">
                    <AlertTriangle size={24} className="text-red-500 mr-2" />
                    <h2 className="text-xl font-bold">Confirm Account Deletion</h2>
                  </div>
                  <p className="mb-4 text-gray-600">
                    Are you sure you want to delete your account? This action cannot be undone.
                  </p>
                  <div className="flex justify-end space-x-2">
                    <button 
                      onClick={() => setShowDeleteConfirmation(false)}
                      className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition duration-200"
                    >
                      Cancel
                    </button>
                    <button 
                      onClick={handleDeleteAccount}
                      className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200"
                    >
                      Delete Account
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      case '3dDesign':
        return (
          <div className="animate-fade-in">
            <h2 className="text-2xl font-bold mb-4">3D Design Service</h2>
            <p className="text-lg mb-4">3D design services start from ₹ 18,000</p>
            <div className="flex justify-center mb-4">
              <div className="w-64 h-[512px]"> {/* Adjust width and height as needed */}
                <iframe
                  src="https://www.youtube.com/embed/S8bMTTaw9PY"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full"
                ></iframe>
              </div>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg">
              <h3 className="text-xl font-semibold mb-2">Contact Us Now</h3>
              <div className="flex flex-col space-y-2">
                <a 
                  href="mailto:info@designque.in" 
                  className="flex items-center space-x-2 text-blue-600 hover:text-blue-800"
                >
                  <FaEnvelope className="h-5 w-5" />
                  <span>info@designque.in</span>
                </a>
                <a 
                  href="https://wa.me/919966922000" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="flex items-center space-x-2 text-green-600 hover:text-green-800"
                >
                  <FaWhatsapp className="h-5 w-5" />
                  <span>+919966922000</span>
                </a>
              </div>
            </div>
          </div>
        );
      case 'theme':
        return (
          <div className="space-y-4 animate-fade-in">
            <h2 className="text-xl font-semibold mb-4">Theme & Display</h2>
            {renderThemeOptions()}
          </div>
        );
      case 'privacy':
        return (
          <div className="space-y-4 animate-fade-in p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-gray-800">Privacy & Security</h2>
            <p className="text-gray-600">
              At Design Que, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data.
            </p>
            <h3 className="text-lg font-semibold text-gray-700">Data Collection</h3>
            <p className="text-gray-600">
              We collect personal information that you provide to us when you register for an account, use our services, or communicate with us. This may include your name, email address, phone number, and payment information.
            </p>
            <h3 className="text-lg font-semibold text-gray-700">Data Usage</h3>
            <p className="text-gray-600">
              Your data is used to provide and improve our services, process transactions, and communicate with you about your account and our services. We may also use your information to send you promotional materials, but you can opt-out at any time.
            </p>
            <h3 className="text-lg font-semibold text-gray-700">Data Security</h3>
            <p className="text-gray-600">
              We implement a variety of security measures to maintain the safety of your personal information. Your data is stored on secure servers, and we use encryption to protect sensitive information during transmission.
            </p>
            <h3 className="text-lg font-semibold text-gray-700">Your Rights</h3>
            <p className="text-gray-600">
              You have the right to access, correct, or delete your personal information at any time. If you have any questions or concerns about your data, please contact our support team.
            </p>
            <h3 className="text-lg font-semibold text-gray-700">Changes to This Policy</h3>
            <p className="text-gray-600">
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page. Please review this policy periodically for any updates.
            </p>
            <p className="text-gray-600">
              For more information, please contact us at <a href="mailto:support@designque.in" className="text-blue-600 hover:underline">support@designque.in</a>.
            </p>
          </div>
        );
      case 'roomNames':
        return (
          <div className="space-y-4 animate-fade-in">
            <CustomRoomNames />
          </div>
        );
      case 'support':
        return (
          <div className="space-y-4 animate-fade-in">
            <h2 className="text-lg font-semibold">Support & Contact</h2>
            <div className="flex flex-col space-y-4">
              <a 
                href="mailto:info@designque.in" 
                className="flex items-center space-x-2 text-blue-600 hover:text-blue-800"
              >
                <FaEnvelope className="h-5 w-5" />
                <span>Email us at info@designque.in</span>
              </a>
              <a 
                href="https://wa.me/919966922000" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="flex items-center space-x-2 text-green-600 hover:text-green-800"
              >
                <FaWhatsapp className="h-5 w-5" />
                <span>WhatsApp: +919966922000</span>
              </a>
            </div>
          </div>
        );
      case 'legal':
        return <LegalAndAbout />;
      case 'defaultTemplate':
        return (
          <div className="w-full max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-6 text-gray-800">Default Template</h2>
            <div className="space-y-6">
              <div className="flex">
                <div className="w-2/3 pr-2">
                  <input 
                    className="w-full p-2 mb-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-xs font-bold" 
                    placeholder="Company Name" 
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <div className="relative">
                    <MapPin size={16} className="absolute top-2 left-3 text-gray-500" />
                    <textarea 
                      className="w-full p-2 mb-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-xs pl-8" 
                      placeholder="Company Address" 
                      rows="3"
                      value={companyAddress}
                      onChange={(e) => setCompanyAddress(e.target.value)}
                      style={{ resize: 'none', height: '80px', fontSize: '0.75rem' }}
                    />
                  </div>
                  <div className="relative">
                    <Phone size={16} className="absolute top-2 left-3 text-gray-500" />
                    <input 
                      className="w-full p-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm pl-8" 
                      placeholder="Mobile Number" 
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-1/3 pl-2">
                  {logoPreview && (
                    <div className="relative w-full aspect-square mb-3 flex items-center justify-center rounded-lg">
                      <img 
                        src={logoPreview} 
                        alt="Company Logo" 
                        className="w-full h-auto object-contain rounded-lg" 
                        style={{ maxWidth: '200px', maxHeight: '200px' }}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Payment Terms */}
              <div>
                <div 
                  className="flex items-center justify-between mb-2 cursor-pointer"
                  onClick={() => toggleSection('paymentTerms')}
                >
                  <div className="flex items-center">
                    <CreditCard size={20} className="mr-2 text-blue-500" />
                    <h3 className="text-lg font-semibold">Payment Terms</h3>
                  </div>
                  {expandedSection === 'paymentTerms' ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                </div>
                {expandedSection === 'paymentTerms' && (
                  <div className="space-y-2">
                    {paymentTerms.map((term, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <div className="relative w-24">
                          <input
                            type="number"
                            value={term.percentage}
                            onChange={(e) => handleObjectArrayChange(setPaymentTerms, index, 'percentage', e.target.value)}
                            className="w-full p-1 pr-6 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm h-6"
                            placeholder="0"
                          />
                          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 text-sm">%</span>
                        </div>
                        <AutoResizeTextarea
                          value={term.description}
                          onChange={(e) => handleObjectArrayChange(setPaymentTerms, index, 'description', e.target.value)}
                          placeholder="Payment term description"
                          className="flex-grow p-1 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm h-6"
                        />
                        <button
                          onClick={() => handleRemoveItem(setPaymentTerms, index)}
                          className="text-red-500 hover:bg-red-100 p-1 rounded-full transition-colors duration-200"
                        >
                          <Trash2 size={16} />
                        </button>
                      </div>
                    ))}
                    <button
                      className="mt-2 flex items-center justify-center w-6 h-6 rounded-full bg-blue-500 text-white hover:bg-blue-600"
                      onClick={() => handleAddObjectItem(setPaymentTerms, { percentage: '', description: '' })}
                    >
                      <Plus size={16} />
                    </button>
                  </div>
                )}
              </div>

              {/* Material Specification */}
              <div>
                <div 
                  className="flex items-center justify-between mb-2 cursor-pointer"
                  onClick={() => toggleSection('materialSpecs')}
                >
                  <div className="flex items-center">
                    <Package size={20} className="mr-2 text-green-500" />
                    <h3 className="text-lg font-semibold">Material Specification</h3>
                  </div>
                  {expandedSection === 'materialSpecs' ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                </div>
                {expandedSection === 'materialSpecs' && (
                  <div className="space-y-2">
                    {materials.map((spec, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <AutoResizeTextarea
                          value={spec.name}
                          onChange={(e) => handleObjectArrayChange(setMaterials, index, 'name', e.target.value)}
                          placeholder="Material name"
                          className="w-1/3 p-1 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm h-6"
                        />
                        <AutoResizeTextarea
                          value={spec.description}
                          onChange={(e) => handleObjectArrayChange(setMaterials, index, 'description', e.target.value)}
                          placeholder="Material description"
                          className="flex-grow p-1 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm h-6"
                        />
                        <button
                          onClick={() => handleRemoveItem(setMaterials, index)}
                          className="text-red-500 hover:bg-red-100 p-1 rounded-full transition-colors duration-200"
                        >
                          <Trash2 size={16} />
                        </button>
                      </div>
                    ))}
                    <button
                      className="mt-2 flex items-center justify-center w-6 h-6 rounded-full bg-green-500 text-white hover:bg-green-600"
                      onClick={() => handleAddObjectItem(setMaterials, { name: '', description: '' })}
                    >
                      <Plus size={16} />
                    </button>
                  </div>
                )}
              </div>

              {/* Notes */}
              <div>
                <div 
                  className="flex items-center justify-between mb-2 cursor-pointer"
                  onClick={() => toggleSection('notes')}
                >
                  <div className="flex items-center">
                    <FileText size={20} className="mr-2 text-yellow-500" />
                    <h3 className="text-lg font-semibold">Notes</h3>
                  </div>
                  {expandedSection === 'notes' ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                </div>
                {expandedSection === 'notes' && (
                  <div className="space-y-2">
                    {notes.map((note, index) => (
                      <div key={index} className="flex items-center">
                        <AutoResizeTextarea
                          value={note}
                          onChange={(e) => handleArrayChange(setNotes, index, e.target.value)}
                          placeholder="Enter note"
                          className="flex-grow p-1 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm h-6"
                        />
                        <button
                          onClick={() => handleRemoveItem(setNotes, index)}
                          className="ml-2 text-red-500 hover:bg-red-100 p-1 rounded-full transition-colors duration-200"
                        >
                          <Trash2 size={16} />
                        </button>
                      </div>
                    ))}
                    <button
                      className="mt-2 flex items-center justify-center w-6 h-6 rounded-full bg-yellow-500 text-white hover:bg-yellow-600"
                      onClick={() => handleAddItem(setNotes)}
                    >
                      <Plus size={16} />
                    </button>
                  </div>
                )}
              </div>

              <button 
                className="w-full bg-indigo-500 text-white px-4 py-3 rounded-md hover:bg-indigo-600 transition-colors duration-200 flex items-center justify-center"
                onClick={saveCompanyDetails}
              >
                <Save size={20} className="mr-2" /> Save Default Template
              </button>
            </div>
          </div>
        );
      case 'appDemo':
        return (
          <div className="space-y-5 animate-fade-in">
            <h2 className="text-lg font-semibold text-gray-800 text-center mb-4">
              Quick Start App Demo & Guide
            </h2>
            
            {/* Tutorial 1 */}
            <div className="relative w-10/12 sm:w-3/5 mx-auto group">
              <div className="rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
                <div className="relative bg-gradient-to-r from-teal-50 via-emerald-50 to-teal-50 rounded-lg overflow-hidden" style={{ aspectRatio: '16/7' }}>
                  {/* Animated Wave Pattern Background */}
                  <div className="absolute inset-0 opacity-20">
                    <svg className="w-[200%] h-full animate-wave" viewBox="0 0 200 100" preserveAspectRatio="none">
                      <pattern id="waves" width="40" height="15" patternUnits="userSpaceOnUse">
                        <path d="M0 7.5 Q10 0, 20 7.5 Q30 15, 40 7.5" 
                              fill="none" 
                              stroke="currentColor" 
                              strokeWidth="0.8" 
                              className="text-emerald-400"/>
                      </pattern>
                      <rect width="200" height="100" fill="url(#waves)"/>
                    </svg>
                  </div>

                  {/* Tutorial Number */}
                  <div className="absolute left-4 top-4 text-emerald-600 text-sm font-medium">
                    01
                  </div>

                  {/* Play Button Link */}
                  <a 
                    href="https://youtube.com/shorts/8wj0ISfBTTE?si=5OVoMVhds-t-U7jC"
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="absolute inset-0 flex items-center justify-center"
                  >
                    <div className="w-14 h-14 rounded-full border-2 border-emerald-200 bg-white/90 flex items-center justify-center group-hover:border-emerald-300 group-hover:bg-white transition-all duration-300 shadow-sm">
                      <svg className="w-7 h-7 text-emerald-600 group-hover:text-emerald-700 transition-colors duration-300 translate-x-0.5" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M6.92 5.51L18.9 12l-11.98 6.49V5.51z"/>
                      </svg>
                    </div>
                  </a>
                </div>
                
                {/* Title Below Video with white background and smooth corners */}
                <div className="py-2 px-3 text-center bg-white rounded-lg">
                  <p className="text-gray-800 text-sm">
                    App installation tutorial for iPhone, Android, Windows
                  </p>
                </div>
              </div>
            </div>

            {/* Tutorial 2 */}
            <div className="relative w-10/12 sm:w-3/5 mx-auto group">
              <div className="rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
                <div className="relative bg-gradient-to-r from-teal-50 via-emerald-50 to-teal-50 rounded-lg overflow-hidden" style={{ aspectRatio: '16/7' }}>
                  {/* Animated Wave Pattern Background */}
                  <div className="absolute inset-0 opacity-20">
                    <svg className="w-[200%] h-full animate-wave" viewBox="0 0 200 100" preserveAspectRatio="none">
                      <pattern id="waves2" width="40" height="15" patternUnits="userSpaceOnUse">
                        <path d="M0 7.5 Q10 0, 20 7.5 Q30 15, 40 7.5" 
                              fill="none" 
                              stroke="currentColor" 
                              strokeWidth="0.8" 
                              className="text-emerald-400"/>
                      </pattern>
                      <rect width="200" height="100" fill="url(#waves2)"/>
                    </svg>
                  </div>

                  {/* Tutorial Number */}
                  <div className="absolute left-4 top-4 text-emerald-600 text-sm font-medium">
                    02
                  </div>

                  {/* Play Button Link */}
                  <a 
                    href="https://youtu.be/yWC31ZkR5as"
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="absolute inset-0 flex items-center justify-center"
                  >
                    <div className="w-14 h-14 rounded-full border-2 border-emerald-200 bg-white/90 flex items-center justify-center group-hover:border-emerald-300 group-hover:bg-white transition-all duration-300 shadow-sm">
                      <svg className="w-7 h-7 text-emerald-600 group-hover:text-emerald-700 transition-colors duration-300 translate-x-0.5" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M6.92 5.51L18.9 12l-11.98 6.49V5.51z"/>
                      </svg>
                    </div>
                  </a>
                </div>
                
                {/* Title Below Video with white background and smooth corners */}
                <div className="py-2 px-3 text-center bg-white rounded-lg">
                  <p className="text-gray-800 text-sm">
                    App Demo
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="animate-fade-in">
            <p className="text-gray-500">Select a setting to view options.</p>
          </div>
        );
    }
  };

  const handleLostPassword = async () => {
    const email = prompt("Please enter your email address to reset your password:");
    if (email) {
      try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset email sent. Please check your inbox.");
      } catch (error) {
        console.error("Error sending password reset email:", error);
        alert("Failed to send password reset email. Please try again.");
      }
    }
  };

  const updatePhoneNumber = async (userId, newPhoneNumber) => {
    try {
      const user = auth.currentUser;
      await updateProfile(user, { phoneNumber: newPhoneNumber });
      alert("Phone number updated successfully.");
    } catch (error) {
      console.error("Error updating phone number:", error);
      alert("Failed to update phone number. Please try again.");
    }
  };

  const handleChangePhoneNumber = async () => {
    const newPhoneNumber = prompt("Please enter your new phone number:");
    if (newPhoneNumber) {
      await updatePhoneNumber(user.uid, newPhoneNumber);
    }
  };

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const compressedFile = await compressImage(file);
        
        if (compressedFile.size > 800 * 1024) {
          throw new Error("Image is too large. Please choose a smaller image.");
        }

        const reader = new FileReader();
        reader.onloadend = async (e) => {
          const logoData = e.target.result;
          setLogoPreview(logoData);
          await saveLogo(logoData);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error processing logo:", error);
        alert(error.message || "Error processing logo. Please try again with a smaller image.");
      }
    }
  };

  const deleteLogo = () => {
    setLogoPreview(null);
  };

  const saveCompanyDetails = async () => {
    if (authUser) {
      try {
        const detailsToSave = {
          companyName: companyName || '',
          companyAddress: companyAddress || '',
          mobileNumber: mobileNumber || '',
          logo: logoPreview,
          paymentTerms: paymentTerms,
          materialSpecs: materials,
          notes: notes,
          isFirstTimeUser: false
        };

        await firebaseUpdateUserSettings(authUser.uid, detailsToSave);
        console.log('Company details saved successfully');
        alert('Company details saved successfully!');
      } catch (error) {
        console.error('Error saving company details:', error);
        alert(`Failed to save company details. Error: ${error.message}`);
      }
    } else {
      console.error('No user found when trying to save company details');
      alert('No user found. Please ensure you are logged in.');
    }
  };

  const handlePayment = (method) => {
    console.log(`Processing payment with ${method}`);
    setShowPaymentOptions(false);
  };

  const handleDeleteAccount = async () => {
    if (user) {
      try {
        const success = await deleteUserAccount(user);
        if (success) {
          alert('Your account has been successfully deleted.');
          // Redirect to login or home page
          // You might need to implement this navigation logic
        } else {
          alert('Failed to delete account. Please try again.');
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        alert('An error occurred while deleting your account. Please try again.');
      }
    }
  };

  const handleObjectArrayChange = (setter, index, field, value) => {
    setter(prev => {
      const newArray = [...prev];
      newArray[index][field] = value;
      return newArray;
    });
  };

  const handleArrayChange = (setter, index, value) => {
    setter(prev => {
      const newArray = [...prev];
      newArray[index] = value;
      return newArray;
    });
  };

  const handleAddObjectItem = (setter, template) => {
    setter(prev => [...prev, template]);
  };

  const handleAddItem = (setter) => {
    setter(prev => [...prev, '']);
  };

  const handleRemoveItem = (setter, index) => {
    setter(prev => prev.filter((_, i) => i !== index));
  };

  const renderIcon = (Icon) => <Icon size={28} />;

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1024,
      useWebWorker: true
    };
    
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
      throw error;
    }
  };

  const saveLogo = async (logoData) => {
    const db = await initDB();
    await db.put('logos', logoData, 'currentLogo');
  };

  const initDB = async () => {
    return openDB('QuotationApp', 1, {
      upgrade(db) {
        db.createObjectStore('logos');
      },
    });
  };

  useEffect(() => {
    const handleNavigateToSection = (event) => {
      if (event.detail.section) {
        handleSectionChange(event.detail.section);
      }
    };

    document.querySelector('.settings-screen')?.addEventListener('navigateToSection', handleNavigateToSection);

    return () => {
      document.querySelector('.settings-screen')?.removeEventListener('navigateToSection', handleNavigateToSection);
    };
  }, []);

  useEffect(() => {
    const checkVersion = async () => {
      const version = await getAppVersion();
      setCurrentVersion(version);
    };
    
    checkVersion();
    const interval = setInterval(checkVersion, 60000);
    
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="settings-screen pb-20">
      <header 
        className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} shadow-sm p-4 flex items-center justify-between transition-all duration-300 ease-in-out mb-4`}
      >
        <div className="flex items-center">
          {activeSection && (
            <button 
              onClick={() => handleSectionChange(null)}
              className="mr-2 transition-opacity duration-300 ease-in-out"
            >
              <ChevronLeft className="h-6 w-6" />
            </button>
          )}
          <h1 className="text-xl font-semibold">
            {activeSection ? settingsSections.find(s => s.id === activeSection)?.label : 'Settings'}
          </h1>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium text-gray-500 bg-gray-100 px-2 py-1 rounded-full">
            v1.3
          </span>
          {waitingWorker && (
            <button
              onClick={onUpdate}
              className="flex items-center space-x-1 text-sm font-medium text-white bg-blue-500 px-2 py-1 rounded-full hover:bg-blue-600 transition-colors duration-200"
            >
              <RefreshCw className="h-3.5 w-3.5" />
              <span>Update</span>
            </button>
          )}
        </div>
      </header>

      <main className="p-4">
        {authError && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{authError}</span>
          </div>
        )}

        {activeSection === 'billing' && user && (
          <div className="mb-4">
            <p className="text-sm mb-2">Signed in as: <strong>{user.email}</strong></p>
            <button onClick={handleSignOut} className="w-full p-2 bg-red-500 text-white rounded-md">Sign Out</button>
          </div>
        )}

        {activeSection ? (
          <div className={`transition-opacity duration-300 ease-in-out ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}>
            {renderSettingsContent()}
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-4">
            {settingsSections.map((section) => (
              <motion.button
                key={section.id}
                onClick={() => handleSectionChange(section.id)}
                className={`flex flex-col items-center justify-center p-4 rounded-lg shadow-sm ${section.color} text-white h-28`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {section.id === '3dDesign' ? (
                  <Cube />
                ) : (
                  renderIcon(section.icon)
                )}
                <span className="mt-2 text-sm font-medium text-center">
                  {section.label}
                </span>
              </motion.button>
            ))}
          </div>
        )}
      </main>
    </div>
  );
};


export default SettingsScreen;