import React, { useState, useEffect, useCallback } from 'react';
import { onAuthStateChanged, GoogleAuthProvider, signInWithCredential } from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from './Services/firebase';
import SignUp from './components/SignUp';
import Tabs from './components/Tabs';
import InteractiveMobileQuotation from './components/InteractiveMobileQuotation';
import Files from './components/Files';
import SettingsScreen from './components/SettingScreen';
import { useFirebaseService } from './hooks/useFirebaseService';
import ItemsList from './components/ItemsList';
import { ThemeProvider } from './contexts/ThemeContext';
import { ProductProvider } from './contexts/ProductContext';
import { useSwipeable } from 'react-swipeable';
import { AuthProvider } from './contexts/AuthContext';
import { GlobalProvider } from './contexts/GlobalContext'; // Add this import
import ProfileCompletionPopup from './components/ProfileCompletionPopup';
import SharedQuotation from './components/SharedQuotation';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SubscriptionExpiredModal from './components/SubscriptionExpiredModal';
import TutorialPopup from './components/TutorialPopup';
import TrialStartedPopup from './components/TrialStartedPopup';
import UpdatePopup from './components/UpdatePopup';
import { clearCache } from './utils/cacheHelpers'; // Add this import

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('quoteMaker');
  const [editingQuotation, setEditingQuotation] = useState(null);
  const [quotations, setQuotations] = useState([]);
  const [error, setError] = useState(null);
  const { getQuotations, loading: serviceLoading, error: serviceError, addQuotation, updateQuotation, duplicateQuotation } = useFirebaseService();
  const [refreshing, setRefreshing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [showProfileCompletion, setShowProfileCompletion] = useState(false);
  const [profileComplete, setProfileComplete] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [showSubscriptionExpired, setShowSubscriptionExpired] = useState(false);
  const [showTutorialPopup, setShowTutorialPopup] = useState(false);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(true);
  const [hasShownProfilePopup, setHasShownProfilePopup] = useState(false);
  const [showTrialStarted, setShowTrialStarted] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [isCheckingUpdate, setIsCheckingUpdate] = useState(false);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("User authenticated:", user.email);
        setUser(user);
        checkUserProfile(user);
      } else {
        console.log("No authenticated user");
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const checkUserProfile = async (user) => {
    try {
      let userDoc = await getDoc(doc(db, 'users', user.uid));
      const isNewUser = !userDoc.exists();
      
      if (isNewUser) {
        await setDoc(doc(db, 'users', user.uid), {
          email: user.email,
          profileCompleted: false,
          signupTimestamp: new Date().toISOString(),
          isNewUser: true
        });
        userDoc = await getDoc(doc(db, 'users', user.uid));
      }
      
      const userData = userDoc.data();
      setProfileComplete(userData.profileCompleted || false);
    } catch (error) {
      console.error("Error checking user profile:", error);
    }
  };

  const fetchQuotations = useCallback(async () => {
    if (user) {
      try {
        const result = await getQuotations(user.uid);
        setQuotations(result.quotations);
        setError(null); // Clear any existing errors
      } catch (err) {
        console.error("Error fetching quotations:", err);
        // Don't show error message if we're offline
        if (navigator.onLine) {
          setError("Unable to sync with server. Using cached data.");
        }
      }
    }
  }, [user, getQuotations]);

  useEffect(() => {
    if (user) {
      fetchQuotations();
    }
  }, [user, fetchQuotations]);

  const handleSignIn = async () => {
    try {
      const credential = GoogleAuthProvider.credential(
        null,
        '{"sub": "abc123", "email": "user@example.com", "name": "Test User"}'
      );
      await signInWithCredential(auth, credential);
    } catch (error) {
      console.error('Error signing in:', error);
      alert('Failed to sign in. Please try again.');
    }
  };

  const handleEditQuotation = (quotation) => {
    setEditingQuotation(quotation);
    setActiveTab('quoteMaker');
  };

  const handleCloseQuotation = () => {
    setEditingQuotation(null);
    setActiveTab('quotations');
  };

  const handleSaveQuotation = async (savedQuotation) => {
    setEditingQuotation(null);
    setActiveTab('quotations');

    // Scroll to top immediately
    window.scrollTo(0, 0);
    const filesContainer = document.querySelector('.files-container');
    if (filesContainer) {
      filesContainer.scrollTop = 0;
    }

    // Set a small delay before starting the refresh process
    setTimeout(async () => {
      setRefreshing(true);

      try {
        if (savedQuotation.id) {
          await updateQuotation(savedQuotation.id, savedQuotation);
        } else {
          await addQuotation(savedQuotation);
        }
        await fetchQuotations();
      } catch (error) {
        console.error("Error saving quotation:", error);
        setError("Failed to save quotation. Please try again.");
      } finally {
        setRefreshing(false);
        setRefreshTrigger(prev => prev + 1);
      }
    }, 100); // 100ms delay before starting the refresh process
  };

  const handleStartNewQuotation = async (clientName, propertyType) => {
    const newQuotation = {
      clientName,
      propertyType,
      createdDate: new Date().toISOString(),
      updatedDate: new Date().toISOString(),
      // ... other quotation properties
    };
    await addQuotation(newQuotation);
    fetchQuotations(); // Use fetchQuotations instead of refreshQuotations
  };

  const handleDuplicateQuotation = async (quotationId) => {
    try {
      const duplicatedQuotation = await duplicateQuotation(quotationId);
      await fetchQuotations();
      setEditingQuotation(duplicatedQuotation);
      setActiveTab('quoteMaker');
    } catch (error) {
      console.error("Error duplicating quotation:", error);
      setError("Failed to duplicate quotation. Please try again.");
    }
  };

  const onRefreshQuote = useCallback(() => {
    if (activeTab === 'quoteMaker') {
      // Refresh the quote maker component
      // You might need to implement a refresh method in your InteractiveMobileQuotation component
      if (editingQuotation) {
        setEditingQuotation({ ...editingQuotation, refreshTimestamp: Date.now() });
      }
    }
  }, [activeTab, editingQuotation]);

  const handleRefresh = useCallback(() => {
    return new Promise((resolve) => {
      setRefreshing(true);
      // Refresh logic based on active tab
      switch (activeTab) {
        case 'quotations':
          fetchQuotations().then(() => {
            setRefreshing(false);
            resolve();
          });
          break;
        case 'productsList':
          // Add refresh logic for products list
          // For example: refreshProducts().then(() => resolve());
          setTimeout(() => {
            setRefreshing(false);
            resolve();
          }, 1000); // Temporary placeholder, replace with actual refresh logic
          break;
        case 'settings':
          // Add refresh logic for settings
          // For example: refreshSettings().then(() => resolve());
          setTimeout(() => {
            setRefreshing(false);
            resolve();
          }, 1000); // Temporary placeholder, replace with actual refresh logic
          break;
        default:
          setTimeout(() => {
            setRefreshing(false);
            resolve();
          }, 1000);
      }
    });
  }, [activeTab, fetchQuotations]);

  const swipeHandlers = useSwipeable({
    onSwiping: (eventData) => {
      if (eventData.dir === 'Down' && eventData.deltaY > 300 && !refreshing && !isDragging) {
        handleRefresh();
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  useEffect(() => {
    let timer;
    
    const checkAndShowProfilePopup = async () => {
      if (!user || hasShownProfilePopup) return;

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        
        if (!userData?.profileCompleted) {
          if (userData.isNewUser) {
            // For new users, wait 5 minutes
            timer = setTimeout(() => {
              setShowProfileCompletion(true);
              setHasShownProfilePopup(true);
            }, 5 * 60 * 1000);
          } else {
            // For existing users with incomplete profiles, show immediately
            setShowProfileCompletion(true);
            setHasShownProfilePopup(true);
          }
        }
      } catch (error) {
        console.error("Error in profile popup check:", error);
      }
    };

    checkAndShowProfilePopup();

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [user, hasShownProfilePopup]);

  const handleProfileCompletionClose = useCallback(() => {
    setShowProfileCompletion(false);
  }, []);

  const handleProfileComplete = useCallback(async (profileData) => {
    if (!user) return;
    
    try {
      await setDoc(doc(db, 'users', user.uid), {
        ...profileData,
        profileCompleted: true,
        isNewUser: false,
        updatedAt: new Date().toISOString()
      }, { merge: true });
      
      setProfileComplete(true);
      setShowProfileCompletion(false);
      setHasShownProfilePopup(true);
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile. Please try again.");
    }
  }, [user]);

  const checkSubscriptionValidity = async (user) => {
    try {
      const allowedUserRef = doc(db, "allowedUsers", user.email);
      const allowedUserSnap = await getDoc(allowedUserRef);
      
      if (!allowedUserSnap.exists()) {
        // Create new trial account if user doesn't exist
        const trialEndTime = new Date();
        trialEndTime.setDate(trialEndTime.getDate() + 7); // 7 days trial

        await setDoc(allowedUserRef, {
          email: user.email,
          nextBillingDate: trialEndTime,
          createdAt: new Date(),
          trialEndDate: trialEndTime,
          isTrialUser: true,
          status: 'active'
        });
        return true;
      }

      const userData = allowedUserSnap.data();
      const now = new Date();

      // If status is explicitly set to inactive
      if (userData.status === 'inactive') {
        return false;
      }

      // Check trial period first
      if (userData.isTrialUser && userData.trialEndDate) {
        const trialEnd = userData.trialEndDate.toDate();
        if (now <= trialEnd) {
          return true;
        }
      }

      // Then check billing date
      if (userData.nextBillingDate) {
        const nextBilling = userData.nextBillingDate.toDate();
        if (now <= nextBilling) {
          return true;
        }
      }

      return false;
    } catch (error) {
      console.error("Error checking subscription validity:", error);
      return false;
    }
  };

  useEffect(() => {
    const checkValidity = async () => {
      if (user) {
        const isValid = await checkSubscriptionValidity(user);
        console.log("Subscription validity check result:", isValid);
        if (!isValid) {
          setShowSubscriptionExpired(true);
        }
      }
    };
    
    checkValidity();
    
    // Check every minute instead of every hour
    const interval = setInterval(checkValidity, 60000); // 60000ms = 1 minute
    
    return () => clearInterval(interval);
  }, [user]);

  useEffect(() => {
    const checkFirstTimeUser = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userData = userDoc.data();
          
          // Check if this is a new user (either no document or first login)
          if (!userDoc.exists()) {
            // This is a completely new user
            setShowTrialStarted(true);
            await setDoc(doc(db, 'users', user.uid), {
              email: user.email,
              isNewUser: true,
              hasSeenTrialPopup: true,
              hasSeenInstallVideo: false,
              hasSeenTutorial: false,
              createdAt: new Date(),
              updatedAt: new Date()
            });
            
            // Show installation video after 30 seconds
            setTimeout(() => {
              showInstallationVideo();
            }, 30000);
            
            // Show tutorial popup after 2 minutes
            setTimeout(() => {
              setShowTutorialPopup(true);
              updateDoc(doc(db, 'users', user.uid), {
                hasSeenTutorial: true,
                updatedAt: new Date()
              });
            }, 120000);
          }
        } catch (error) {
          console.error("Error checking first time user:", error);
        }
      }
    };

    // Function to show installation video
    const showInstallationVideo = () => {
      const videoModal = document.createElement('div');
      videoModal.className = 'fixed inset-0 flex items-center justify-center bg-black/60 backdrop-blur-sm z-50';
      videoModal.innerHTML = `
        <div class="bg-white p-4 rounded-lg shadow-lg w-[90%] sm:w-[400px] relative">
          <button class="absolute right-2 top-2 text-gray-400 hover:text-gray-600 z-10" onclick="this.parentElement.parentElement.remove()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div class="relative" style="padding-top: 177.77%"> <!-- Aspect ratio for vertical video (9:16) -->
            <iframe
              class="absolute inset-0 w-full h-full"
              src="https://youtube.com/embed/8wj0ISfBTTE?autoplay=1&mute=0"
              title="App Installation Tutorial"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      `;
      document.body.appendChild(videoModal);
      
      // Update user's status
      if (user) {
        updateDoc(doc(db, 'users', user.uid), {
          hasSeenInstallVideo: true,
          updatedAt: new Date()
        });
      }
    };

    checkFirstTimeUser();
  }, [user]);

  const handleGoToTutorials = () => {
    setActiveTab('settings');
    setShowTutorialPopup(false);
    // Add navigation to App Demo section
    const settingsScreen = document.querySelector('.settings-screen');
    if (settingsScreen) {
      setTimeout(() => {
        settingsScreen.dispatchEvent(new CustomEvent('navigateToSection', {
          detail: { section: 'appDemo' }
        }));
      }, 100);
    }
  };

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js').then(registration => {
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          newWorker.addEventListener('statechange', () => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              setWaitingWorker(newWorker);
              setUpdateAvailable(true);
            }
          });
        });
      });

      // Check for updates every minute
      const interval = setInterval(() => {
        navigator.serviceWorker.getRegistration().then(registration => {
          registration?.update();
        });
      }, 60000);

      return () => clearInterval(interval);
    }
  }, []);

  const handleUpdate = async () => {
    if (waitingWorker) {
      setIsUpdating(true);
      try {
        waitingWorker.postMessage({ type: 'SKIP_WAITING' });
        setUpdateAvailable(false);
        await clearCacheAndReload();
      } catch (error) {
        console.error('Update failed:', error);
        setIsUpdating(false);
      }
    }
  };

  // Add this new function for version checking
  const checkForUpdates = async () => {
    if (isCheckingUpdate) return;
    
    try {
      setIsCheckingUpdate(true);
      const response = await fetch('/manifest.json?' + new Date().getTime());
      const manifest = await response.json();
      const currentVersion = process.env.REACT_APP_VERSION || '1.2';
      
      if (manifest.version !== currentVersion) {
        setShowUpdatePopup(true); // Show popup first
        
        // Wait for user interaction before clearing cache
        if (waitingWorker) {
          return; // Let handleUpdate handle the update process
        }
        
        // If no service worker, proceed with manual update
        await clearCacheAndReload();
      }
    } catch (error) {
      console.error('Error checking for updates:', error);
    } finally {
      setIsCheckingUpdate(false);
    }
  };

  const clearCacheAndReload = async () => {
    try {
      // Clear Cache Storage
      if ('caches' in window) {
        const cacheKeys = await caches.keys();
        await Promise.all(
          cacheKeys.map(key => caches.delete(key))
        );
      }
      
      // Clear IndexedDB cache
      await clearCache();
      
      // Clear localStorage
      localStorage.clear();
      
      // Unregister service workers
      if ('serviceWorker' in navigator) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        await Promise.all(registrations.map(reg => reg.unregister()));
      }
      
      // Reload with cache bypass
      window.location.reload(true);
    } catch (error) {
      console.error('Error during cache clearing:', error);
      // Force reload as fallback
      window.location.reload(true);
    }
  };

  // Add this new useEffect for version checking
  useEffect(() => {
    // Check immediately
    checkForUpdates();
    
    // Then check every minute
    const interval = setInterval(checkForUpdates, 60000);
    
    return () => clearInterval(interval);
  }, []);

  if (loading || isUpdating) {
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center bg-white">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mb-4"></div>
        <p className="text-gray-600">
          {isUpdating ? "Updating application..." : "Loading..."}
        </p>
      </div>
    );
  }

  if (!user) {
    return <SignUp />;
  }

  return (
    <ThemeProvider>
      <AuthProvider>
        <GlobalProvider>
          <ProductProvider>
            <Router>
              <div className="w-full max-w-md mx-auto bg-gray-50 min-h-screen font-sans flex flex-col" {...swipeHandlers}>
                {isCheckingUpdate && (
                  <div className="fixed bottom-0 left-0 right-0 bg-blue-500 text-white text-xs text-center py-1">
                    Checking for updates...
                  </div>
                )}
                <header className="sticky top-0 bg-white shadow-md z-10">
                  <Tabs activeTab={activeTab} setActiveTab={setActiveTab} onRefreshQuote={onRefreshQuote} />
                </header>
                {(loading || serviceLoading || refreshing) && (
                  <div className="text-center py-2 bg-gray-100">
                    {refreshing ? "Refreshing..." : "Loading..."}
                  </div>
                )}
                <main className="flex-grow overflow-auto">
                  {(error || serviceError) && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                      {error || serviceError}
                    </div>
                  )}
                  <Routes>
                    <Route path="/" element={
                      <div>
                        <div className={activeTab === 'quoteMaker' ? 'block' : 'hidden'}>
                          <InteractiveMobileQuotation 
                            editQuotationId={editingQuotation ? editingQuotation.id : null}
                            editQuotationData={editingQuotation}
                            onSave={handleSaveQuotation}
                            onClose={handleCloseQuotation}
                            setIsDragging={setIsDragging}
                            onStartNew={handleStartNewQuotation}
                          />
                          {showProfileCompletion && !profileComplete && (
                            <ProfileCompletionPopup 
                              onClose={handleProfileCompletionClose} 
                              onComplete={handleProfileComplete}
                            />
                          )}
                        </div>
                        <div className={activeTab === 'quotations' ? 'block' : 'hidden'}>
                          <Files 
                            quotations={quotations}
                            onEditQuotation={handleEditQuotation}
                            onDuplicateQuotation={handleDuplicateQuotation}
                            refreshQuotations={fetchQuotations}
                            refreshTrigger={refreshTrigger}
                            refreshing={refreshing}
                          />
                        </div>
                        <div className={activeTab === 'productsList' ? 'block' : 'hidden'}>
                          <ItemsList />
                        </div>
                        <div className={activeTab === 'settings' ? 'block' : 'hidden'}>
                          <SettingsScreen 
                            waitingWorker={waitingWorker}
                            onUpdate={handleUpdate}
                          />
                        </div>
                      </div>
                    } />
                    <Route path="/share/:shareId" element={<SharedQuotation />} />
                  </Routes>
                </main>
              </div>
              {showSubscriptionExpired && (
                <SubscriptionExpiredModal 
                  onClose={() => setShowSubscriptionExpired(false)} 
                />
              )}
              {showTutorialPopup && (
                <TutorialPopup 
                  onClose={() => setShowTutorialPopup(false)}
                  onGoToTutorials={handleGoToTutorials}
                />
              )}
              {showTrialStarted && (
                <TrialStartedPopup 
                  onClose={() => setShowTrialStarted(false)} 
                />
              )}
              {showUpdatePopup && (
                <UpdatePopup 
                  onUpdate={handleUpdate}
                  onClose={() => setShowUpdatePopup(false)}
                />
              )}
            </Router>
          </ProductProvider>
        </GlobalProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
