// src/contexts/GlobalContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useFirebaseService } from '../hooks/useFirebaseService';
import { useAuth } from './AuthContext'; // Import useAuth if you haven't already

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [quotations, setQuotations] = useState([]);
  const [products, setProducts] = useState([]);
  const { subscribeToQuotations, subscribeToProducts } = useFirebaseService();
  const { user } = useAuth(); // Get the user from AuthContext

  useEffect(() => {
    let unsubscribeQuotations;
    let unsubscribeProducts;

    if (user) {
      unsubscribeQuotations = subscribeToQuotations(user.uid, (fetchedQuotations) => {
        setQuotations(fetchedQuotations);
      });

      unsubscribeProducts = subscribeToProducts(user.uid, (fetchedProducts) => {
        setProducts(fetchedProducts);
      });
    }

    return () => {
      if (unsubscribeQuotations) unsubscribeQuotations();
      if (unsubscribeProducts) unsubscribeProducts();
    };
  }, [user, subscribeToQuotations, subscribeToProducts]);

  return (
    <GlobalContext.Provider value={{ quotations, setQuotations, products, setProducts }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);