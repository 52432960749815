import React from 'react';
import { RefreshCw, X } from 'lucide-react';

const UpdatePopup = ({ onUpdate, onClose }) => {
  const currentVersion = "v1.2"; // Previous version
  const newVersion = "v1.3"; // New version
  
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-sm w-full p-6 relative">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
        >
          <X size={20} />
        </button>

        <div className="flex items-center space-x-3 mb-4">
          <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
            <RefreshCw className="w-6 h-6 text-blue-600" />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-gray-900">Update Available</h2>
            <p className="text-sm text-gray-600">
              {currentVersion} → {newVersion}
            </p>
          </div>
        </div>

        <div className="space-y-4">
          <button
            onClick={onUpdate}
            className="w-full bg-blue-600 text-white py-2.5 px-4 rounded-lg hover:bg-blue-700 transition duration-200 font-medium"
          >
            Update Now
          </button>
          <button
            onClick={onClose}
            className="w-full border border-gray-300 text-gray-700 py-2.5 px-4 rounded-lg hover:bg-gray-50 transition duration-200 font-medium"
          >
            Later
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdatePopup; 