export const getAppVersion = () => {
  try {
    return fetch('/manifest.json')
      .then(response => response.json())
      .then(manifest => `Version ${manifest.version}`)
      .catch(() => {
        console.error('Error fetching manifest version');
        return `Version ${process.env.REACT_APP_VERSION || '1.3'}`;
      });
  } catch (error) {
    console.error('Error getting app version:', error);
    return `Version ${process.env.REACT_APP_VERSION || '1.3'}`;
  }
};

export const checkVersionAndUpdate = async () => {
  try {
    const response = await fetch('/manifest.json');
    const manifest = await response.json();
    const currentVersion = process.env.REACT_APP_VERSION || '1.3';
    
    return manifest.version !== currentVersion;
  } catch (error) {
    console.error('Error checking version:', error);
    return false;
  }
}; 