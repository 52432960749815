const axios = require('axios');
const crypto = require('crypto');
require('dotenv').config();

class PhonePeAPI {
  constructor() {
    this.merchantId = process.env.MERCHANT_ID;
    this.apiKey = process.env.TEST_API_KEY_VALUE;
    this.apiKeyIndex = process.env.TEST_API_KEY_INDEX;
    this.baseUrl = process.env.TEST_HOST_URL;
  }

  generateXVerify(payload, apiEndpoint) {
    const string = `${payload}${apiEndpoint}${this.apiKey}`;
    return crypto.createHash('sha256').update(string).digest('hex') + '###' + this.apiKeyIndex;
  }

  async initiatePayment(amount, transactionId, callbackUrl) {
    const payload = {
      merchantId: this.merchantId,
      merchantTransactionId: transactionId,
      amount: amount * 100, // Convert to paise
      redirectUrl: callbackUrl,
      redirectMode: 'POST',
      callbackUrl: callbackUrl,
      mobileNumber: '9999999999', // Optional: Customer's mobile number
      paymentInstrument: {
        type: 'PAY_PAGE'
      }
    };

    const payloadString = JSON.stringify(payload);
    const base64Payload = Buffer.from(payloadString).toString('base64');
    const apiEndpoint = '/pg/v1/pay';
    const xVerify = this.generateXVerify(base64Payload, apiEndpoint);

    try {
      const response = await axios.post(`${this.baseUrl}${apiEndpoint}`, {
        request: base64Payload
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-VERIFY': xVerify
        }
      });

      return response.data;
    } catch (error) {
      console.error('Error initiating payment:', error.response ? error.response.data : error.message);
      throw error;
    }
  }

  async checkStatus(merchantTransactionId) {
    const apiEndpoint = `/pg/v1/status/${this.merchantId}/${merchantTransactionId}`;
    const xVerify = this.generateXVerify('', apiEndpoint);

    try {
      const response = await axios.get(`${this.baseUrl}${apiEndpoint}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-VERIFY': xVerify,
          'X-MERCHANT-ID': this.merchantId
        }
      });

      return response.data;
    } catch (error) {
      console.error('Error checking payment status:', error.response ? error.response.data : error.message);
      throw error;
    }
  }
}

module.exports = PhonePeAPI;
