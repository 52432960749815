import React, { useState, useEffect, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import DownloadPDF from './DownloadPDF';
import { useReactToPrint } from 'react-to-print';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Plus, Trash2, Edit2, Check, ChevronDown, Upload, User, MapPin, Phone, X, Banknote, Package, FileText, DollarSign, Minus, ChevronUp, CreditCard, Move, Printer } from 'lucide-react';
import { format } from 'date-fns';
import PrintPDF from './DownloadPDF';
import ReactDOM from 'react-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useFirebaseService } from '../hooks/useFirebaseService';
import { auth } from '../Services/firebase';
import NewQuotationPopup from './NewQuotationPopup';
import { useAuth } from '../contexts/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '../hooks/useTheme';
import { useGlobalContext } from '../contexts/GlobalContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { defaultRoomNames } from './CustomRoomNames';
import imageCompression from 'browser-image-compression';
import { openDB } from 'idb';
import { isMobile } from 'react-device-detect';
import QuotationSkeleton from './QuotationSkeleton';
import { getCachedData, setCachedData } from '../utils/cacheHelpers';
import DownloadPrintablePDF from './DownloadPrintablePDF';

const NumberInput = ({ value, onChange, label, step = 1 }) => {
  const [localValue, setLocalValue] = useState(value !== undefined && value !== '' ? value.toString() : '0');
  const theme = useTheme();

  useEffect(() => {
    setLocalValue(value !== undefined && value !== '' ? value.toString() : '0');
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || (newValue.match(/^\d*\.?\d*$/) && !isNaN(parseFloat(newValue)))) {
      setLocalValue(newValue);
      onChange(newValue === '' ? 0 : parseFloat(newValue));
    }
  };

  const handleBlur = () => {
    const parsedValue = parseFloat(localValue);
    if (isNaN(parsedValue) || localValue === '') {
      setLocalValue('0');
      onChange(0);
    } else {
      setLocalValue(parsedValue.toString());
      onChange(parsedValue);
    }
  };

  return (
    <div className="flex items-center justify-between mb-2">
      <span className="text-sm text-gray-600">{label}:</span>
      <div className="flex items-center">
        <button 
          className={`${theme.numberInputButtons} px-2.5 py-1.5 rounded-l transition-colors duration-200`}
          onClick={() => onChange(Math.max(0, parseFloat(value || 0) - step))}
        >
          -
        </button>
        <input
          type="text"
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          className={`w-20 text-center border-y border-gray-300 focus:outline-none ${theme.inputFocus} text-sm`}
        />
        <button 
          className={`${theme.numberInputButtons} px-2.5 py-1.5 rounded-r transition-colors duration-200`}
          onClick={() => onChange(parseFloat(value || 0) + step)}
        >
          +
        </button>
      </div>
    </div>
  );
};

const ItemDropdown = ({ value, onChange, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div
        className="w-full p-2 border border-gray-300 rounded-md flex justify-between items-center cursor-pointer bg-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-sm">{value || "Select an item"}</span>
        <ChevronDown size={20} />
      </div>
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-md shadow-lg max-h-60 overflow-y-auto">
          {options.map((option, index) => (
            <li
              key={index}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm"
              onClick={() => {
                onChange(option);
                setIsOpen(false);
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const EditableDropdown = ({ value, options, onChange, onBlur, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [lastSelected, setLastSelected] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        onBlur(inputValue);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onBlur, inputValue]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue);
    setIsOpen(true);
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    onChange(option);
    setIsOpen(false);
    setLastSelected(option);
  };

  const filteredOptions = options.filter(option => {
    const searchTerms = inputValue.toLowerCase().split(/\s+/);
    return searchTerms.every(term => 
      option.toLowerCase().split(/\s+/).some(word => 
        word.startsWith(term)
      )
    );
  });

  return (
    <div className="relative" ref={dropdownRef}>
      <input
        className="bg-white text-gray-800 font-bold w-full p-2 rounded-md border border-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setIsOpen(true)}
        placeholder={placeholder}
      />
      {isOpen && (
        <ul className="absolute z-50 w-full bg-white border border-gray-300 mt-1 rounded-md shadow-lg max-h-48 overflow-y-auto">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <li
                key={index}
                className="px-4 py-2 hover:bg-indigo-100 cursor-pointer text-sm flex items-center justify-between"
                onClick={() => handleOptionClick(option)}
              >
                <span>{option}</span>
                {option === lastSelected && (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                )}
              </li>
            ))
          ) : (
            <li className="px-4 py-2 text-gray-500 text-sm">No matches found</li>
          )}
        </ul>
      )}
    </div>
  );
};


const formatPrice = (price) => {
  return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(price);
};

const formatPriceWithoutDecimal = (price) => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);
};

const AutoResizeTextarea = ({ value, onChange, placeholder, className, minHeight = '20px', style }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = minHeight;
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight > parseInt(minHeight) ? `${scrollHeight}px` : minHeight;
    }
  }, [value, minHeight]);

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={`w-full px-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm resize-none overflow-hidden ${className}`}
      style={{ 
        minHeight: minHeight,
        lineHeight: '1.2',  // Reduced line height
        padding: '2px 8px',  // Reduced vertical padding
        ...style
      }}
    />
  );
};

const InteractiveMobileQuotation = ({ 
  initialQuotationData = {}, 
  editQuotationId, 
  onSave, 
  onClose, 
  ...otherProps 
}) => {
  const [hideUnitPriceInPDF, setHideUnitPriceInPDF] = useState(initialQuotationData.hideUnitPriceInPDF || false);
  const theme = useTheme();
  const { products, quotations, setQuotations } = useGlobalContext();
  const [isCreatingNewQuotation, setIsCreatingNewQuotation] = useState(false);
  const [showNewQuotationPopup, setShowNewQuotationPopup] = useState(false);
  const [propertyType, setPropertyType] = useState('');
  const [rooms, setRooms] = useLocalStorage('quotation_rooms', []);
  const [discount, setDiscount] = useState(0);
  const [clientName, setClientName] = useLocalStorage('quotation_clientName', '');
  const [date, setDate] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [editingItem, setEditingItem] = useState(null);
  const [roomTypes, setRoomTypes] = useState([]);
  const [isEditingDiscount, setIsEditingDiscount] = useState(false);
  const [itemSuggestions] = useState([
    "Wardrobe with Laminates", 
    "Kitchen Base Unit", 
    "Wardrobe Loft Unit",
    "TV Unit",
    "Study Table",
    "Dressing Table"
  ]);
  const [isEditingAddress, setIsEditingAddress] = useState(true);
  const [saveStatus, setSaveStatus] = useState('Saved');
  const [isEditing, setIsEditing] = useState(false);
  const [currentEditQuotationId, setCurrentEditQuotationId] = useState(editQuotationId || null);

  const [paymentTerms, setPaymentTerms] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [notes, setNotes] = useState([]);
  const [isNewQuotation, setIsNewQuotation] = useState(false);

  const [expandedSection, setExpandedSection] = useState(null);

  const [sectionHeights, setSectionHeights] = useState({
    paymentTerms: 0,
    materials: 0,
    notes: 0
  });


  const sectionRefs = {
    paymentTerms: useRef(null),
    materials: useRef(null),
    notes: useRef(null)
  };

  const [version, setVersion] = useState(1);

  const { user, loading, getUserSettings, updateUserSettings } = useFirebaseService();
  const { addQuotation, updateQuotation, getQuotation } = useFirebaseService();

  const [gst, setGst] = useState(0);
  const [isGstEnabled, setIsGstEnabled] = useState(initialQuotationData.isGstEnabled || false);
  const [isDiscountEnabled, setIsDiscountEnabled] = useState(initialQuotationData.isDiscountEnabled || false);

  const [logo, setLogo] = useState(null);
  const [companyDetails, setCompanyDetails] = useState({
    name: '',
    address: '',
    mobileNumber: '',
    logo: null
  });

  const [isSaving, setIsSaving] = useState(false);

  const [isLoadingFile, setIsLoadingFile] = useState(false);

  // Initialize IndexedDB
  const initDB = async () => {
    return openDB('QuotationApp', 1, {
      upgrade(db) {
        db.createObjectStore('logos');
      },
    });
  };

  // Save logo to IndexedDB
  const saveLogo = async (logoData) => {
    try {
      const db = await initDB();
      await db.put('logos', logoData, 'currentLogo');
      console.log('Logo saved to IndexedDB');
    } catch (error) {
      console.error('Error saving logo to IndexedDB:', error);
    }
  };

  // Load logo from IndexedDB
  const loadLogo = async () => {
    try {
      const db = await initDB();
      const logo = await db.get('logos', 'currentLogo');
      console.log('Logo loaded from IndexedDB:', logo ? 'Found' : 'Not found');
      return logo;
    } catch (error) {
      console.error('Error loading logo from IndexedDB:', error);
      return null;
    }
  };

  // Modified handleLogoUpload function
  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const compressedFile = await compressImage(file);
        
        if (compressedFile.size > 800 * 1024) {
          throw new Error("Image is too large. Please choose a smaller image.");
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
          const logoData = e.target.result;
          setLogo(logoData);
          setCompanyDetails(prev => ({ ...prev, logo: logoData }));
          await saveLogo(logoData);
          console.log('Logo uploaded and saved');
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error processing logo:", error);
        alert(error.message || "Error processing logo. Please try again with a smaller image.");
      }
    }
  };

  // Load logo and user settings on component mount
  useEffect(() => {
    const fetchLogo = async () => {
      const savedLogo = await loadLogo();
      if (savedLogo) {
        setLogo(savedLogo);
        setCompanyDetails(prev => ({ ...prev, logo: savedLogo }));
        console.log('Logo loaded on mount');
      } else {
        console.log('No logo found on mount');
      }
    };
    fetchLogo();
  }, []);

  // Modified loadUserSettings function
  const loadUserSettings = async (forceLoad = false) => {
    if (user) {
      try {
        const settings = await getUserSettings(user.uid);
        if (settings) {
          setPaymentTerms(settings.paymentTerms || []);
          setMaterials(settings.materialSpecs || []);
          setNotes(settings.notes || []);
          setCompanyDetails(prev => ({
            name: settings.companyName || prev.name,
            address: settings.companyAddress || prev.address,
            mobileNumber: settings.mobileNumber || prev.mobileNumber,
            logo: prev.logo // Keep the current logo state
          }));
        }
      } catch (error) {
        console.error('Error loading user settings:', error);
      }
    }
    // Load logo from IndexedDB
    const savedLogo = await loadLogo();
    if (savedLogo) {
      setLogo(savedLogo);
      setCompanyDetails(prev => ({ ...prev, logo: savedLogo }));
      console.log('Logo loaded from IndexedDB');
    } else {
      console.log('No logo found in IndexedDB');
    }
  };

  // Modified deleteLogo function
  const deleteLogo = async () => {
    try {
      setLogo(null);
      setCompanyDetails(prev => ({ ...prev, logo: null }));
      const db = await initDB();
      await db.delete('logos', 'currentLogo');
      console.log('Logo deleted successfully');
      
      // Update user settings in Firestore to remove the logo
      if (user) {
        await updateUserSettings(user.uid, { logo: null });
      }
    } catch (error) {
      console.error('Error deleting logo:', error);
      alert('Failed to delete logo. Please try again.');
    }
  };

  useEffect(() => {
    const updateHeight = (section) => {
      if (expandedSection === section && sectionRefs[section].current) {
        setSectionHeights(prev => ({
          ...prev,
          [section]: sectionRefs[section].current.scrollHeight
        }));
      } else {
        setSectionHeights(prev => ({...prev, [section]: 0}));
      }
    };

    updateHeight('paymentTerms');
    updateHeight('materials');
    updateHeight('notes');
  }, [expandedSection, paymentTerms, materials, notes]);

  // Load state from local storage
  useEffect(() => {
    const savedState = localStorage.getItem('quotation_state');
    if (savedState) {
      const {
        rooms,
        discount,
        companyName,
        mobileNumber,
        logo,
        companyAddress,
        clientName,
        date,
        isCreatingNewQuotation,
        showNewQuotationPopup,
        propertyType,
        hasUnsavedChanges,
        editingItem,
        roomTypes,
        isEditingDiscount,
        itemSuggestions,
        isEditingAddress,
        saveStatus,
        isEditing,
        currentEditQuotationId,
        hideUnitPriceInPDF,
        paymentTerms,
        materials,
        notes,
        gst,
        isGstEnabled,
        isDiscountEnabled,
        version
      } = JSON.parse(savedState);

      setRooms(rooms);
      setDiscount(discount);
      setCompanyDetails({
        name: companyName,
        address: companyAddress,
        mobileNumber: mobileNumber,
        logo: logo
      });
      setClientName(clientName);
      setDate(date);
      setIsCreatingNewQuotation(isCreatingNewQuotation);
      setShowNewQuotationPopup(showNewQuotationPopup);
      setPropertyType(propertyType);
      setHasUnsavedChanges(hasUnsavedChanges);
      setEditingItem(editingItem);
      setRoomTypes(roomTypes);
      setIsEditingDiscount(isEditingDiscount);
      setIsEditingAddress(isEditingAddress);
      setSaveStatus(saveStatus);
      setIsEditing(isEditing);
      setCurrentEditQuotationId(currentEditQuotationId);
      setHideUnitPriceInPDF(hideUnitPriceInPDF);
      setPaymentTerms(paymentTerms);
      setMaterials(materials);
      setNotes(notes);
      setGst(gst);
      setIsGstEnabled(isGstEnabled);
      setIsDiscountEnabled(isDiscountEnabled);
      setVersion(version);
    }
  }, []);

  useEffect(() => {
    if (editQuotationId) {
      loadQuotation(editQuotationId);
    } else {
      const fetchLogo = async () => {
        const savedLogo = await loadLogo();
        if (savedLogo) {
          setLogo(savedLogo);
          setCompanyDetails(prev => ({ ...prev, logo: savedLogo }));
        }
      };
      fetchLogo();
    }
  }, [editQuotationId]);

  const handleCreateNewQuotation = async () => {
    setIsCreatingNewQuotation(true);
    
    // Show UI immediately with minimal data
    setShowNewQuotationPopup(true);
    
    // Load cached settings first
    const cachedSettings = await getCachedData('userSettings', user.uid);
    if (cachedSettings) {
      setCompanyDetails(cachedSettings.companyDetails);
    }
    
    // Then load fresh data in background
    loadUserSettings(true).then(() => {
      // Update UI with fresh data if different from cache
      console.log('Fresh settings loaded');
    });
  };

  const handleStartNewQuotation = () => {
    setIsCreatingNewQuotation(true);
    setShowNewQuotationPopup(false);
    setHasUnsavedChanges(true);
    setClientName(clientName);
    // Preserve the property type selected in the popup
    setPropertyType(propertyType);
  };

  const resetQuotationState = () => {
    setRooms([{ id: uuidv4(), name: "", items: [] }]);
    setDiscount(0);
    setClientName('');
    setDate(new Date());
    setIsCreatingNewQuotation(false);
    setShowNewQuotationPopup(false);
    setPropertyType('');
    setHasUnsavedChanges(false);
    setEditingItem(null);
    setIsEditingDiscount(false);
    setCompanyDetails({
      name: '',
      address: '',
      mobileNumber: '',
      logo: null
    });
    setCurrentEditQuotationId(null); // Add this line
    setIsNewQuotation(true); // Add this line
    // Reset other state variables as needed
  };

  useEffect(() => {
    if (editQuotationId) {
      loadQuotation(editQuotationId);
    }
  }, [editQuotationId]);

  const loadQuotation = async (id) => {
    setIsLoadingFile(true);
    try {
      const quotation = await getQuotation(id);
      if (quotation) {
        console.log('Loaded quotation:', quotation);
        setRooms(quotation.quotationData.rooms);
        setDiscount(quotation.quotationData.discount);
        setCompanyDetails({
          name: quotation.quotationData.companyName,
          address: quotation.quotationData.companyAddress,
          mobileNumber: quotation.quotationData.mobileNumber,
          logo: quotation.quotationData.logo || null
        });
        setLogo(quotation.quotationData.logo || null);
        if (quotation.quotationData.logo) {
          await saveLogo(quotation.quotationData.logo);
          console.log('Logo saved from loaded quotation');
        } else {
          console.log('No logo in loaded quotation');
        }
        setClientName(quotation.quotationData.clientName);
        setDate(quotation.quotationData.date ? new Date(quotation.quotationData.date) : null);
        setIsCreatingNewQuotation(true);
        
        setPaymentTerms(quotation.quotationData.paymentTerms || []);
        setMaterials(quotation.quotationData.materials || []);
        setNotes(quotation.quotationData.notes || []);
        setGst(quotation.quotationData.gst || 0);
        setPropertyType(quotation.propertyType || '');
        setVersion(quotation.version || 1);
        setCurrentEditQuotationId(id);
        setIsNewQuotation(false); // Add this line
        setHideUnitPriceInPDF(quotation.hideUnitPriceInPDF || false);
        setIsGstEnabled(quotation.isGstEnabled || false);
        setIsDiscountEnabled(quotation.isDiscountEnabled || false);
        setDiscount(quotation.quotationData.discount || 0);
      }
    } catch (error) {
      console.error('Error loading quotation:', error);
      alert('Error loading quotation. Please try again.');
    } finally {
      setIsLoadingFile(false);
    }
  };

  // Debounced save function
  const debouncedSave = useCallback(
    debounce((state) => {
      const stateToSave = { ...state };
      delete stateToSave.logo; // Remove logo from the state to be saved in localStorage
      localStorage.setItem('quotation_state', JSON.stringify(stateToSave));
    }, 1000),
    []
  );

  // Save state to local storage
  useEffect(() => {
    const state = {
      rooms,
      discount,
      companyName: companyDetails.name,
      mobileNumber: companyDetails.mobileNumber,
      logo: companyDetails.logo,
      companyAddress: companyDetails.address,
      clientName,
      date,
      isCreatingNewQuotation,
      showNewQuotationPopup,
      propertyType,
      hasUnsavedChanges,
      editingItem,
      roomTypes,
      isEditingDiscount,
      itemSuggestions,
      isEditingAddress,
      saveStatus,
      isEditing,
      currentEditQuotationId,
      hideUnitPriceInPDF,
      paymentTerms,
      materials,
      notes,
      gst,
      isGstEnabled,
      isDiscountEnabled,
      version,
    };
    debouncedSave(state);
  }, [
    rooms,
    discount,
    companyDetails,
    clientName,
    date,
    isCreatingNewQuotation,
    showNewQuotationPopup,
    propertyType,
    hasUnsavedChanges,
    editingItem,
    roomTypes,
    isEditingDiscount,
    itemSuggestions,
    isEditingAddress,
    saveStatus,
    isEditing,
    currentEditQuotationId,
    hideUnitPriceInPDF,
    paymentTerms,
    materials,
    notes,
    gst,
    isGstEnabled,
    isDiscountEnabled,
    version,
    debouncedSave,
  ]);

  const calculateTotal = () => {
    return rooms.reduce((total, room) => 
      total + room.items.reduce((roomTotal, item) => roomTotal + item.totalPrice, 0)
    , 0);
  };

  const calculateGrandTotal = () => {
    const total = calculateTotal();
    const discountAmount = isDiscountEnabled ? (total * discount / 100) : 0;
    const discountedTotal = total - discountAmount;
    const gstAmount = isGstEnabled ? (discountedTotal * gst / 100) : 0;
    return discountedTotal + gstAmount;
  };

  const updateItem = (roomIndex, itemId, updates) => {
    setRooms(prevRooms => prevRooms.map((room, idx) => {
      if (idx === roomIndex) {
        return {
          ...room,
          items: room.items.map(item => {
            if (item.id === itemId) {
              const updatedItem = { ...item, ...updates };
              if ('length' in updates || 'height' in updates) {
                updatedItem.area = Number((updatedItem.length * updatedItem.height).toFixed(2));
              } else if ('area' in updates) {
                updatedItem.area = Number(updates.area.toFixed(2));
                updatedItem.length = 0;
                updatedItem.height = 0;
              }
              updatedItem.totalPrice = updatedItem.area * updatedItem.boxPrice;
              return updatedItem;
            }
            return item;
          })
        };
      }
      return room;
    }));
  };

  const addRoom = () => {
    const newRoom = {
      id: Date.now(),
      name: "",
      items: []
    };
    setRooms(prevRooms => [...prevRooms, newRoom]);
  };

  const addItem = (roomIndex) => {
    const newItemId = Date.now();
    setRooms(prevRooms => prevRooms.map((room, idx) => {
      if (idx === roomIndex) {
        const newItem = {
          id: newItemId,
          name: "",  // Start with an empty name
          length: 0,
          height: 0,
          area: 0,
          boxPrice: 0,
          totalPrice: 0
        };
        return { ...room, items: [...room.items, newItem] };
      }
      return room;
    }));
    setEditingItem(newItemId);
  };

  const deleteItem = (roomIndex, itemId) => {
    setRooms(prevRooms => prevRooms.map((room, idx) => 
      idx === roomIndex 
        ? { ...room, items: room.items.filter(item => item.id !== itemId) } 
        : room
    ));
    setEditingItem(null);
  };

  const deleteRoom = (roomIndex) => {
    setRooms(prevRooms => prevRooms.filter((_, idx) => idx !== roomIndex));
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.5, // Reduce max size to 500KB
      maxWidthOrHeight: 1024, // Reduce max width/height
      useWebWorker: true
    };
    
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
      throw error;
    }
  };

  const saveQuotation = async () => {
    try {
      setIsSaving(true);
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      
      console.log('Saving quotation. Current state:', { 
        isNewQuotation, 
        currentEditQuotationId,
        hideUnitPriceInPDF,
      });

      const quotationData = {
        userId: user.uid,
        clientName,
        propertyType,
        totalBudget: calculateGrandTotal(),
        createdDate: new Date().toISOString(),
        updatedDate: new Date().toISOString(),
        version,
        hideUnitPriceInPDF,
        isGstEnabled,
        isDiscountEnabled,
        quotationData: {
          companyName: companyDetails.name,
          mobileNumber: companyDetails.mobileNumber,
          logo: companyDetails.logo || null,
          companyAddress: companyDetails.address,
          clientName,
          date: date instanceof Date ? date.toISOString() : null,
          rooms,
          discount: isDiscountEnabled ? discount : 0,
          total: calculateTotal(),
          grandTotal: calculateGrandTotal(),
          paymentTerms,
          materials,
          notes,
          propertyType,
          gst: isGstEnabled ? gst : 0,
          hideUnitPriceInPDF,
          isGstEnabled,
          isDiscountEnabled,
        }
      };

      console.log('Quotation data to be saved:', quotationData);

      let savedQuote;
      if (currentEditQuotationId && !isNewQuotation) {
        console.log('Updating existing quotation:', currentEditQuotationId);
        try {
          savedQuote = await updateQuotation(currentEditQuotationId, quotationData);
        } catch (error) {
          if (error.message.includes('not found')) {
            console.log('Quotation not found, creating a new one');
            savedQuote = await addQuotation(quotationData);
          } else {
            throw error;
          }
        }
      } else {
        console.log('Creating new quotation');
        savedQuote = await addQuotation(quotationData);
      }

      console.log('Quotation saved successfully:', savedQuote);

      // Update component state
      setCurrentEditQuotationId(savedQuote.id);
      setIsNewQuotation(false);
      setHasUnsavedChanges(false);

      // Callback to parent component
      onSave(savedQuote);

      // User feedback
      alert('Quotation saved successfully!');

      // Update local storage
      const currentState = {
        rooms,
        discount,
        companyName: companyDetails.name,
        mobileNumber: companyDetails.mobileNumber,
        logo: null, // Don't store logo in localStorage
        companyAddress: companyDetails.address,
        clientName,
        date,
        isCreatingNewQuotation,
        showNewQuotationPopup,
        propertyType,
        hasUnsavedChanges: false,
        editingItem,
        roomTypes,
        isEditingDiscount,
        itemSuggestions,
        isEditingAddress,
        saveStatus,
        isEditing,
        currentEditQuotationId: savedQuote.id,
        hideUnitPriceInPDF,
        paymentTerms,
        materials,
        notes,
        gst,
        isGstEnabled,
        isDiscountEnabled,
        version,
        isNewQuotation: false,
      };
      debouncedSave(currentState);
      
      return savedQuote; // Return the saved quote for potential further use
    } catch (error) {
      console.error('Error saving quotation:', error);
      alert(`Error saving quotation: ${error.message}. Please try again.`);
      throw error; // Re-throw the error for the caller to handle if needed
    } finally {
      setIsSaving(false);
    }
  };

  const printRef = useRef();

  const handlePrintPDF = useReactToPrint({
    content: () => printRef.current,
    removeAfterPrint: true,
  });

  const downloadQuotation = () => {
    const quotationData = {
      companyName: companyDetails.name,
      mobileNumber: companyDetails.mobileNumber,
      logo: companyDetails.logo,
      companyAddress: companyDetails.address,
      clientName,
      date,
      rooms,
      discount,
      total: calculateTotal(),
      grandTotal: calculateGrandTotal(),
      hideUnitPrice: hideUnitPriceInPDF,
      paymentTerms,
      materials,
      notes,
      gst,
      isGstEnabled,
      isDiscountEnabled
    };

    const printComponent = (
      <PrintPDF ref={printRef} quotationData={quotationData} />
    );

    const printContainer = document.createElement('div');
    printContainer.style.position = 'absolute';
    printContainer.style.left = '-9999px';
    document.body.appendChild(printContainer);

    ReactDOM.render(printComponent, printContainer, () => {
      handlePrintPDF();
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(printContainer);
        document.body.removeChild(printContainer);
      }, 100);
    });
  };

  const handleRoomNameChange = (roomIndex, newName) => {
    setRooms(prevRooms => prevRooms.map((room, idx) => 
      idx === roomIndex ? { ...room, name: newName } : room
    ));
  };

  const renderItem = (item, itemIndex, roomIndex) => {
    const circleColor = theme.numberCircle;
    const separatorColor = theme.separatorColor;

    if (editingItem === item.id) {
      return (
        <div className={`${theme.itemBackground} rounded-lg p-2 mb-2 shadow-lg w-full`}>
          <div className="flex justify-between items-center mb-1">
            <div className={`${circleColor} text-white rounded-full w-6 h-6 flex items-center justify-center mr-3`}>
              {itemIndex + 1}
            </div>
            <span className="font-semibold text-gray-800 text-sm mr-3">{formatPrice(item.totalPrice)}</span>
          </div>
          <EditableDropdown
            value={item.name}
            options={products.map(product => product.name)}
            onChange={(newName) => {
              const selectedProduct = products.find(p => p.name === newName);
              if (selectedProduct) {
                updateItem(roomIndex, item.id, { 
                  name: newName, 
                  height: selectedProduct.height,
                  boxPrice: selectedProduct.unitPrice
                });
              } else {
                updateItem(roomIndex, item.id, { name: newName });
              }
            }}
            onBlur={(finalName) => updateItem(roomIndex, item.id, { name: finalName })}
            placeholder="Item Name"
          />
          <div className="mb-2"></div>
          <NumberInput
            label="Width (ft)"
            value={item.length || ''}
            onChange={(value) => updateItem(roomIndex, item.id, { length: value })}
            step={1}
          />
          <NumberInput
            label="Height (ft)"
            value={item.height || ''}
            onChange={(value) => updateItem(roomIndex, item.id, { height: value })}
            step={1}
          />
          <NumberInput
            label="Area/No.S"
            value={item.area || ''}
            onChange={(value) => updateItem(roomIndex, item.id, { area: value })}
            step={1}
          />
          <NumberInput
            label="Unit Price (₹)"
            value={item.boxPrice || ''}
            onChange={(value) => updateItem(roomIndex, item.id, { boxPrice: value })}
            step={50}
          />
          <div className="flex justify-between mt-3">
            <button
              className="bg-red-500 text-white px-3 py-2 rounded-md hover:bg-red-600 transition-colors duration-200"
              onClick={() => deleteItem(roomIndex, item.id)}
            >
              <Trash2 size={18} color="white" />
            </button>
            <button
              className="bg-green-500 text-white px-3 py-2 rounded-md hover:bg-green-600 transition-colors duration-200"
              onClick={() => setEditingItem(null)}
            >
              <Check size={18} color="white" />
            </button>
          </div>
        </div>
      );
    }

    return (
      <Draggable key={item.id} draggableId={`item-${item.id}`} index={itemIndex}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`${theme.itemBackground} rounded-lg p-2 mb-2 shadow-lg w-full ${
              snapshot.isDragging ? 'opacity-75' : ''
            }`}
          >
            <div className="flex justify-between items-center mb-1">
              <div className="flex items-center">
                <div
                  {...provided.dragHandleProps}
                  className="flex items-center cursor-move relative group"
                >
                  <div className={`${circleColor} text-white rounded-full w-6 h-6 flex items-center justify-center mr-1`}>
                    {itemIndex + 1}
                  </div>
                  <svg width="12" height="16" viewBox="0 0 12 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="text-gray-400 mr-2">
                    <circle cx="6" cy="3" r="1.25" />
                    <circle cx="6" cy="8" r="1.25" />
                    <circle cx="6" cy="13" r="1.25" />
                  </svg>
                  {/* Enhanced Tooltip with Move Icon */}
                  <div className="absolute left-0 -top-12 bg-gray-800 text-white text-xs rounded py-2 px-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap pointer-events-none flex items-center">
                    <span className="mr-2">Hold and drag to reorder</span>
                    <Move size={16} className="text-gray-400" />
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <span className="font-semibold text-gray-800 text-sm mr-3">{formatPrice(item.totalPrice)}</span>
                <button 
                  className={`${theme.editButton} p-1 rounded-full transition-colors duration-200`}
                  onClick={() => setEditingItem(item.id)}
                >
                  <Edit2 size={18} />
                </button>
              </div>
            </div>
            <div className="font-medium text-gray-800 text-sm mb-1">{item.name}</div>
            <div className="text-xs text-gray-600 flex items-center">
              {item.length > 0 && item.height > 0 && (
                <span className="flex-shrink-0">
                  <span className="font-bold">{item.length}'</span>
                  <span className="text-xs">w</span> X <span className="font-bold">{item.height}'</span>
                  <span className="text-xs">h</span> ft
                  <span className={`${separatorColor} mx-1`}>●</span>
                </span>
              )}
              {item.area > 0 && (
                <span className="flex-shrink-0">
                  Area/No.S: <span className="font-bold">{Number(item.area.toFixed(1))}</span>
                  <span className={`${separatorColor} mx-1`}>●</span>
                </span>
              )}
              <span className="flex-shrink-0">
                Unit Price: <span className="font-bold">{formatPrice(item.boxPrice)}</span>
              </span>
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  const handleClosePopup = () => {
    setShowNewQuotationPopup(false);
  };

  const handleClose = () => {
    if (hasUnsavedChanges) {
      const confirmClose = window.confirm("You have unsaved changes. Do you want to save before closing?");
      if (confirmClose) {
        saveQuotation().then(() => {
          resetQuotationState();
          onClose(true);
        }).catch((error) => {
          // Handle error if saving fails
          console.error('Error while saving before close:', error);
        });
      } else {
        resetQuotationState();
        onClose(true);
      }
    } else {
      resetQuotationState();
      onClose(true);
    }
  };

  const calculateAmount = (percentage) => {
    const grandTotal = calculateGrandTotal();
    return formatPrice(grandTotal * percentage / 100);
  };

  const handleSave = (field, index, key, value) => {
    switch (field) {
      case 'paymentTerms':
        setPaymentTerms(prev => prev.map((term, i) => 
          i === index ? { ...term, [key]: value } : term
        ));
        break;
      case 'materials':
        setMaterials(prev => prev.map((material, i) => 
          i === index ? { ...material, [key]: value } : material
        ));
        break;
      case 'notes':
        setNotes(prev => prev.map((note, i) => 
          i === index ? value : note
        ));
        break;
      case 'companyDetails':
        setCompanyDetails(prev => ({ ...prev, [key]: value }));
        break;
    }
  };

  const handleDelete = (field, index) => {
    switch (field) {
      case 'paymentTerms':
        setPaymentTerms(prev => prev.filter((_, i) => i !== index));
        break;
      case 'materials':
        setMaterials(prev => prev.filter((_, i) => i !== index));
        break;
      case 'notes':
        setNotes(prev => prev.filter((_, i) => i !== index));
        break;
    }
  };

  const handleAdd = (field) => {
    switch (field) {
      case 'paymentTerms':
        setPaymentTerms(prev => [...prev, { percentage: 0, description: '' }]);
        break;
      case 'materials':
        setMaterials(prev => [...prev, { name: '', description: '' }]);
        break;
      case 'notes':
        setNotes(prev => [...prev, '']);
        break;
    }
  };

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const renderAddButton = (onClick, color) => (
    <button
      onClick={onClick}
      className="mt-2 flex items-center justify-center w-8 h-8 bg-white hover:bg-gray-100 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
      aria-label="Add item"
    >
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={color}>
        <path d="M12 5V19" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5 12H19" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </button>
  );

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    const sourceRoomIndex = parseInt(source.droppableId.split('-')[1]);
    const destRoomIndex = parseInt(destination.droppableId.split('-')[1]);

    const newRooms = [...rooms];
    const [movedItem] = newRooms[sourceRoomIndex].items.splice(source.index, 1);
    newRooms[destRoomIndex].items.splice(destination.index, 0, movedItem);

    setRooms(newRooms);
  };

  // Add this effect to update hasUnsavedChanges when relevant state changes
  useEffect(() => {
    setHasUnsavedChanges(true);
  }, [rooms, discount, companyDetails, clientName, date, propertyType, paymentTerms, materials, notes, gst, isGstEnabled, isDiscountEnabled]);

  const handleToggleChange = (toggleName, value) => {
    switch(toggleName) {
      case 'hideUnitPrice':
        setHideUnitPriceInPDF(value);
        break;
      default:
        console.error('Unknown toggle:', toggleName);
    }
  };

  // Add loading state
  const [isInitializing, setIsInitializing] = useState(true);

  // Add cleanup effect
  useEffect(() => {
    return () => {
      // Cleanup function to prevent memory leaks
      setRooms([]);
      setCompanyDetails({
        name: '',
        address: '',
        mobileNumber: '',
        logo: null
      });
      setPaymentTerms([]);
      setMaterials([]);
      setNotes([]);
      setEditingItem(null);
      setLogo(null);
      
      // Clear any ongoing operations
      if (debouncedSave.cancel) {
        debouncedSave.cancel();
      }
    };
  }, []);

  // Add initialization effect
  useEffect(() => {
    const initializeComponent = async () => {
      try {
        // Try to load cached data first
        const cachedData = await getCachedData('quotationState', user?.uid);
        if (cachedData) {
          // Apply cached data
          setCompanyDetails(cachedData.companyDetails);
          setRooms(cachedData.rooms || []);
          // ... set other cached states
        }

        // Load fresh data
        if (editQuotationId) {
          await loadQuotation(editQuotationId);
        } else {
          await loadUserSettings();
        }
      } catch (error) {
        console.error('Error initializing component:', error);
      } finally {
        setIsInitializing(false);
      }
    };

    initializeComponent();
  }, [editQuotationId, user]);

  useEffect(() => {
    const loadCustomRoomNames = async () => {
      if (user) {
        try {
          const settings = await getUserSettings(user.uid);
          if (settings && settings.customRoomNames) {
            setRoomTypes(settings.customRoomNames);
          } else {
            setRoomTypes(defaultRoomNames);
          }
        } catch (error) {
          console.error('Error loading custom room names:', error);
          setRoomTypes(defaultRoomNames);
        }
      }
    };

    loadCustomRoomNames();
  }, [user, getUserSettings]);

  useEffect(() => {
    const handleRoomNamesUpdate = (event) => {
      setRoomTypes(event.detail.roomNames);
    };

    window.addEventListener('roomNamesUpdated', handleRoomNamesUpdate);

    // Load initial room names
    const loadInitialRoomNames = async () => {
      if (user) {
        try {
          const settings = await getUserSettings(user.uid);
          if (settings && settings.customRoomNames) {
            setRoomTypes(settings.customRoomNames);
          } else {
            setRoomTypes(defaultRoomNames);
          }
        } catch (error) {
          console.error('Error loading room names:', error);
          setRoomTypes(defaultRoomNames);
        }
      }
    };

    loadInitialRoomNames();

    return () => {
      window.removeEventListener('roomNamesUpdated', handleRoomNamesUpdate);
    };
  }, [user, getUserSettings]);

  return (
    <div className={`w-full max-w-md mx-auto ${theme.mainBackground} p-4 font-sans relative`}>
      {isCreatingNewQuotation ? (
        <>
          <button 
            onClick={handleClose}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-colors duration-200"
            aria-label="Close quotation"
          >
            <X size={24} />
          </button>
          <h1 className="text-xl font-bold mb-6 text-gray-800 pr-8">Create Quotation</h1>

          <div className="mb-4 text-sm text-gray-600 flex items-center">
            <span className={`w-2 h-2 rounded-full mr-2 ${saveStatus === 'Saved' ? 'bg-green-500' : 'bg-yellow-500'}`}></span>
            {saveStatus}
          </div>

          <div className="mb-4 flex">
            <div className="w-2/3 pr-2">
              <input 
                className="w-full p-2 mb-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-xs font-bold" 
                placeholder="Company Name" 
                value={companyDetails.name}
                onChange={(e) => handleSave('companyDetails', null, 'name', e.target.value)}
              />
              <div className="relative">
                <MapPin size={16} className="absolute top-2 left-3 text-gray-500" />
                <AutoResizeTextarea
                  value={companyDetails.address}
                  onChange={(e) => handleSave('companyDetails', null, 'address', e.target.value)}
                  placeholder="Company Address"
                  className="pl-8"
                  minHeight="55px"
                  style={{ paddingLeft: '28px' }}
                />
              </div>
              <div className="relative mt-2">
                <Phone size={16} className="absolute top-2 left-3 text-gray-500" />
                <input 
                  className="w-full p-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm pl-8" 
                  placeholder="Mobile Number" 
                  value={companyDetails.mobileNumber}
                  onChange={(e) => handleSave('companyDetails', null, 'mobileNumber', e.target.value)}
                />
              </div>
            </div>
            <div className="w-1/3 pl-2">
              <div className={`relative w-full aspect-square mb-3 flex items-start justify-center rounded-lg ${logo ? '' : 'bg-gray-200'}`}>
                {logo ? (
                  <>
                    <img 
                      src={logo} 
                      alt="Company Logo" 
                      className="w-full h-auto object-contain rounded-lg" 
                      style={{ maxWidth: '200px', maxHeight: '200px' }}
                    />
                    <button 
                      className="absolute top-0 right-0 m-1 text-red-500 hover:bg-red-100 p-1 rounded-full transition-colors duration-200"
                      onClick={deleteLogo}
                    >
                      <Trash2 size={16} />
                    </button>
                  </>
                ) : (
                  <label className="cursor-pointer flex flex-col items-center justify-center w-full h-full">
                    <Upload size={24} />
                    <span className="mt-2 text-xs text-gray-600">Upload Logo</span>
                    <input 
                      type="file" 
                      className="hidden" 
                      onChange={handleLogoUpload} 
                      accept="image/*" 
                    />
                  </label>
                )}
              </div>
            </div>
          </div>
          
          <div className="mb-4 flex items-center">
            <div className="relative w-1/2 mr-2">
              <User size={16} className="absolute top-2 left-3 text-gray-500" />
              <input 
                className="w-full p-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm pl-8" 
                placeholder="Client Name" 
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
              />
            </div>
            <div className="relative w-1/2">
              <DatePicker
                selected={date}
                onChange={(date) => setDate(date)}
                dateFormat="dd MMM yyyy"
                className="w-full p-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm pl-8"
                placeholderText="Select date"
              />
            </div>
          </div>
          
          <div className="border-b border-gray-300 mb-4"></div>
          
          <DragDropContext onDragEnd={onDragEnd} touchStartDelay={1500}>
            {rooms.map((room, roomIndex) => (
              <div key={room.id} className="mb-6">
                <div className={`${theme.cardBackground} rounded-lg shadow-md overflow-visible`}>
                  <div className={`flex justify-between items-center p-2 border-b rounded-t-lg w-full ${theme.roomHeader}`}>
                    <div className="w-3/4">
                      <EditableDropdown
                        value={room.name}
                        options={roomTypes}
                        onChange={(newName) => handleRoomNameChange(roomIndex, newName)}
                        onBlur={(finalName) => handleRoomNameChange(roomIndex, finalName)}
                        placeholder="Room Name"
                      />
                    </div>
                    <button 
                      className="ml-3 text-red-500 bg-white bg-opacity-50 hover:bg-opacity-75 p-1 rounded-full transition-colors duration-200"
                      onClick={() => deleteRoom(roomIndex)}
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                  <Droppable droppableId={`room-${roomIndex}`}>
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`p-2 ${theme.itemsArea}`}
                      >
                        {room.items.map((item, itemIndex) => renderItem(item, itemIndex, roomIndex))}
                        {provided.placeholder}
                        <button 
                          onClick={() => addItem(roomIndex)}
                          className={`w-full mt-1 text-white px-4 py-2 rounded-md transition-colors duration-200 flex items-center justify-center ${theme.addItemButton}`}
                        >
                          <Plus size={20} color="white" className="mr-2" /> Add Item
                        </button>
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            ))}
          </DragDropContext>
          
          <button 
            className="w-full mb-6 bg-green-500 text-white px-4 py-3 rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center"
            onClick={addRoom}
          >
            <Plus size={20} color="white" className="mr-2" /> Add Room
          </button>
          
          <div className={`bg-white shadow-lg p-4 border-t ${theme.borderColor} rounded-lg mt-6 mb-6`}>
            <div className="space-y-3">
              <div className="flex justify-between items-center">
                <span className="text-sm font-semibold">Total</span>
                <span className="text-sm font-medium">{formatPriceWithoutDecimal(calculateTotal())}</span>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="text-sm mr-2">Discount</span>
                  <div className="relative inline-block w-10 h-6 align-middle select-none transition duration-200 ease-in">
                    <input
                      type="checkbox"
                      name="discountToggle"
                      id="discountToggle"
                      checked={isDiscountEnabled}
                      onChange={() => {
                        setIsDiscountEnabled(!isDiscountEnabled);
                        if (!isDiscountEnabled && discount === '') setDiscount(5);
                      }}
                      className="sr-only"
                    />
                    <label
                      htmlFor="discountToggle"
                      className={`block w-10 h-6 rounded-full transition-colors duration-200 ease-in-out ${isDiscountEnabled ? 'bg-green-500' : 'bg-gray-300'}`}
                    >
                      <span className={`absolute top-0.5 left-0.5 w-5 h-5 rounded-full bg-white transition-transform duration-200 ease-in-out ${isDiscountEnabled ? 'transform translate-x-4' : ''}`}></span>
                    </label>
                  </div>
                </div>
                <div className="flex items-center">
                  {isDiscountEnabled && (
                    <div className="relative">
                      <input
                        type="text"
                        value={discount}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === '') {
                            setDiscount('');
                          } else {
                            const numValue = Number(value);
                            setDiscount(isNaN(numValue) ? 0 : Math.max(0, numValue));
                          }
                        }}
                        onBlur={() => {
                          if (discount === '') setDiscount(0);
                        }}
                        className="w-16 p-1 pr-5 text-right border rounded mr-1 text-sm"
                      />
                      <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm">%</span>
                    </div>
                  )}
                  <span className="text-sm font-medium w-20 text-right text-green-500">
                    {isDiscountEnabled ? `-${formatPriceWithoutDecimal(calculateTotal() * (discount || 0) / 100)}` : '-'}
                  </span>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="text-sm mr-2">GST</span>
                  <div className="relative inline-block w-10 h-6 align-middle select-none transition duration-200 ease-in">
                    <input
                      type="checkbox"
                      name="gstToggle"
                      id="gstToggle"
                      checked={isGstEnabled}
                      onChange={() => {
                        setIsGstEnabled(!isGstEnabled);
                        if (!isGstEnabled && gst === '') setGst(18);
                      }}
                      className="sr-only"
                    />
                    <label
                      htmlFor="gstToggle"
                      className={`block w-10 h-6 rounded-full transition-colors duration-200 ease-in-out ${isGstEnabled ? 'bg-green-500' : 'bg-gray-300'}`}
                    >
                      <span className={`absolute top-0.5 left-0.5 w-5 h-5 rounded-full bg-white transition-transform duration-200 ease-in-out ${isGstEnabled ? 'transform translate-x-4' : ''}`}></span>
                    </label>
                  </div>
                </div>
                <div className="flex items-center">
                  {isGstEnabled && (
                    <div className="relative">
                      <input
                        type="text"
                        value={gst}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === '') {
                            setGst('');
                          } else {
                            const numValue = Number(value);
                            setGst(isNaN(numValue) ? 0 : Math.max(0, numValue));
                          }
                        }}
                        onBlur={() => {
                          if (gst === '') setGst(0);
                        }}
                        className="w-16 p-1 pr-5 text-right border rounded mr-1 text-sm"
                      />
                      <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm">%</span>
                    </div>
                  )}
                  <span className="text-sm font-medium w-20 text-right">
                    {isGstEnabled ? formatPriceWithoutDecimal(
                      (calculateTotal() - (isDiscountEnabled ? calculateTotal() * (discount || 0) / 100 : 0)) * (gst || 0) / 100
                    ) : '-'}
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-4 pt-3 border-t border-gray-200">
              <div className="flex justify-between items-center font-bold">
                <span className="text-base">Grand Total</span>
                <span className="text-base">{formatPriceWithoutDecimal(calculateGrandTotal())}</span>
              </div>
            </div>
          </div>
          
          <div className="bg-white shadow-lg p-4 border-t border-gray-200 rounded-lg mt-6 space-y-4 text-sm">
            {/* Payment Terms */}
            <div className={`rounded-lg overflow-hidden transition-all duration-300 ease-in-out ${
              expandedSection === 'paymentTerms' 
                ? 'bg-indigo-50 border-2 border-indigo-200' 
                : ''
            }`}>
              <h3 
                className="text-lg font-semibold p-2 flex justify-between items-center cursor-pointer"
                onClick={() => toggleSection('paymentTerms')}
              >
                <span className="flex items-center">
                  <CreditCard size={20} className="mr-2 text-indigo-600" />
                  Payment Terms
                </span>
                {expandedSection === 'paymentTerms' ? <ChevronUp size={20} className="text-indigo-600" /> : <ChevronDown size={20} />}
              </h3>
              <div 
                ref={sectionRefs.paymentTerms}
                style={{height: `${sectionHeights.paymentTerms}px`}}
                className="transition-all duration-300 ease-in-out"
              >
                <div className="p-2 space-y-4">
                  {Array.isArray(paymentTerms) && paymentTerms.map((term, index) => (
                    <div key={index} className="flex flex-col">
                      <div className="flex relative mb-1">
                        <div className="absolute left-0 top-0 bottom-0 flex items-center justify-center w-7">
                          <div className="bg-indigo-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs flex-shrink-0">
                            {index + 1}
                          </div>
                        </div>
                        <div className="flex-grow pl-7">
                          <AutoResizeTextarea
                            value={term.description}
                            onChange={(e) => handleSave('paymentTerms', index, 'description', e.target.value)}
                            className="w-full p-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm"
                            placeholder="Payment description"
                            minHeight="24px"
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-between ml-7">
                        <div className="flex items-center">
                          <input
                            type="text"
                            value={term.percentage}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value === '' || (!isNaN(value) && Number(value) >= 0)) {
                                handleSave('paymentTerms', index, 'percentage', value === '' ? '' : Number(value));
                              }
                            }}
                            className="w-16 p-1 text-right bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm"
                          />
                          <span className="ml-1 text-sm">%</span>
                        </div>
                        <div className="flex items-center">
                          <span className="text-sm font-bold mr-2">
                            {term.percentage === '' ? '-' : calculateAmount(Number(term.percentage))}
                          </span>
                          <button onClick={() => handleDelete('paymentTerms', index)} className="text-red-500 hover:text-red-700 p-1">
                            <Trash2 size={20} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-start">
                    {renderAddButton(() => handleAdd('paymentTerms'), 'text-indigo-600')}
                  </div>
                </div>
              </div>
            </div>

            {/* Material Specification */}
            <div className={`rounded-lg overflow-hidden transition-all duration-300 ease-in-out ${
              expandedSection === 'materials' 
                ? 'bg-green-50 border-2 border-green-200' 
                : ''
            }`}>
              <h3 
                className="text-lg font-semibold p-2 flex justify-between items-center cursor-pointer"
                onClick={() => toggleSection('materials')}
              >
                <span className="flex items-center">
                  <Package size={20} className="mr-2 text-green-600" />
                  Material Specification
                </span>
                {expandedSection === 'materials' ? <ChevronUp size={20} className="text-green-600" /> : <ChevronDown size={20} />}
              </h3>
              <div 
                ref={sectionRefs.materials}
                style={{height: `${sectionHeights.materials}px`}}
                className="transition-all duration-300 ease-in-out"
              >
                <div className="p-2 space-y-4">
                  {materials.map((material, index) => (
                    <div key={index} className="flex flex-col">
                      <div className="flex items-center mb-1">
                        <div className="bg-green-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs mr-2 flex-shrink-0">
                          {index + 1}
                        </div>
                        <div className="flex-grow relative">
                          <AutoResizeTextarea
                            value={material.name}
                            onChange={(e) => handleSave('materials', index, 'name', e.target.value)}
                            className="w-full p-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm font-semibold pr-8"
                            placeholder="Material name"
                            minHeight="24px"
                          />
                          <button 
                            onClick={() => handleDelete('materials', index)} 
                            className="absolute right-0 top-0 text-red-500 hover:text-red-700 p-1"
                          >
                            <Trash2 size={20} />
                          </button>
                        </div>
                      </div>
                      <div className="flex items-center ml-7">
                        <AutoResizeTextarea
                          value={material.description}
                          onChange={(e) => handleSave('materials', index, 'description', e.target.value)}
                          placeholder="Material description"
                          minHeight="24px"
                          className="w-full p-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm"
                        />
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-start">
                    {renderAddButton(() => handleAdd('materials'), 'text-green-600')}
                  </div>
                </div>
              </div>
            </div>

            {/* Notes */}
            <div className={`rounded-lg overflow-hidden transition-all duration-300 ease-in-out ${
              expandedSection === 'notes' 
                ? 'bg-yellow-50 border-2 border-yellow-200' 
                : ''
            }`}>
              <h3 
                className="text-lg font-semibold p-2 flex justify-between items-center cursor-pointer"
                onClick={() => toggleSection('notes')}
              >
                <span className="flex items-center">
                  <FileText size={20} className="mr-2 text-yellow-600" />
                  Notes
                </span>
                {expandedSection === 'notes' ? <ChevronUp size={20} className="text-yellow-600" /> : <ChevronDown size={20} />}
              </h3>
              <div 
                ref={sectionRefs.notes}
                style={{height: `${sectionHeights.notes}px`}}
                className="transition-all duration-300 ease-in-out"
              >
                <div className="p-2 space-y-2">
                  {notes.map((note, index) => (
                    <div key={index} className="flex relative">
                      <div className="absolute left-0 top-0 bottom-0 flex items-center justify-center w-7">
                        <div className="bg-yellow-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs flex-shrink-0">
                          {index + 1}
                        </div>
                      </div>
                      <div className="flex-grow pl-7">
                        <AutoResizeTextarea
                          value={note}
                          onChange={(e) => handleSave('notes', index, null, e.target.value)}
                          placeholder="Add a note"
                          minHeight="24px"
                          className="w-full p-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm"
                        />
                      </div>
                      <button onClick={() => handleDelete('notes', index)} className="text-red-500 hover:text-red-700 p-1 flex-shrink-0">
                        <Trash2 size={20} />
                      </button>
                    </div>
                  ))}
                  <div className="flex justify-start">
                    {renderAddButton(() => handleAdd('notes'), 'text-yellow-600')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 mb-4 flex items-center">
            <span className="mr-2 text-sm text-gray-600">Hide Unit Price in PDF</span>
            <div className="relative">
              <input
                type="checkbox"
                checked={hideUnitPriceInPDF}
                onChange={(e) => e.stopPropagation()}
                className="sr-only"
              />
              <div
                className={`block w-14 h-8 rounded-full transition-colors duration-200 ${hideUnitPriceInPDF ? 'bg-green-500' : 'bg-gray-300'}`}
                onClick={() => setHideUnitPriceInPDF(!hideUnitPriceInPDF)}
              ></div>
              <div
                className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-200 ${
                  hideUnitPriceInPDF ? 'transform translate-x-full' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setHideUnitPriceInPDF(!hideUnitPriceInPDF);
                }}
              ></div>
            </div>
          </div>

          <div className="mt-6">
            <div className="flex space-x-4">
              <button 
                className="flex-1 bg-blue-500 text-white px-4 py-3 rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center text-sm font-medium"
                onClick={saveQuotation}
                disabled={isSaving}
              >
                {isSaving ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Saving quote...
                  </>
                ) : (
                  <>
                    <Check size={20} color="white" className="mr-2" /> Save Quote
                  </>
                )}
              </button>

              <div className="flex space-x-2">
                <DownloadPDF
                  quotationData={{
                    companyName: companyDetails.name,
                    mobileNumber: companyDetails.mobileNumber,
                    logo: companyDetails.logo,
                    companyAddress: companyDetails.address,
                    clientName,
                    date,
                    rooms,
                    discount,
                    total: calculateTotal(),
                    grandTotal: calculateGrandTotal(),
                    hideUnitPrice: hideUnitPriceInPDF,
                    paymentTerms,
                    materials,
                    notes,
                    gst,
                    isGstEnabled,
                    isDiscountEnabled
                  }}
                  isMobile={isMobile}
                >
                  {({ loading, error, handleDownload }) => (
                    <button 
                      className="flex-1 bg-green-500 text-white px-4 py-3 rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center text-sm font-medium"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDownload();
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Generating...
                        </>
                      ) : (
                        <>
                          <FileText size={20} color="white" className="mr-2" /> {isMobile ? 'Generate PDF' : 'Download PDF'}
                        </>
                      )}
                    </button>
                  )}
                </DownloadPDF>

                <DownloadPrintablePDF
                  quotationData={{
                    companyName: companyDetails.name,
                    mobileNumber: companyDetails.mobileNumber,
                    logo: companyDetails.logo,
                    companyAddress: companyDetails.address,
                    clientName,
                    date,
                    rooms,
                    discount,
                    total: calculateTotal(),
                    grandTotal: calculateGrandTotal(),
                    hideUnitPrice: hideUnitPriceInPDF,
                    paymentTerms,
                    materials,
                    notes,
                    gst,
                    isGstEnabled,
                    isDiscountEnabled
                  }}
                  isMobile={isMobile}
                >
                  {({ loading, error, handleDownload }) => (
                    <button 
                      className="bg-green-500 text-white p-3 rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDownload();
                      }}
                      disabled={loading}
                      title="Generate Printable PDF"
                    >
                      {loading ? (
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      ) : (
                        <Printer size={20} color="white" />
                      )}
                    </button>
                  )}
                </DownloadPrintablePDF>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="mt-8">
          <button
            onClick={handleCreateNewQuotation}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-200 mb-4"
          >
            Create New Quotation
          </button>
        </div>
      )}
      {showNewQuotationPopup && (
        <NewQuotationPopup
          onClose={() => setShowNewQuotationPopup(false)}
          onStart={handleStartNewQuotation}
          clientName={clientName}
          setClientName={setClientName}
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          companyName={companyDetails.name}
        />
      )}
      {isLoadingFile && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg flex items-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span className="text-lg font-semibold">Loading quotation...</span>
          </div>
        </div>
      )}
      {isInitializing && (
        <QuotationSkeleton />
      )}
    </div>
  );
};

export default InteractiveMobileQuotation;