import React from 'react';
import { PlayCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';

const TutorialPopup = ({ onClose, onGoToTutorials }) => {
  const tutorials = [
    {
      id: 1,
      title: "App installation tutorial for iPhone, Android, Windows",
      videoUrl: "https://youtube.com/shorts/8wj0ISfBTTE?si=5OVoMVhds-t-U7jC",
      number: "01"
    },
    {
      id: 2,
      title: "App Demo",
      videoUrl: "https://youtu.be/yWC31ZkR5as",
      number: "02"
    }
  ];

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/60 backdrop-blur-sm z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg w-[90%] sm:w-[360px] max-h-[85vh] overflow-y-auto relative mx-auto">
        <button 
          onClick={onClose}
          className="absolute right-1.5 top-1.5 text-gray-400 hover:text-gray-600"
        >
          <XMarkIcon className="w-4 h-4" />
        </button>

        <h2 className="text-xl font-bold mb-2">Welcome to Design Que!</h2>
        <p className="text-gray-600 text-sm mb-4">
          Get started with our quick tutorial videos.
        </p>

        <div className="space-y-4 mb-4">
          {tutorials.map((tutorial) => (
            <div key={tutorial.id} className="relative w-[85%] mx-auto group">
              <div className="rounded-md shadow-sm">
                <div className="relative bg-gradient-to-r from-teal-50 via-emerald-50 to-teal-50 rounded-md overflow-hidden" style={{ aspectRatio: '16/10' }}>
                  {/* Animated Wave Pattern Background */}
                  <div className="absolute inset-0 opacity-20">
                    <svg className="w-[200%] h-full animate-wave" viewBox="0 0 200 100" preserveAspectRatio="none">
                      <pattern id={`waves${tutorial.id}`} width="40" height="15" patternUnits="userSpaceOnUse">
                        <path d="M0 7.5 Q10 0, 20 7.5 Q30 15, 40 7.5" 
                              fill="none" 
                              stroke="currentColor" 
                              strokeWidth="0.8" 
                              className="text-emerald-400"/>
                      </pattern>
                      <rect width="200" height="100" fill={`url(#waves${tutorial.id})`}/>
                    </svg>
                  </div>

                  {/* Tutorial Number */}
                  <div className="absolute left-2 top-2 text-emerald-600 text-sm font-medium">
                    {tutorial.number}
                  </div>

                  {/* Play Button Link */}
                  <a 
                    href={tutorial.videoUrl}
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="absolute inset-0 flex items-center justify-center"
                  >
                    <div className="w-10 h-10 rounded-full border-2 border-emerald-200 bg-white/90 flex items-center justify-center group-hover:border-emerald-300 group-hover:bg-white transition-all duration-300 shadow-sm">
                      <svg className="w-5 h-5 text-emerald-600 group-hover:text-emerald-700 transition-colors duration-300 translate-x-0.5" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M6.92 5.51L18.9 12l-11.98 6.49V5.51z"/>
                      </svg>
                    </div>
                  </a>
                </div>
                
                {/* Title Below Video */}
                <div className="py-2 px-2 text-center">
                  <p className="text-gray-800 text-sm leading-tight">
                    {tutorial.title}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end space-x-3">
          <button 
            onClick={onClose} 
            className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800 font-medium"
          >
            Maybe Later
          </button>
          <button 
            onClick={onGoToTutorials} 
            className="px-4 py-1.5 bg-blue-500 text-white rounded hover:bg-blue-600 font-medium shadow-sm hover:shadow transition-all text-sm"
          >
            Start Learning
          </button>
        </div>
      </div>
    </div>
  );
};

export default TutorialPopup;
